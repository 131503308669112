const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export default LAYOUT_CONST;

export const COLORS = {
    MAIN_BACKGROUND: '#f9fafb',
    LAYOUT_BORDER: '#ebedf0',
    ICON_UNSELECTED: '#667085',
    ICON_SELECTED: '#024679',
    LABEL_COLOR: '#535862',
    FILTER_BORDER_COLOR: '#D0D5DD',
    NAWY_ORANGE: '#FF5E00',
    GREEN: '#079455',
    DARK_GREEN: '#008000',
    BLUE: '#015C9A',
    RED: '#BA0000'
};
