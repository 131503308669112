import { Button, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createCampaingArrow from '../../assets/images/create-campaing-card/create-campaing-arrow.svg';
import fadedLogo from '../../assets/images/create-campaing-card/faded-logo.svg';
import { COLORS } from 'constant';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { CampaignStatusEnum } from 'components/campaign-summary/campaign-summary.interface';

const WelcomeCard = ({ userName = 'User' }) => {
    const [campaignCount, setCampaignCount] = useState(0);
    const theme = useTheme();
    const getRunningCampaignsCount = async () => {
        try {
            const params: any = {};
            params.campaignStatus = CampaignStatusEnum.live;

            const response = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organization-campaigns/live`, {
                params
            });
            setCampaignCount(response.data);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error fetching running campaigns count',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        getRunningCampaignsCount();
    }, []);

    return (
        <Box
            sx={{
                borderRadius: '8px',
                background: 'linear-gradient(268deg, #7DCBC1 -72.72%, #4888AE -2.71%, #185277 50.44%, #052E4A 76.88%)',
                padding: '16px',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
                marginTop: { xs: 2, md: 0 },
                marginX: { xs: 2, md: 0 }
            }}
        >
            <img
                src={fadedLogo}
                alt="Faded Logo"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: '8px'
                }}
            />
            <Box>
                <Typography color="white" fontSize={14} fontWeight={400}>
                    Welcome, {userName}!
                </Typography>
                <Typography variant="subtitle1" color="white" fontSize={15} fontWeight={600}>
                    Don't miss the chance to create your Campaign with Nawy’s CMS.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        width: { xs: '100%', md: 'fit-content' }
                    }}
                >
                    <Typography variant="h3" color={COLORS.NAWY_ORANGE} fontWeight={700}>
                        +{campaignCount !== undefined && campaignCount !== null ? campaignCount : 0}
                    </Typography>
                    <Typography variant="body2" color="white" sx={{ marginLeft: 1 }}>
                        Campaigns running in different projects
                    </Typography>
                </Box>
            </Box>
            <Button
                variant="contained"
                color="primary"
                sx={{
                    backgroundColor: '#FFF',
                    color: 'black',
                    marginTop: { xs: 2, md: 0 },
                    '&:hover': {
                        backgroundColor: theme.palette.grey[300]
                    },
                    width: { xs: '100%', md: 'auto', minWidth: 160 }
                }}
                href={`campaigns/new`}
                target="_blank"
            >
                Create Campaign <img src={createCampaingArrow} alt="Arrow" style={{ marginLeft: 8 }} />
            </Button>
        </Box>
    );
};

export default WelcomeCard;
