import { format, isValid } from 'date-fns';
import { useState } from 'react';
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { defaultStaticRanges } from './constants';
import { Stack } from '@mui/system';
import { IRangeDatePickerProps } from 'components/common/interfaces';
import { COLORS } from 'constant';
import useDeviceType from 'hooks/useDeviceType';

const style = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4
};

const CustomDateRangePicker: React.FC<IRangeDatePickerProps> = ({ filter, handleFilter, staticRanges = defaultStaticRanges }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleApply = () => {
        if (state[0] && state[0].startDate && state[0].endDate) {
            const formattedStartDate = formatDateSafely(state[0].startDate);
            const formattedEndDate = formatDateSafely(state[0].endDate);
            if (formattedStartDate && formattedEndDate) {
                setValue(`${formattedStartDate} - ${formattedEndDate}`);
            } else {
                setValue('');
                console.warn('Invalid date selected');
            }
            handleFilter(filter.filterName, { startDate: state[0].startDate, endDate: state[0].endDate });
        }
        setOpen(false);
    };
    const handleClearValue = () => {
        setValue('');
        setState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        handleFilter(filter.filterName, { startDate: null, endDate: null });
    };

    const { isMobile } = useDeviceType();
    const formatDateSafely = (date: Date): string => {
        return isValid(date) ? format(date, 'dd/MM/yyyy') : '';
    };

    const handleDateRangeChange = (item: any) => {
        const startDate = new Date(
            Date.UTC(item.selection.startDate.getFullYear(), item.selection.startDate.getMonth(), item.selection.startDate.getDate())
        );
        const endDate = new Date(
            Date.UTC(item.selection.endDate.getFullYear(), item.selection.endDate.getMonth(), item.selection.endDate.getDate())
        );

        setState([{ startDate, endDate, key: 'selection' }]);
    };

    return (
        <>
            <Stack direction="column" sx={{ width: '100%', cursor: 'pointer', alignItems: 'flex-start' }}>
                <Typography variant="caption" sx={{ color: COLORS.LABEL_COLOR }}>
                    {filter.title}
                </Typography>
                <TextField
                    value={value || ''}
                    placeholder="No date selected"
                    fullWidth
                    size="small"
                    onFocus={handleOpen}
                    InputProps={{
                        endAdornment: (
                            <>
                                {value && (
                                    <IconButton type="button" aria-label="clear-value" onClick={handleClearValue}>
                                        <CloseOutlinedIcon />
                                    </IconButton>
                                )}
                                <IconButton type="button" aria-label="open-calendar" onClick={handleOpen}>
                                    <CalendarMonthOutlinedIcon />
                                </IconButton>
                            </>
                        )
                    }}
                />
            </Stack>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backdropFilter: 'blur(5px)'
                }}
            >
                <>
                    <Box sx={{ ...style, minHeight: { mobile: '480px', desktop: '530px' } }}>
                        {isMobile ? (
                            <DateRange
                                editableDateInputs={true}
                                onChange={handleDateRangeChange}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                            />
                        ) : (
                            <DateRangePicker
                                onChange={handleDateRangeChange}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                                direction="horizontal"
                                months={2}
                                staticRanges={staticRanges}
                            />
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 3 }}>
                            <Button onClick={handleClearValue}>Clear</Button>
                            <Button onClick={handleApply}>Apply</Button>
                        </Box>
                    </Box>
                </>
            </Modal>
        </>
    );
};

export default CustomDateRangePicker;
