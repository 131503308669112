import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { CustomProps } from './interfaces';

export const CustomNumericFormat = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="EGP "
        />
    );
});
