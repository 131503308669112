import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NavMotion from 'layout/NavMotion';
import CampaignRequests from 'pages/campaign-requests';
import CampaignDetails from 'pages/campaign-summary';
import CampaignRequestSummary from 'pages/campaign-request-summary';
import Campaigns from 'pages/campaigns';
import CampaignForm from 'pages/campaign-form';
import Compounds from 'pages/compounds';
import UnderConstruction from 'pages/maintenance/UnderConstruction';
import Partners from 'pages/partners';
import PartnerSummary from 'pages/partner-summary';
import PartnersWallets from 'pages/partners-wallet';
import PartnerWalletSummary from 'pages/partner-wallet-summary';
import Home from 'pages/home';
import LeadBalancer from 'pages/facebook-lead-balancer';
import WebsiteLeadBalancer from 'pages/website-lead-balancer';
import FallbackScenarios from 'pages/fallback-scenarios';
import PreApprovedLocations from 'pages/pre-approved-locations';

// sample page routing
const Locations = Loadable(lazy(() => import('pages/locations')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/compounds',
            element: <Compounds />
        },
        {
            path: '/locations',
            element: <Locations />
        },
        {
            path: '/campaign-requests',
            element: <CampaignRequests />
        },
        {
            path: '/campaigns/:id',
            element: <CampaignDetails />
        },
        {
            path: '/campaign-requests/:id',
            element: <CampaignRequestSummary />
        },
        {
            path: '/campaigns',
            element: <Campaigns />
        },
        {
            path: '/partners',
            element: <Partners />
        },
        {
            path: '/partners/:id',
            element: <PartnerSummary />
        },
        {
            path: '/facebook-lead-balancer',
            element: <LeadBalancer />
        },
        {
            path: '/website-lead-balancer',
            element: <WebsiteLeadBalancer />
        },
        {
            path: '/campaigns/new',
            element: <CampaignForm />
        },
        {
            path: '/partners-wallets',
            element: <PartnersWallets />
        },
        {
            path: '/settings/fallback-scenarios',
            element: <FallbackScenarios />
        },
        {
            path: '/partners-wallets/:id',
            element: <PartnerWalletSummary />
        },
        {
            path: '/settings/pre-approved-locations',
            element: <PreApprovedLocations />
        },
        {
            path: '/under-construction',
            element: <UnderConstruction />
        }
    ]
};

export default MainRoutes;
