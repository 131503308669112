import { FilterTypeEnum, ITableFilter } from 'components/common/SearchableTable/interfaces';

const tableFilters: ITableFilter[] = [
    { filterType: FilterTypeEnum.LIST, filterName: 'platformIds', optionsKey: 'platforms', title: 'Platform' },
    { filterType: FilterTypeEnum.LIST, filterName: 'pageIds', optionsKey: 'pages', title: 'Page' },
    {
        filterType: FilterTypeEnum.LIST,
        filterName: 'locationIds',
        title: 'Location',
        paginated: true,
        url: '/v1/locations/search',
        initialSearchQuery: {
            pageSize: 50,
            page: 1,
            name: '',
            addLocationTypeToName: true
        }
    }
];

export default tableFilters;
