import SearchableList from 'components/common/SearchableList';
import arrowUpRight from 'assets/images/icons/arrow-up-right.svg';
const PartnersWallet = () => {
    return (
        <SearchableList
            url="/v1/organizations/query"
            name="Partners"
            title="Partners"
            button={{ title: 'Wallet details', navigateTo: '/partners-wallets', icon: arrowUpRight }}
        />
    );
};

export default PartnersWallet;
