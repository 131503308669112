import { CMS_API, formattedQuery } from 'store/apis';
import { QueryAdsInterface } from './interfaces';

const ADS_APIS = {
    queryAds: (query: QueryAdsInterface) => {
        const formatQuery: string[] = [CMS_API, '/v1/ads', '?'];

        return formattedQuery(formatQuery, query);
    }
};

export default ADS_APIS;
