import { Card, ImageList, ImageListItem, Link } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { CampaignRequest } from './interfaces/campaign-request.interface';
import StatusChip from 'components/common/SearchableTable/StatusChip';
import LeadsIcon from '../../assets/images/responsive icons/leads.svg';
import CustomTypography from 'ui-component/CustomTypography';
import CampaignRequestRowActions from './CampaignRequestRowActions';

type CampaignRequestsListItemProps = {
    rowData: CampaignRequest;
    refresh: () => void;
};

const CampaignRequestsListItem: React.FC<CampaignRequestsListItemProps> = ({ rowData, refresh }) => {
    return (
        <>
            <Card
                sx={{
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    borderBottom: '1px solid  #EAECF0',
                    boxShadow: '0px 1px #EAECF0',
                    borderRadius: '0px'
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack>
                        <Link href={`campaign-requests/${rowData.id}`} underline="none" color="inherit">
                            <CustomTypography
                                maxWidth={200}
                                noWrap
                                header="Name"
                                tooltipText={rowData.organizationCampaign.name}
                                sx={{ color: '#015C9A', fontWeight: '500', fontSize: '16px' }}
                            >
                                {rowData.organizationCampaign.name}
                            </CustomTypography>
                        </Link>
                        <CustomTypography
                            maxWidth={150}
                            noWrap
                            header="ID"
                            variant="caption"
                            tooltipText={`ID: ${rowData.id}`}
                            sx={{ fontSize: '14px' }}
                        >
                            ID: {rowData.id}
                        </CustomTypography>
                    </Stack>

                    <StatusChip label={rowData.organizationCampaign.organizationCampaignStatus.type}></StatusChip>
                </Stack>

                <Stack direction="column" spacing={1} sx={{ backgroundColor: '#F7F7F7', padding: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1'
                            }}
                        >
                            <ImageListItem>
                                <img src={LeadsIcon} alt="LeadsIcon" />
                            </ImageListItem>
                        </ImageList>
                        <CustomTypography sx={{ fontSize: '14px', fontWeight: 400 }}>est. Avg leads :</CustomTypography>
                        {rowData.organizationCampaign.leadCount && (
                            <CustomTypography
                                noWrap
                                maxWidth={150}
                                tooltipText={rowData.organizationCampaign.leadCount}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}
                            >
                                {rowData.organizationCampaign.leadCount}
                            </CustomTypography>
                        )}
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1'
                            }}
                        >
                            <ImageListItem>
                                <img src={LeadsIcon} alt="LeadsIcon" />
                            </ImageListItem>
                        </ImageList>
                        <CustomTypography sx={{ fontSize: '14px', fontWeight: 400 }}>est. Cost / lead : </CustomTypography>
                        {rowData.organizationCampaign.costPerLead && (
                            <CustomTypography
                                noWrap
                                maxWidth={150}
                                tooltipText={rowData.organizationCampaign.costPerLead}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}
                            >
                                {rowData.organizationCampaign.costPerLead}
                            </CustomTypography>
                        )}
                    </Stack>
                </Stack>

                <Stack direction="row" height="88px" justifyContent="space-between" marginTop={1}>
                    <Stack direction="column" paddingX={1}>
                        <Stack direction="row" spacing={1}>
                            <CustomTypography
                                header="Avg Cost / lead"
                                tooltipText={rowData.organizationCampaign.organization.name}
                                maxWidth={170}
                                noWrap
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            >
                                {rowData.organizationCampaign.organization.name}
                            </CustomTypography>
                            {/* <CustomTypography sx={{ fontSize: '14px', fontWeight: '600' }}>EGP</CustomTypography> */}
                        </Stack>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500' }}>Partner name</CustomTypography>
                    </Stack>
                    <Stack
                        direction="column"
                        paddingX={1}
                        sx={{
                            borderLeft: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: ' linear-gradient(180deg, #FFFFFF 0%, #BEBEBE 48.71%, #FCFCFC 100%)'
                        }}
                    >
                        {rowData.organizationCampaign.organizationCampaignEntities.length != 0 &&
                            rowData.organizationCampaign.organizationCampaignEntities[0]?.location?.name && (
                                <CustomTypography
                                    maxWidth={150}
                                    noWrap
                                    header="Location"
                                    tooltipText={rowData.organizationCampaign.organizationCampaignEntities[0]?.location?.name}
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 600
                                    }}
                                >
                                    {rowData.organizationCampaign.organizationCampaignEntities[0]?.location?.name}
                                </CustomTypography>
                            )}
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500' }}>Campaign location</CustomTypography>
                    </Stack>
                    <Stack
                        direction="column"
                        paddingX={1}
                        sx={{
                            borderLeft: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: ' linear-gradient(180deg, #FFFFFF 0%, #BEBEBE 48.71%, #FCFCFC 100%)'
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <CustomTypography
                                header="Avg Cost / lead"
                                tooltipText={rowData.organizationCampaign.id}
                                maxWidth={70}
                                noWrap
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            >
                                {rowData.organizationCampaign.id}
                                {/* // TODO:: check as this value is not correct.. however this is following the table view value */}
                            </CustomTypography>
                            <CustomTypography sx={{ fontSize: '14px', fontWeight: '600' }}>EGP</CustomTypography>
                        </Stack>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '400' }}>out of</CustomTypography>
                        <CustomTypography
                            header="Budget"
                            tooltipText={rowData.organizationCampaign.budget}
                            noWrap
                            maxWidth={70}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400'
                            }}
                        >
                            {rowData.organizationCampaign.budget}
                        </CustomTypography>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500' }}>Request / Partner budget</CustomTypography>
                    </Stack>
                </Stack>
                <Box />
                <CampaignRequestRowActions id={rowData.id} data={rowData} refresh={refresh} useButtons />
            </Card>
        </>
    );
};

export default CampaignRequestsListItem;
