import { Grid } from '@mui/material';
import CampaignRequestsEmpty from 'components/campaign-requests/CampaignRequestsEmpty';
import { headers } from 'components/campaign-requests/headers';
import campaignRequestsFilters from 'components/campaign-requests/table-filters';
import SearchableTable from 'components/common/SearchableTable';
import { IFilterOptions, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import CampaignRequestsListItem from 'components/campaign-requests/CampaignRequestsListItem';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';

const CampaignRequests = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});
    const [initialSearchQuery, setInitialSearchQuery] = useState<ISearchQueryType>({
        pageSize: 10,
        page: 1,
        status: 'pending'
    });

    const getFilterOptions = async () => {
        try {
            const res = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organizations`);
            const organizations = res.data;
            const partners = organizations.map((organization: { id: any; name: any }) => ({
                value: organization.id,
                label: organization.name
            }));
            setFilterOptions({ partners: partners });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getFilterOptions();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ResponsivePageTitleComponent title="My Requests" mobileOnly={true} />
                        {!isLoading && (
                            <SearchableTable
                                url="/v1/organization-campaign-approvals"
                                name="Campaign Requests"
                                title="Pending Campaign Request"
                                headers={headers}
                                tableFilters={campaignRequestsFilters}
                                TableEmptySectionComponent={CampaignRequestsEmpty}
                                setData={() => {}}
                                initialSearchQuery={initialSearchQuery}
                                rowsPerPage={initialSearchQuery.pageSize}
                                filterOptions={filterOptions}
                                SecondaryActions={[]}
                                toggleFilter={{ defaultValue: 'pending', filterName: 'status', optionValues: ['pending', 'rejected'] }}
                                ListViewComponent={CampaignRequestsListItem}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CampaignRequests;
