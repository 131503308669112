/**
 * axios setup to use mock service
 */

import axios from 'axios';
const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_BACKEND || 'http://localhost:3001/',
    headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
});

export default axiosServices;
