import { Box, Button, Grid, IconButton } from '@mui/material';
import ApproveIcon from '../../assets/images/icons/approve.svg';
import RejectIcon from '../../assets/images/icons/reject.svg';
import axiosServices from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import { useState } from 'react';
import ConfirmModal from 'components/common/ConfirmModal';
import SimpleConfirmModal from 'components/common/SimpleConfirmModal';
import { COLORS } from 'constant';

interface IProps {
    id: number;
    data: any;
    useButtons?: boolean;
    refresh: () => void;
}

const CampaignRequestRowActions: React.FC<IProps> = ({ id, data, refresh, useButtons = false }) => {
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
    const [simpleConfirmModalIsOpen, setSimpleConfirmModalIsOpen] = useState<boolean>(false);

    const handleApprove = async () => {
        try {
            await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organization-campaign-approvals/${id}`, {
                approved: true
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaign request approved',
                    variant: 'success',
                    close: true
                })
            );
            refresh();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const handleReject = async (comment?: string | null) => {
        try {
            await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organization-campaign-approvals/${id}`, {
                rejected: true,
                comment: comment
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaign request rejected',
                    variant: 'success',
                    close: true
                })
            );
            refresh();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const ApproveButton = () => (
        <Button
            variant="text"
            sx={{
                width: { xs: '100%', md: '164px' },
                minWidth: '164px',
                heigh: '40px',
                borderRadius: '8px',
                color: COLORS.GREEN,
                backgroundColor: '#F7F7F7',
                '&:hover': {
                    backgroundColor: '#f1f1f1'
                }
            }}
            onClick={handleApprove}
        >
            <img src={ApproveIcon} alt={'Icon'} style={{ marginRight: '10px' }} /> Approve
        </Button>
    );

    const RejectButton = () => (
        <Button
            variant="text"
            sx={{
                width: { xs: '100%', md: '164px' },
                minWidth: '164px',
                heigh: '40px',
                borderRadius: '8px',
                color: COLORS.RED,
                backgroundColor: '#F7F7F7',
                '&:hover': {
                    backgroundColor: '#f1f1f1'
                }
            }}
            onClick={() => setSimpleConfirmModalIsOpen(true)}
        >
            <img src={RejectIcon} alt={'Icon'} style={{ marginRight: '10px' }} />
            Reject
        </Button>
    );

    return (
        <Grid>
            {!(data?.deletedAt || data?.rejected) && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'center'
                    }}
                >
                    {useButtons ? (
                        <>
                            <RejectButton />
                            <ApproveButton />
                        </>
                    ) : (
                        <>
                            <IconButton size="small" onClick={() => setSimpleConfirmModalIsOpen(true)}>
                                <img src={RejectIcon} alt={'Icon'} />
                            </IconButton>
                            <IconButton size="small" onClick={handleApprove}>
                                <img src={ApproveIcon} alt={'Icon'} />
                            </IconButton>
                        </>
                    )}
                </Box>
            )}
            {confirmModalIsOpen && (
                <ConfirmModal
                    title="Reject request"
                    secondaryTitle="Type the reason of rejection"
                    hasCommentSection={true}
                    commentPlaceholder={'Write a brief why you rejecting this request...'}
                    confirmButtonTitle="Reject"
                    confirmButtonColor="#BA0000"
                    isOpen={confirmModalIsOpen}
                    handleConfirm={handleReject}
                    handleClose={() => {
                        setConfirmModalIsOpen(false);
                    }}
                />
            )}
            {simpleConfirmModalIsOpen && (
                <SimpleConfirmModal
                    open={simpleConfirmModalIsOpen}
                    onClose={() => setSimpleConfirmModalIsOpen(false)}
                    onConfirm={() => setConfirmModalIsOpen(true)}
                    title={'Are you sure you want to reject the campaign?'}
                    confirmText={'Yes Reject'}
                />
            )}
        </Grid>
    );
};

export default CampaignRequestRowActions;
