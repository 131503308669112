import { Grid } from '@mui/material';
import SearchableTable from 'components/common/SearchableTable';
import { IFilterOptions, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import FallbackScenariosListItem from 'components/fallback-scenarios/FallbackScenariosListItem';
import fallbackScenariosFilters from 'components/fallback-scenarios/table-filters';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { headers } from '../../components/fallback-scenarios/headers';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';
const FallbackScenarios = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});

    const initialSearchQuery: ISearchQueryType = {
        pageSize: 10,
        page: 1
    };

    const getFilterOptions = async () => {
        try {
            const res = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/platforms`);
            const platforms = res.data;
            const platformsOptions = platforms.map((platform: { id: any; name: any }) => ({
                value: platform.id,
                label: platform.name
            }));
            setFilterOptions({ platforms: platformsOptions });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getFilterOptions();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ResponsivePageTitleComponent title="Fallback Scenarios" mobileOnly={true} />
                        {!isLoading && (
                            <SearchableTable
                                url="/v1/pages/fallback-scenarios"
                                name="Fallback Scenarios"
                                title="Fallback Scenarios"
                                headers={headers}
                                tableFilters={fallbackScenariosFilters}
                                setData={() => {}}
                                initialSearchQuery={initialSearchQuery}
                                rowsPerPage={initialSearchQuery.pageSize}
                                filterOptions={filterOptions}
                                // SecondaryActions={[optionsButton]}
                                ListViewComponent={FallbackScenariosListItem}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FallbackScenarios;
