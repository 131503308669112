import { Typography, Box } from '@mui/material';
import { useState } from 'react';
import WelcomeCard from '../../components/home/welcome-card';
import HomeStatistics from '../../components/home/HomeStatistics';
import SearchableTable from 'components/common/SearchableTable';
import { homeHeaders } from '../../components/home/headers';
import homeFilters from '../../components/home/table-filters';
import { FilterTypeEnum, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import { LocationType } from 'components/locations/interfaces';
import CustomDateRangePicker from 'ui-component/CustomDateRangePicker/CustomDateRangePicker';

const Home = () => {
    const [startDate, setStartDate] = useState<string | null>('');
    const [endDate, setEndDate] = useState<string | null>('');
    const [initialSearchQuery, setInitialSearchQuery] = useState<ISearchQueryType>({
        pageSize: 10,
        page: 1,
        locationType: LocationType.area
    });

    const handleFilter = (key: string | string[], value: any, overrideFilters: boolean = false) => {
        setStartDate(value.startDate);
        setEndDate(value.endDate);
    };
    return (
        <>
            <WelcomeCard userName={'userName'} />
            <Box
                sx={{
                    borderRadius: '8px',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'flex-start', md: 'center' },
                    justifyContent: 'space-between',
                    position: 'relative'
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        color: 'var(--Dark-grey, #101828)',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '28px'
                    }}
                >
                    Overview
                </Typography>
                {/* <HomeRangeDatePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} /> */}
                <Box sx={{ width: { xs: '100%', md: '332px' } }}>
                    <CustomDateRangePicker
                        handleFilter={handleFilter}
                        filter={{
                            filterType: FilterTypeEnum.DATE,
                            filterName: 'date',
                            title: 'Date'
                        }}
                    />
                </Box>
            </Box>
            <HomeStatistics startDate={startDate} endDate={endDate} />
            <Box sx={{ margin: '16px 0' }} />
            <SearchableTable
                url={'/v1/locations/search-locations'}
                name="areas"
                title="Estimated Cost per lead"
                headers={homeHeaders}
                inTitleFilters={homeFilters}
                setData={() => {}}
                initialSearchQuery={initialSearchQuery}
                rowsPerPage={initialSearchQuery.pageSize}
                enableSwipeLottie={false}
            />
        </>
    );
};

export default Home;
