import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import {
    CampaignStatusEnum,
    IActionLogStatusUpdates,
    ICampaignSummary,
    IOrganizationCampaign
} from 'components/campaign-summary/campaign-summary.interface';
import ActionLog from 'components/common/ActionLog';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import CalenderIcon from '../../assets/images/icons/calender.svg';
import connectorIcon from '../../assets/images/icons/Connector.svg';
import LeadsIcon from '../../assets/images/icons/leads.svg';
import LocationIcon from '../../assets/images/icons/location.svg';
import WalletIcon from '../../assets/images/icons/wallet.svg';
import SimpleConfirmModal from 'components/common/SimpleConfirmModal';
import { COLORS } from 'constant';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';

const CampaignSummary = () => {
    interface RouteParams {
        ownerID: string;
        [key: string]: string | undefined;
    }
    const { id } = useParams<RouteParams>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [campaignSummary, setCampaignSummary] = useState<ICampaignSummary | null>(null);
    const [pauseConfirmationModalIsOpen, setPauseConfirmationModalIsOpen] = useState<boolean>(false);
    const [stopConfirmationModalIsOpen, setStopConfirmationModalIsOpen] = useState<boolean>(false);

    const [actionLogStatusUpdates, setActionLogStatusUpdates] = useState<IActionLogStatusUpdates>({
        statusUpdates: [],
        organizationName: ''
    });
    const getCampaignSummary = async () => {
        try {
            const response = await axiosServices.get<IOrganizationCampaign>(
                `${process.env.REACT_APP_BACKEND}/v1/organization-campaigns/${id}`
            );
            const resData = response.data;
            const campaignSummaryTemp: ICampaignSummary = {
                name: resData.name,
                budgetPerDay: resData.budget / resData.numberOfDays,
                startDate: resData.startDate,
                endDate: resData.endDate,
                numberOfDays: resData.numberOfDays,
                startImmediately: resData.startImmediately,
                Locations: resData.organizationCampaignEntities?.map((entity) => entity?.location?.name),
                expectedLeadsPerDay: resData.leadCount / resData.numberOfDays,
                exclusiveLeads: false,
                maximumCampaignSpent: resData.spendLimit,
                status: resData.organizationCampaignStatus?.name,
                statusType: resData.organizationCampaignStatus?.type,
                createdBy: resData.organization?.name,
                statusUpdates: resData.statusUpdates
            };
            setActionLogStatusUpdates({
                statusUpdates: campaignSummaryTemp.statusUpdates || [],
                organizationName: resData.organization?.name
            });
            setCampaignSummary(campaignSummaryTemp);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateCampaignStatus = async (live?: boolean, pause?: boolean, stop?: boolean) => {
        setIsLoading(true);
        try {
            await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organization-campaigns/${id}`, {
                ...(live && { live: true }),
                ...(pause && { pause: true }),
                ...(stop && { stop: true })
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Campaign ${live ? 'is live' : pause ? 'is paused' : 'has stopped'}!`,
                    variant: 'success',
                    close: true
                })
            );
            getCampaignSummary();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCampaignSummary();
    }, []);

    const StopButton = () => (
        <Button
            variant="outlined"
            sx={{
                backgroundColor: 'white',
                width: { xs: '100%', md: '164px' },
                minWidth: '164px',
                height: '40px',
                borderRadius: '8px',
                color: '#475467',
                border: '1px solid var(--Light-grey, #475467)',
                '&:hover': {
                    backgroundColor: '#f1f1f1',
                    color: '#475467',
                    border: '1px solid var(--Light-grey, #475467)'
                }
            }}
            onClick={() => setStopConfirmationModalIsOpen(true)}
        >
            Stop Campaign
        </Button>
    );

    const ResumeButton = () => (
        <Button
            variant="contained"
            sx={{
                backgroundColor: '#015C9A',
                width: { xs: '100%', md: '164px' },
                minWidth: '164px',
                heigh: '40px',
                borderRadius: '8px',
                marginLeft: '10px'
            }}
            onClick={() => handleUpdateCampaignStatus(true, false, false)}
        >
            Resume Campaign
        </Button>
    );

    const StartButton = () => (
        <Button
            variant="text"
            sx={{
                width: { xs: '100%', md: '164px' },
                minWidth: '164px',
                heigh: '40px',
                borderRadius: '8px',
                marginLeft: '10px',
                color: COLORS.GREEN,
                backgroundColor: '#F7F7F7',
                '&:hover': {
                    backgroundColor: '#f1f1f1'
                }
            }}
            onClick={() => handleUpdateCampaignStatus(true, false, false)}
        >
            Mark as live
        </Button>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    {/* Campaign Summary */}
                    <Grid item xs={12} md={8}>
                        <ResponsivePageTitleComponent title="Campaigns" mobileOnly={true} />
                        <Card sx={{ paddingX: { xs: '12px', md: 0 } }}>
                            <CardContent>
                                <Typography variant="h4" sx={{ mb: 2 }}>
                                    Campaign summary
                                </Typography>
                                {/* <Typography variant="subtitle2" sx={{ mb: 2, color: '#888' }}>
                                    Partner campaign data created
                                </Typography> */}
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body1">
                                        <strong>Campaign Name</strong>: {campaignSummary?.name}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={WalletIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Budget per day</Typography>
                                        <Typography>
                                            {campaignSummary?.budgetPerDay &&
                                            !isNaN(campaignSummary.budgetPerDay) &&
                                            Number.isFinite(campaignSummary.budgetPerDay)
                                                ? campaignSummary.budgetPerDay.toLocaleString(undefined, { maximumFractionDigits: 0 })
                                                : '--'}{' '}
                                            EGP / day
                                        </Typography>
                                    </Box>
                                </Box>

                                <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={CalenderIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Campaign Time</Typography>
                                        <Typography>
                                            {campaignSummary?.startDate && campaignSummary?.endDate ? (
                                                <>
                                                    From <strong>{format(new Date(campaignSummary.startDate), 'dd/MM/yyyy')}</strong> to{' '}
                                                    <strong>{format(new Date(campaignSummary.endDate), 'dd/MM/yyyy')}</strong>
                                                    <strong> ({campaignSummary?.numberOfDays} days)</strong>
                                                </>
                                            ) : (
                                                '--'
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={LocationIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Location</Typography>
                                        <Typography>{campaignSummary?.Locations?.join(' - ')}</Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={LeadsIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Expected leads</Typography>
                                        <Typography>
                                            {campaignSummary?.expectedLeadsPerDay &&
                                                !isNaN(campaignSummary.budgetPerDay) &&
                                                Number.isFinite(campaignSummary.budgetPerDay) &&
                                                Math.trunc(campaignSummary.expectedLeadsPerDay)}{' '}
                                            / day{' '}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={LeadsIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Exclusive leads</Typography>
                                        <Typography>Off</Typography>
                                    </Box>
                                </Box> */}
                                {/* <Divider /> */}
                                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={SpentIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Maximum campaign spent</Typography>
                                        <Typography>
                                            {' '}
                                            {campaignSummary?.maximumCampaignSpent?.toLocaleString(undefined, {
                                                maximumFractionDigits: 0
                                            })}{' '}
                                            EGP
                                        </Typography>
                                    </Box>
                                </Box> */}
                                {/* <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={CalenderIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Start date</Typography>
                                        <Typography>Immediately once approved</Typography>
                                    </Box>
                                </Box> */}
                                {campaignSummary?.statusType &&
                                    [CampaignStatusEnum.approved, CampaignStatusEnum.paused, CampaignStatusEnum.live].includes(
                                        campaignSummary.statusType
                                    ) && (
                                        <Box sx={{ display: 'flex', gap: 2, mt: 3, justifyContent: { xs: 'center', md: 'flex-end' } }}>
                                            {campaignSummary.statusType === CampaignStatusEnum.live ? (
                                                <>
                                                    <StopButton />
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: '#015C9A',
                                                            width: { xs: '100%', md: '164px' },
                                                            minWidth: '164px',
                                                            heigh: '40px',
                                                            borderRadius: '8px',
                                                            marginLeft: '10px'
                                                        }}
                                                        onClick={() => setPauseConfirmationModalIsOpen(true)}
                                                    >
                                                        Pause Campaign
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <StopButton />
                                                    {campaignSummary.statusType === CampaignStatusEnum.approved ? (
                                                        <StartButton />
                                                    ) : (
                                                        <ResumeButton />
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    )}
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Actions Log */}
                    <Grid item xs={12} md={4}>
                        <ActionLog
                            title="Actions Log"
                            subtitle="All actions taken on this campaign"
                            actionLogStatusUpdates={actionLogStatusUpdates || []}
                            connectorIcon={connectorIcon}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ height: 24 }} />
            </Box>
            {pauseConfirmationModalIsOpen && (
                <SimpleConfirmModal
                    open={pauseConfirmationModalIsOpen}
                    onClose={() => setPauseConfirmationModalIsOpen(false)}
                    onConfirm={() => handleUpdateCampaignStatus(false, true, false)}
                    title="Are you sure you want to pause the campaign?"
                    confirmText="Yes Pause"
                />
            )}
            {stopConfirmationModalIsOpen && (
                <SimpleConfirmModal
                    open={stopConfirmationModalIsOpen}
                    onClose={() => setStopConfirmationModalIsOpen(false)}
                    onConfirm={() => handleUpdateCampaignStatus(false, false, true)}
                    title="Are you sure you want to stop the campaign?"
                    confirmText="Yes Stop"
                />
            )}
        </Box>
    );
};

export default CampaignSummary;
