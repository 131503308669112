export const CMS_API = process.env.REACT_APP_BACKEND || 'http://localhost:3001';

export const queryData = (queryName: string, data?: string | number | boolean) =>
    (data && data !== undefined) || data === false
        ? typeof data === 'string' && data.includes('array')
            ? `${data.replaceAll('array', '')}`
            : `${queryName}=${data}`
        : '';

export const formattedQuery = (formatQuery: string[], query: object) => {
    const queryValues = Object.values(query);
    Object.keys(query).forEach((queryKey, i) => {
        if (queryValues[i] && queryKey) formatQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
    });
    return formatQuery.join('');
};
