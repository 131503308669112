import { Grid, IconButton, Link, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import diagonalArrow from '../../assets/images/icons/arrow-diagonal.svg';
import { IHomeStatisticsFilter } from './interfaces/home-statistics-filter.type';
import { IHomeStatistics } from './interfaces/home-statistics.type';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import { CampaignStatusEnum } from 'components/campaign-summary/campaign-summary.interface';
const HomeStatistics: React.FC<IHomeStatisticsFilter> = (filters) => {
    const [homeStatistics, setHomeStatistics] = useState<IHomeStatistics>({} as IHomeStatistics);

    const getStatistics = async () => {
        const params: any = {};
        params.campaignStatus = CampaignStatusEnum.live;
        if (filters.startDate) params.startDate = filters.startDate;
        if (filters.endDate) params.endDate = filters.endDate;

        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organization-campaigns/analytics`, {
                params
            });
            setHomeStatistics(response.data);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error fetching statistics',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const OptionsIconButton = () => (
        <IconButton aria-label="options" size="small">
            <MoreVertIcon fontSize="inherit" />
        </IconButton>
    );

    const formatNumber = (num: number) => {
        return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
    };
    useEffect(() => {
        getStatistics();
    }, [filters.startDate, filters.endDate]);

    return (
        <Grid container spacing={2} sx={{ px: { xs: 2, md: 0 } }}>
            {[
                { label: 'Running campaigns', value: homeStatistics.campaignsCount, extra: <OptionsIconButton /> },
                { label: 'Leads generated', value: homeStatistics.leadsGeneratedCount, extra: <OptionsIconButton /> },
                { label: 'Avg cost / lead', value: homeStatistics.averageCostPerLead, extra: <OptionsIconButton /> }
            ].map(({ label, value, extra }, index) => (
                <Grid item xs={6} lg sm={6} key={index}>
                    <MainCard sx={{ color: 'black', height: '100%', border: 1, borderColor: '#EAECF0', borderRadius: '12px' }}>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 52 }}>
                                <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px' }} color="inherit">
                                    {label}
                                </Typography>
                                {extra || null}
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                {label === 'Avg cost / lead' && (
                                    <Typography sx={{ display: { xs: 'none', md: 'block' }, fontSize: 12, fontWeight: 600 }}>
                                        EGP
                                    </Typography>
                                )}
                                <Typography sx={{ fontSize: { xs: 22, md: 32 }, fontWeight: 600, lineHeight: '44px' }}>
                                    {value ? formatNumber(value) : '--'}
                                </Typography>
                            </Box>
                        </Stack>
                    </MainCard>
                </Grid>
            ))}

            <Grid item xs={6} lg sm={6}>
                <MainCard
                    sx={{
                        bgcolor: '#003A6C',
                        color: 'white',
                        height: '100%',
                        border: 1,
                        borderColor: '#EAECF0',
                        borderRadius: '12px'
                    }}
                >
                    <Stack spacing={3}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', height: 48 }}>My balance</Typography>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 600 }}>EGP</Typography>
                                <Typography sx={{ fontSize: { xs: 12, md: 24 }, fontWeight: 600 }}>--</Typography>
                            </Box>
                            <Link href="/partners-wallets" sx={{ alignSelf: 'flex-end' }} underline="none" color="inherit">
                                <Typography sx={{ fontSize: 14, fontWeight: 600, display: 'flex', gap: 1, alignItems: 'center' }}>
                                    Wallet details
                                    <img src={diagonalArrow} alt="icon" />
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default HomeStatistics;
