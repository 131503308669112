import SpentBudgetCell from 'components/campaigns/SpentBudgetCell';
import { IHeaderData } from 'components/common/SearchableTable/interfaces';

export const headers: IHeaderData[] = [
    {
        name: 'ID',
        key: 'id',
        hidden: true
    },
    {
        name: 'Name',
        key: 'name'
        // hasFilter: true,
        // filterType: 'text',
        // filterName: 'name'
    },
    {
        name: 'Facebook Pages',
        key: 'pages',
        type: 'list',
        iterableKeys: ['name'],
        iterable: true
    },
    {
        name: 'Active Campaigns',
        key: 'activeCampaignsCount'
        // type: 'date',
        // sortable: true
    },
    {
        name: 'Leads',
        key: 'leadCount'
        // type: 'date',
        // sortable: true
    },
    {
        name: 'Active Agents',
        key: 'activeAgents',
        type: 'number'
        // type: 'date',
        // sortable: true
    },
    {
        name: 'Spent / Total Budget',
        key: 'budgetSpent',
        CustomCellComponent: SpentBudgetCell
    },
    {
        name: 'Has CRM',
        key: 'hasCRM',
        isBoolean: true
        // filterType: 'text',
        // filterName: 'name'
    },
    {
        name: '',
        key: '',
        externalLinkCell: true,
        linkPrefix: 'partners'
    }
];
