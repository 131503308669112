import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { RefObject, useCallback, useEffect, useState } from 'react';
import ConfirmModal from 'components/common/ConfirmModal';
import * as Yup from 'yup';
import { useWebsiteLeadBalancer } from './hooks/useWebsiteLeadBalancer';
import { WebsiteLeadBalancerForm } from './WebsiteLeadBalancerForm';

interface IProps {
    id: number;
    data: any;
    refresh: any;
}

// Validation schema with Yup
const websiteLeadBalancerValidationSchema = Yup.object().shape({
    organizationId: Yup.number().required('Partner is required').moreThan(0, 'Partner is required'),
    weightType: Yup.string().required('Weight type is required'),
    ruleType: Yup.string().optional(),
    ruleValue: Yup.string().optional(),
    weight: Yup.number().required('Weight is required').moreThan(0, 'Weight is required').lessThan(100, 'Weight is required')
});

const WebsiteLeadBalancerRowActions: React.FC<IProps> = ({ id, data, refresh }) => {
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    const {
        formData,
        formOptions,
        isLoading,
        setIsLoading,
        handleChange,
        handleSave,
        handleDelete,
        getFormOptions,
        inputRefs,
        handleClearInputs,
        setFormData
    } = useWebsiteLeadBalancer({
        id,
        data,
        refresh
    });

    const handleClose = () => {
        setFormData({ ...data });
        handleClearInputs();
    };

    const memoizedFormOptionsCallback = useCallback(() => {
        getFormOptions();
    }, []);

    useEffect(() => {
        if (updateModalIsOpen) {
            setIsLoading(true);
            memoizedFormOptionsCallback();
        } else {
            setIsLoading(false);
        }
    }, [updateModalIsOpen, deleteModalIsOpen]);

    return (
        <Grid>
            <IconButton size="small" onClick={() => setUpdateModalIsOpen(true)}>
                <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => setDeleteModalIsOpen(true)}>
                <DeleteIcon />
            </IconButton>
            {deleteModalIsOpen && !isLoading && (
                <ConfirmModal
                    title="Delete Website Lead Balancer Rule"
                    secondaryTitle="Are you sure you want to delete this rule?"
                    confirmButtonTitle="Delete"
                    confirmButtonColor="#BA0000"
                    isOpen={deleteModalIsOpen}
                    handleConfirm={handleDelete}
                    handleClose={() => {
                        setDeleteModalIsOpen(false);
                        handleClose();
                    }}
                />
            )}
            {updateModalIsOpen && (
                <Dialog
                    sx={{ '& .MuiDialog-paper': { width: '480px', maxHeight: '500px' } }}
                    open={updateModalIsOpen}
                    onClose={() => {
                        setUpdateModalIsOpen(false);
                        handleClose();
                    }}
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h3">Update Website Lead Balancer</Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <WebsiteLeadBalancerForm
                            formData={formData}
                            formOptions={formOptions}
                            handleChange={handleChange}
                            inputRefs={inputRefs as unknown as RefObject<HTMLInputElement>[]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setUpdateModalIsOpen(false);
                                handleClose();
                            }}
                            variant="outlined"
                            sx={{
                                color: '#414651',
                                border: '1px solid #D5D7DA',
                                borderColor: '#D5D7DA',
                                width: '90px',
                                heigh: '40px',
                                borderRadius: '8px'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#015C9A', width: '90px', heigh: '40px', borderRadius: '8px' }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Grid>
    );
};

export default WebsiteLeadBalancerRowActions;
