import { IHeaderData } from 'components/common/SearchableTable/interfaces';
import SpentBudgetCell from './SpentBudgetCell';
import CampaignRowActions from './CampaignRowActions';

export const headers: IHeaderData[] = [
    {
        name: 'ID',
        key: 'id',
        hidden: true
    },
    {
        name: 'Campaign name',
        key: 'name'
    },
    {
        name: 'Partner name',
        key: 'organization',
        subKeys: ['name']
    },
    {
        name: 'Location',
        key: 'organizationCampaignEntities',
        iterableKeys: ['location', 'name'],
        type: 'list',
        iterable: true
    },
    {
        name: 'Status',
        key: 'organizationCampaignStatus',
        subKeys: ['name'],
        statusChip: true
    },
    {
        name: 'est. Cost/Lead',
        key: 'costPerLead',
        valueType: 'price'
    },
    {
        name: 'est. Avg Lead',
        key: 'leadCount'
    },
    {
        name: 'Spent / Total Budget',
        key: 'budgetSpent',
        CustomCellComponent: SpentBudgetCell
    },
    {
        name: '',
        key: '',
        actionColumn: true,
        ActionColumnComponent: CampaignRowActions
    },
    {
        name: '',
        key: '',
        externalLinkCell: true,
        linkPrefix: 'campaigns'
    }
];
