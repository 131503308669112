import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import activeIcon from '../../../assets/images/icons/celebrate.svg';
import finishIcon from '../../../assets/images/icons/finish.svg';
import approvedIcon from '../../../assets/images/icons/green-check-mark.svg';
import pauseIcon from '../../../assets/images/icons/red-pause.svg';
import rejectedIcon from '../../../assets/images/icons/rejected.svg';
import resumeIcon from '../../../assets/images/icons/resume.svg';
import createdIcon from '../../../assets/images/icons/rocket.svg';
import stoppedIcon from '../../../assets/images/icons/stop.svg';
import { IActionItem, IActionLogProps } from './interfaces';

const ActionLog = ({ title = 'Actions Log', subtitle = 'All actions taken', actionLogStatusUpdates, connectorIcon }: IActionLogProps) => {
    const statusUpdates = actionLogStatusUpdates?.statusUpdates;
    const generateActionLog = (): IActionItem[] => {
        if (!statusUpdates) return [];

        const statusIcon = {
            created: createdIcon,
            approved: approvedIcon,
            rejected: rejectedIcon,
            active: activeIcon,
            paused: pauseIcon,
            resumed: resumeIcon,
            finished: finishIcon,
            stopped: stoppedIcon
        };

        const statusHeader = {
            created: `Campaign Created for ${actionLogStatusUpdates.organizationName}`,
            approved: 'Campaign Approved',
            active: 'Campaign is Live',
            rejected: 'Campaign Rejected',
            paused: 'Campaign Paused',
            resumed: 'Campaign Resumed',
            finished: 'Campaign Finished',
            stopped: 'Campaign Stopped'
        };

        let activeFlag = false;
        const actions: IActionItem[] = [];
        statusUpdates?.forEach((status) => {
            const currentStatus = status.currentStatus?.toLocaleLowerCase();
            if (currentStatus === 'created' || currentStatus === 'pending') {
                actions.push({
                    header: statusHeader.created,
                    timestamp: status.createdAt,
                    label: status?.actor ? `By: ${status?.actor}` : '',
                    comment: status?.comment || undefined,
                    icon: statusIcon.created
                });
            } else if (currentStatus === 'approved') {
                actions.push({
                    header: statusHeader[currentStatus as keyof typeof statusHeader],
                    timestamp: status.createdAt,
                    label: status?.actor ? `By: ${status?.actor}` : '',
                    comment: status?.comment || undefined,
                    icon: statusIcon[currentStatus as keyof typeof statusIcon]
                });
            } else if (currentStatus === 'active') {
                if (!activeFlag) {
                    actions.push({
                        header: statusHeader.active,
                        timestamp: status.createdAt,
                        label: 'Campaign is live and running',
                        comment: status?.comment || undefined,
                        icon: statusIcon.active
                    });
                    activeFlag = true; // to make it resumed instead of active again
                } else {
                    actions.push({
                        header: statusHeader.resumed,
                        timestamp: status.createdAt,
                        label: status?.actor ? `By: ${status?.actor}` : '',
                        comment: status?.comment || undefined,
                        icon: statusIcon.resumed
                    });
                }
            } else if (currentStatus === 'paused') {
                actions.push({
                    header: statusHeader[currentStatus as keyof typeof statusHeader],
                    label: status?.actor ? `By: ${status?.actor}` : '',
                    comment: status?.comment || undefined,
                    timestamp: status.createdAt,
                    icon: statusIcon[currentStatus as keyof typeof statusIcon]
                });
            } else if (currentStatus === 'rejected') {
                actions.push({
                    header: statusHeader[currentStatus as keyof typeof statusHeader],
                    timestamp: status.createdAt,
                    label: status?.actor ? `By: ${status?.actor}` : '',
                    comment: status?.comment || undefined,
                    icon: statusIcon[currentStatus as keyof typeof statusIcon]
                });
            } else if (currentStatus === 'finished') {
                actions.push({
                    header: statusHeader[currentStatus as keyof typeof statusHeader],
                    timestamp: status.createdAt,
                    label: status?.actor ? `By: ${status?.actor}` : '',
                    comment: status?.comment || undefined,
                    icon: statusIcon[currentStatus as keyof typeof statusIcon]
                });
            } else if (currentStatus === 'stopped') {
                actions.push({
                    header: statusHeader[currentStatus as keyof typeof statusHeader],
                    timestamp: status.createdAt,
                    label: status?.actor ? `By: ${status?.actor}` : '',
                    comment: status?.comment || undefined,
                    icon: statusIcon[currentStatus as keyof typeof statusIcon]
                });
            }
        });

        // const actions: IActionItem[] =
        //     statusUpdates?.map((status) => ({
        //         header: statusHeader[status.currentStatus?.toLocaleLowerCase() as keyof typeof statusHeader],
        //         timestamp: status.createdAt,
        //         label: status.currentStatus === 'active' ? 'Campaign is live and running' : status?.actor || '',
        //         comment: status?.comment || undefined,
        //         icon: statusIcon[status.currentStatus?.toLocaleLowerCase() as keyof typeof statusIcon]
        //     })) || [];

        return actions.sort((a, b) => new Date(a.timestamp!).getTime() - new Date(b.timestamp!).getTime());
    };
    return (
        <Card sx={{ paddingX: { xs: '12px', md: 0 } }}>
            <CardContent>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    {title}
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 1, color: '#888' }}>
                    {subtitle}
                </Typography>

                {generateActionLog().map((status: IActionItem, index: number) => (
                    <Stack direction="row" key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <IconButton disabled sx={{ mb: '-8px', mt: '-8px' }}>
                                    <img src={status.icon} alt="status icon" />
                                </IconButton>
                                {/** to not render connector for last item */}
                                {index !== statusUpdates.length - 1 && (
                                    <IconButton disabled>
                                        <img src={connectorIcon} alt="connector" style={{ height: '42px' }} />
                                    </IconButton>
                                )}
                            </Box>
                            <Box sx={{ ml: 2, mb: '-8px' }}>
                                <Typography variant="body1" sx={{ fontWeight: 500, wordBreak: 'break-all' }}>
                                    {status.header}
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#667085', display: 'block' }}>
                                    {new Date(status.timestamp!).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric'
                                    })}{' '}
                                    -{' '}
                                    {new Date(status.timestamp!).toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </Typography>
                                {status.label && (
                                    <Typography variant="caption" sx={{ color: '#667085', display: 'block', wordBreak: 'break-all' }}>
                                        {status.label}
                                    </Typography>
                                )}
                                {status.comment && (
                                    <Typography
                                        variant="caption"
                                        sx={{ color: '#667085', display: 'block', wordBreak: 'break-all', mb: 1 }}
                                    >
                                        <strong>Comment: </strong> {status.comment}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Stack>
                ))}
            </CardContent>
        </Card>
    );
};

export default ActionLog;
