import React from 'react';
import Typography from '@mui/material/Typography';

interface ResponsivePageTitleProps {
    title: string;
    mobileOnly?: boolean;
}

const ResponsivePageTitleComponent: React.FC<ResponsivePageTitleProps> = ({ title, mobileOnly = false }) => {
    return (
        <Typography
            variant="h4"
            sx={{
                display: { xs: 'block', md: mobileOnly ? 'none' : 'block' },
                flex: '1 0 0',
                color: 'var(--Dark-grey, #101828)',
                fontFamily: 'Inter',
                fontSize: 20,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                my: 1,
                ml: 1
            }}
        >
            {title}
        </Typography>
    );
};

export default ResponsivePageTitleComponent;
