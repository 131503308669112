import { IHeaderData } from 'components/common/SearchableTable/interfaces';
import FallbackScenarioRowActions from './FallbackScenarioRowActions';
import PlatformNameCell from '../common/PlatformNameCell';
export const headers: IHeaderData[] = [
    {
        name: 'ID',
        key: 'id',
        hidden: true
    },
    {
        name: 'Platform',
        key: 'platform',
        subKeys: ['name'],
        CustomCellComponent: PlatformNameCell
    },
    {
        name: 'Page',
        key: 'name'
    },
    {
        name: 'CRM',
        key: 'pageFallbackOrganizations',
        type: 'list',
        iterableKeys: ['fallbackOrganization.name'],
        iterable: true,
        defaultValue: '-'
    },
    {
        name: '',
        key: '',
        actionColumn: true,
        ActionColumnComponent: FallbackScenarioRowActions
    }
];
