import { Grid } from '@mui/material';
import SearchableTable from 'components/common/SearchableTable';
import { IFilterOptions, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { headers } from 'components/facebook-lead-balancer/headers';
import CreateLeadBalancerForm from 'components/facebook-lead-balancer/CreateLeadBalancerForm';
import leadBalancerFilters from 'components/facebook-lead-balancer/table-filters';

const LeadBalancer = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});
    const [initialSearchQuery, setInitialSearchQuery] = useState<ISearchQueryType>({
        pageSize: 10,
        page: 1,
        orderDirection: 'DESC'
    });

    const getFilterOptions = async () => {
        try {
            const res = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organizations`);
            const organizations = res.data;
            const partners = organizations.map((organization: { id: any; name: any }) => ({
                value: organization.id,
                label: organization.name
            }));

            const res1 = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/pages`);
            const pagesRes = res1.data;
            const pages = pagesRes.map((page: { id: any; name: any }) => ({
                value: page.id,
                label: page.name
            }));

            setFilterOptions({
                partners: partners,
                pages: pages,
                weightTypes: [
                    {
                        value: 'location',
                        label: 'Location'
                    },
                    {
                        value: 'generic',
                        label: 'Generic'
                    }
                ]
            });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getFilterOptions();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {!isLoading && (
                            <SearchableTable
                                url="/v1/lead-balancer/facebook"
                                name="Facebook Lead Balancer"
                                title="Facebook Lead Balancer"
                                headers={headers}
                                tableFilters={leadBalancerFilters}
                                setData={() => {}}
                                initialSearchQuery={initialSearchQuery}
                                rowsPerPage={initialSearchQuery.pageSize}
                                filterOptions={filterOptions}
                                SecondaryActions={[CreateLeadBalancerForm]}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LeadBalancer;
