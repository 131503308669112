import { Stack, Button, Typography, Box } from '@mui/material';
import arrowLeft from '../../../assets/images/icons/arrow-left.svg';
import arrowRight from '../../../assets/images/icons/arrow-right.svg';

interface PaginationProps {
    page: number;
    totalPages: number;
    setPage: (page: number) => void;
}

const Pagination = ({ page, totalPages, setPage }: PaginationProps) => {
    return (
        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
            <Button
                disabled={page === 1}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '1px solid #E5E7EB',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: 'Inter',
                    color: '#344054',
                    width: { md: '115px' },
                    height: '36px',
                    minWidth: '36px'
                }}
                onClick={() => setPage(page - 1)}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <img src={arrowLeft} alt="arrow" />
                    <Typography sx={{ display: { xs: 'none', md: 'block' } }}>Previous</Typography>
                </Box>
            </Button>
            <Typography
                sx={{
                    alignSelf: 'center',
                    fontSize: '14px',
                    backgroundColor: 'ButtonShadow',
                    borderRadius: '8px',
                    p: 1
                }}
            >
                Page {page} of {totalPages}
            </Typography>
            <Button
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '1px solid #E5E7EB',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: 'Inter',
                    color: '#344054',
                    width: { md: '88px' },
                    minWidth: '36px',
                    height: '36px'
                }}
                disabled={page === totalPages}
                onClick={() => setPage(page + 1)}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography sx={{ display: { xs: 'none', md: 'block' } }}>Next</Typography>
                    <img src={arrowRight} alt="arrow" />
                </Box>
            </Button>
        </Stack>
    );
};

export default Pagination;
