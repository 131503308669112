import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ICustomCellProps } from 'components/common/SearchableTable/interfaces';

const SpentBudgetCell: React.FC<ICustomCellProps> = ({ data: { spent, budget } }) => {
    return (
        <>
            <Box>
                <Typography sx={{ fontWeight: 600 }}>{spent.toLocaleString()} EGP</Typography>
                <Typography sx={{ fontWeight: 400 }}>out of {budget} EGP</Typography>
            </Box>
        </>
    );
};

export default SpentBudgetCell;
