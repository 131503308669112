import {
    Box,
    Card,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from '@mui/material';
import { COLORS } from 'constant';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import MainCard from 'ui-component/cards/MainCard';
import axiosServices from 'utils/axios';
import balanceIcon from '../../assets/images/icons/balanceIcon.svg';
import calendarIcon from '../../assets/images/icons/calendar.svg';
import dartIcon from '../../assets/images/icons/dartIcon.svg';
import arrowDown from '../../assets/images/icons/arrow-down.svg';
import emptyCalendarIcon from '../../assets/images/icons/empty-calendar.svg';
import Pagination from 'components/common/Pagination';
import CustomizedRadioButton from 'components/campaign-form/CustomizedRadioButton';

interface IPartnerWalletSummary {
    id: number;
    organizationCampaignName: string;
    organizationCampaignId: number;
    dailySpend: number;
    budget: number;
    date: string;
    totalOrganizationBudget: number;
    totalOrganizationSpend: number;
}
const PartnerWalletSummary = () => {
    interface RouteParams {
        ownerID: string;

        [key: string]: string | undefined;
    }
    const { id } = useParams<RouteParams>();

    const [partnerWalletSummary, setPartnerWalletSummary] = useState<IPartnerWalletSummary[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [typeOfFilter, setTypeOfFilter] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [organizationName, setOrganizationName] = useState<string>('');

    const fetchOrganizationName = async () => {
        setIsLoading(true);
        try {
            const params = { id: id };
            const organization = await axiosServices.get(`v1/organizations/query`, { params });
            setOrganizationName(organization.data.results[0].name);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    // alert: {
                    //     color: 'error'
                    // },
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const params: any = {
                page: page,
                pageSize: 10,
                ...(startDate && { startDate }),
                ...(endDate && { endDate })
            };

            const res1 = await axiosServices.get(`v1/organizations/wallet-summary/${id}`, { params });

            setPartnerWalletSummary(res1.data.results);
            setTotalPages(Math.ceil(res1.data.total / 10));
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    // alert: {
                    //     color: 'error'
                    // },
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;

        if (newValue === typeOfFilter) {
            setTypeOfFilter(null);
            setStartDate(null);
            setEndDate(null);
            setPage(1);
            return;
        }

        setTypeOfFilter(newValue);

        const today = dayjs();
        let newStartDate = null;
        let newEndDate = null;

        switch (newValue) {
            case 'thisMonth':
                newStartDate = today.startOf('month').format('YYYY-MM-DD');
                newEndDate = today.format('YYYY-MM-DD');
                break;
            case 'lastMonth':
                const lastMonth = today.subtract(1, 'month');
                newStartDate = lastMonth.startOf('month').format('YYYY-MM-DD');
                newEndDate = lastMonth.endOf('month').format('YYYY-MM-DD');
                break;
            case 'thisQuarter': {
                const currentQuarter = Math.floor(today.month() / 3);
                const quarterStart = today.month(currentQuarter * 3).startOf('month');
                const quarterEnd = today.month(currentQuarter * 3 + 2).endOf('month');

                newStartDate = quarterStart.format('YYYY-MM-DD');
                newEndDate = quarterEnd.format('YYYY-MM-DD');
                break;
            }
            case 'lastQuarter': {
                const currentQuarter = Math.floor(today.month() / 3);
                let lastQuarterStart;
                let lastQuarterEnd;

                if (currentQuarter === 0) {
                    // current quarter is Q1, last quarter was Q4 of previous year
                    lastQuarterStart = today.subtract(1, 'year').month(9).startOf('month');
                    lastQuarterEnd = today.subtract(1, 'year').month(11).endOf('month');
                } else {
                    // last quarter was in same year
                    lastQuarterStart = today.month((currentQuarter - 1) * 3).startOf('month');
                    lastQuarterEnd = today.month((currentQuarter - 1) * 3 + 2).endOf('month');
                }

                newStartDate = lastQuarterStart.format('YYYY-MM-DD');
                newEndDate = lastQuarterEnd.format('YYYY-MM-DD');
                break;
            }
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setPage(1);
    };

    const handleMenuItemClick = (value: string) => {
        if (value === typeOfFilter) {
            setTypeOfFilter(null);
            setStartDate(null);
            setEndDate(null);
            setPage(1);
        } else {
            handleFilterChange({ target: { value } } as SelectChangeEvent<string>);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, typeOfFilter]);

    useEffect(() => {
        fetchOrganizationName();
    }, []);

    // const getTransactionColor = (type: string) => {
    //     switch (type) {
    //         case 'ADDITION':
    //         case 'TOP_UP':
    //             return '#039855';
    //         case 'DEDUCTION':
    //         case 'WITHDRAWAL':
    //             return '#F04438';
    //         default:
    //             return '#101828';
    //     }
    // };

    return (
        <>
            <Box sx={{ display: { md: 'none' }, padding: '12px', backgroundColor: '#F7F7F7' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Wallet</Typography>
            </Box>
            {/* Content Section */}

            <Grid container direction={{ xs: 'column', md: 'row' }} sx={{ gap: { xs: 3, md: 0 } }} spacing={{ xs: 0, md: 3 }}>
                {/* Date Card */}
                <Grid
                    item
                    xs={12}
                    md={8}
                    order={{ xs: 2, md: 1 }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: { xs: 'start', md: 'center' },
                        flexDirection: { md: 'row', xs: 'column' },
                        padding: { xs: '12px', md: '0px' },
                        mb: 3
                    }}
                >
                    <Typography variant="h3">Partner Transactions</Typography>
                    <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
                        <Stack direction="column">
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="caption" sx={{ color: COLORS.LABEL_COLOR, mr: 1 }}>
                                    Date
                                </Typography>
                                <Typography variant="caption">
                                    <IconButton disabled>
                                        <img src={arrowDown} alt="arrow down" />
                                    </IconButton>
                                </Typography>
                            </Stack>
                            <Select
                                IconComponent={() => null}
                                value={typeOfFilter || ''}
                                onChange={handleFilterChange}
                                sx={{
                                    width: { xs: '100%', md: '200px' },
                                    height: '44px',
                                    // backgroundColor: 'white',
                                    '& .MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        py: 1,
                                        pl: 2
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#D0D5DD',
                                        borderRadius: '8px'
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 1,
                                            '& .MuiList-root': {
                                                p: 1
                                            }
                                        }
                                    }
                                }}
                                displayEmpty
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <img src={calendarIcon} alt="calendar" style={{ width: 20, height: 20 }} />
                                        <Typography sx={{ fontSize: '14px', color: selected ? '#101828' : '#667085' }}>
                                            {!selected
                                                ? 'No Date selected'
                                                : {
                                                      thisMonth: 'This Month',
                                                      lastMonth: 'Last Month',
                                                      thisQuarter: 'This Quarter',
                                                      lastQuarter: 'Last Quarter'
                                                  }[selected]}
                                        </Typography>
                                    </Box>
                                )}
                            >
                                {['thisMonth', 'lastMonth', 'thisQuarter', 'lastQuarter'].map((value) => (
                                    <MenuItem
                                        key={value}
                                        value={value}
                                        onClick={() => handleMenuItemClick(value)}
                                        sx={{
                                            borderRadius: '6px',
                                            '&:hover': {
                                                groundColor: '#F9FAFB'
                                            }
                                        }}
                                    >
                                        <CustomizedRadioButton checked={typeOfFilter === value} />
                                        <Typography sx={{ ml: 1, fontSize: '14px', color: '#344054' }}>
                                            {value === 'thisMonth'
                                                ? 'This Month'
                                                : value === 'lastMonth'
                                                ? 'Last Month'
                                                : value === 'thisQuarter'
                                                ? 'This Quarter'
                                                : 'Last Quarter'}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Box>
                </Grid>
                {/* Main Content */}
                <Grid item xs={12} md={8} order={{ xs: 3, md: 2 }}>
                    {!isLoading && (
                        <MainCard>
                            <Typography variant="h3" sx={{ mb: 2 }}>
                                {organizationName || 'Partner Name'}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            {partnerWalletSummary?.length > 0 ? (
                                <>
                                    <Stack direction="column" spacing={2}>
                                        {partnerWalletSummary?.map((walletSummary) => (
                                            <Box
                                                key={walletSummary.id}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    px: { md: 2, xs: 0 },
                                                    py: { md: 2, xs: 2 },
                                                    gap: 1,
                                                    borderBottom: '1px solid #E5E7EB',
                                                    '&:hover': {
                                                        backgroundColor: '#F9FAFB'
                                                    }
                                                }}
                                            >
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <Box
                                                        sx={{
                                                            width: 44,
                                                            height: 44,
                                                            borderRadius: '8px',
                                                            backgroundColor: '#F2F4F7',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <img src={dartIcon} alt="dart" />
                                                    </Box>
                                                    <Stack direction="column" spacing={0.5}>
                                                        <Typography variant="body1" sx={{ color: '#101828', fontWeight: 500 }}>
                                                            Campaign ID: {walletSummary.organizationCampaignId} - [DEDUCTION]
                                                        </Typography>
                                                        <Typography variant="caption" sx={{ color: '#667085' }}>
                                                            {new Date(walletSummary.date).toLocaleDateString('en-GB', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            })}{' '}
                                                            at{' '}
                                                            {new Date(walletSummary.date).toLocaleTimeString('en-GB', {
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            })}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: '#F04438',
                                                        whiteSpace: 'nowrap',
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    -{walletSummary?.dailySpend?.toLocaleString() || 0} EGP
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>

                                    {/* Pagination */}
                                    <Pagination page={page} totalPages={totalPages} setPage={setPage} />
                                </>
                            ) : (
                                <Stack sx={{ py: 10 }} alignItems="center">
                                    <ImageList
                                        sx={{
                                            width: { xs: 47, md: 55 },
                                            height: { xs: 47, md: 55 },
                                            borderRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 2
                                        }}
                                    >
                                        <ImageListItem>
                                            <img src={emptyCalendarIcon} alt="emptyCalendarIcon" />
                                        </ImageListItem>
                                    </ImageList>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, maxWidth: '300px' }}>
                                        No transactions available for this Date
                                    </Typography>
                                </Stack>
                            )}
                        </MainCard>
                    )}
                </Grid>

                {/* Right Card */}
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <Box>
                        <Card
                            sx={{
                                padding: { xs: '12px', md: '24px' },
                                py: { xs: '12px', md: 0 },
                                backgroundColor: { xs: 'white', md: 'white' }
                            }}
                        >
                            <Stack
                                direction={{ xs: 'row', md: 'column' }}
                                sx={{ p: { xs: 1, md: 0 }, py: { xs: 2, md: 2 }, backgroundColor: { xs: '#F7F7F7', md: 'white' } }}
                                spacing={2}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <ImageList
                                        sx={{
                                            width: { xs: 32, md: 44 },
                                            height: { xs: 32, md: 44 },
                                            borderRadius: '8px',
                                            backgroundColor: '#F2F4F7',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <ImageListItem>
                                            <img src={balanceIcon} alt="balanceIcon" />
                                        </ImageListItem>
                                    </ImageList>
                                </Stack>
                                <Stack
                                    direction={{ xs: 'row', md: 'column' }}
                                    alignItems={{ xs: 'center', md: 'start' }}
                                    spacing={{ xs: 1, md: 0 }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: '400',
                                            fontFamily: 'Inter',
                                            color: '#101828',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        Total spent
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: '20px', md: '34px' },
                                            fontWeight: '600',
                                            fontFamily: 'Inter',
                                            color: '#101828',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {partnerWalletSummary[0]?.totalOrganizationSpend?.toLocaleString() || 0} EGP
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default PartnerWalletSummary;
