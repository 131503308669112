import { createSlice } from '@reduxjs/toolkit';
import { queryPages } from './actions';
import { QueryPagesInitialStateInterface } from './interfaces';

// initial state
const initialState: QueryPagesInitialStateInterface = {
    pages: {}
};

// ==============================|| SLICE - cils ||============================== //

const pages = createSlice({
    name: 'pages',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryPages.pending, (state) => {})
            .addCase(queryPages.fulfilled, (state, action) => {
                state.pages = action.payload;
            })
            .addCase(queryPages.rejected, (state, action) => {})
            .addDefaultCase((state, action) => {});
    }
});

export default pages.reducer;
