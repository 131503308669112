export interface ICampaignSummary {
    name: string;
    budgetPerDay: number;
    startDate: Date;
    endDate: Date;
    numberOfDays: number;
    startImmediately: boolean;
    Locations: string[];
    expectedLeadsPerDay: number;
    exclusiveLeads: boolean;
    maximumCampaignSpent: number;
    status?: string;
    statusType?: CampaignStatusEnum;
    approved?: boolean;
    rejected?: boolean;
    deletedAt?: Date;
    createdBy?: string; // organization name
    statusUpdates?: IStatus[];
}
export interface IActionLogStatusUpdates {
    statusUpdates: IStatus[];
    organizationName: string;
}
export interface IStatus {
    id?: number;
    createdAt?: Date;
    currentStatusId?: number;
    currentStatus?: string;
    organizationCampaignId?: number;
    actor?: string;
    comment?: string;
    organizationName?: string;
}

enum subscriptionType {
    leadCount = 'lead_count',
    budget = 'editor'
}

export enum CampaignStatusEnum {
    pending = 'pending',
    approved = 'approved',
    rejected = 'rejected',
    live = 'active',
    paused = 'paused',
    finished = 'finished',
    stopped = 'stopped'
}

interface IBase {
    id: number;
    uid: string;
    deletedAt: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface IOrganization extends IBase {
    name: string;
}

interface IOrganizationCampaignStatus extends IBase {
    name: string;
    type?: CampaignStatusEnum;
}

interface ILocation extends IBase {
    name: string;
}

interface IOrganizationCampaignEntity extends IBase {
    entityId: number;
    entityType: string;
    location: ILocation;
}

export interface IOrganizationCampaign extends IBase {
    name: string;
    platformId: number;
    organization: IOrganization;
    organizationCampaignStatus: IOrganizationCampaignStatus;
    organizationCampaignEntities: IOrganizationCampaignEntity[];
    subscriptionType: subscriptionType;
    budget: number;
    leadCount: number;
    costPerLead: number;
    spendLimit: number;
    startDate: Date;
    endDate: Date;
    numberOfDays: number;
    startImmediately: boolean;
    createdBy?: string; // organization name
    rejectedComment?: string;
    // createdAt?: Date; exists in IBase
    approvedAt?: Date;
    approvedBy?: string;
    rejectedAt?: Date;
    rejectedBy?: string;
    liveAt?: Date;
    pausedAt?: Date;
    pausedComment?: string;
    resumedAt?: Date;
    finishedAt?: Date;
    actor?: string;
    statusUpdates?: IStatus[];
}

export interface IOrganizationCampaignApproval extends IBase {
    organizationCampaign: IOrganizationCampaign;
    approved?: boolean;
    rejected?: boolean;
    statusUpdates?: IStatus[];
}
