import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useRef, useState } from 'react';
import { FilterTypeEnum, ISearchQueryType, ITableFilter, ITableFiltersProps } from './interfaces';
import SearchIcon from '@mui/icons-material/Search';
import { COLORS } from 'constant';
import CustomDateRangePicker from 'ui-component/CustomDateRangePicker/CustomDateRangePicker';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const TableFilters: React.FC<ITableFiltersProps> = ({
    inputRefs,
    handleClearFilters,
    filters,
    tableFilters,
    handleFilter,
    filterOptions,
    isFilterInTableTitle
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [tmpFilters, setTmpFilters] = useState<any>({});
    const textSearchRef = useRef<HTMLInputElement>(null);
    const [paginatedOptions, setPaginatedOptions] = useState<{ [key: string]: any[] }>({});
    const [paginatedQueries, setPaginatedQueries] = useState<{ [key: string]: ISearchQueryType }>({});
    const [nonPaginatedOptions, setNonPaginatedOptions] = useState<{ [key: string]: any[] }>({});

    const debounce = (fn: Function, ms = 1000) => {
        let timeoutId: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: any[]) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn.apply(this, args), ms);
        };
    };

    const handleListFilter = (values: any, filter: ITableFilter) => {
        switch (filter.filterType) {
            case FilterTypeEnum.LIST:
                if (values.length > 0) {
                    const list = values.map((value: any) => value.id);
                    handleFilter(filter.filterName, list);
                } else {
                    handleFilter(filter.filterName, null);
                }
                break;
            case FilterTypeEnum.SELECT_ONE:
                if (values) {
                    const val = values.id;
                    handleFilter(filter.filterName, val);
                } else {
                    handleFilter(filter.filterName, null);
                }
                break;
        }
    };

    useEffect(() => {
        setTmpFilters({ ...filters });
    }, [isOpen]);

    // initial paginated queries
    useEffect(() => {
        const initialQueries: { [key: string]: ISearchQueryType } = {};
        tableFilters?.forEach((filter) => {
            if (filter.paginated && filter.initialSearchQuery) {
                initialQueries[filter.filterName] = filter.initialSearchQuery;
                fetchPaginatedOptions(filter, filter.initialSearchQuery);
            } else {
                fetchNonPaginatedOptions(filter);
            }
        });
        setPaginatedQueries(initialQueries);
    }, [tableFilters]);

    const fetchNonPaginatedOptions = async (filter: ITableFilter) => {
        if (filter.filterType !== FilterTypeEnum.LIST && filter.filterType !== FilterTypeEnum.SELECT_ONE) return;
        try {
            if (filter.paginated || !filter.url) {
                const optionsKey = filter.optionsKey ?? '';
                const options = filterOptions[optionsKey]; // might be undefined

                if (Array.isArray(options) && options.length > 0 && (options[0]?.value || options[0]?.label)) {
                    options.forEach((element: { id: any; value: any; label: any; name: any }) => {
                        element.id = element.value;
                        element.name = element.label;
                    });
                }
                setNonPaginatedOptions((prev) => ({
                    ...prev,
                    [filter.filterName]: options ?? []
                }));
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}${filter.url}`);
            setNonPaginatedOptions((prev) => ({
                ...prev,
                [filter.filterName]: response.data.results
            }));
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Could not fetch options for ${filter.title || filter.filterName}`,
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    // getting paginated options
    const fetchPaginatedOptions = async (filter: ITableFilter, query: ISearchQueryType) => {
        if (!filter.paginated || !filter.url) return;

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}${filter.url}`, {
                params: query
            });
            setPaginatedOptions((prev) => ({
                ...prev,
                [filter.filterName]: response.data.results
            }));
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Could not fetch options for ${filter.title || filter.filterName}`,
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    // search for paginated filters
    const debouncedSearch = debounce((filter: ITableFilter, searchValue: string) => {
        setPaginatedQueries((prev) => ({
            ...prev,
            [filter.filterName]: {
                ...prev[filter.filterName],
                name: searchValue,
                page: 1
            }
        }));
    }, 500);

    useEffect(() => {
        tableFilters?.forEach((filter) => {
            if (filter.paginated && paginatedQueries[filter.filterName]) {
                fetchPaginatedOptions(filter, paginatedQueries[filter.filterName]);
            }
        });
    }, [paginatedQueries]);

    return (
        <Box>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={{ xs: 1 }}>
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    sx={{ px: { xs: 2, md: 0 } }}
                    justifyContent="flex-start"
                    alignItems="center"
                    columnSpacing={{ xs: 2 }}
                    rowSpacing={{ xs: 1 }}
                >
                    {tableFilters?.slice(0, 5).map((filter: ITableFilter, id: number) => (
                        <Grid xs={filter.gridSize || 12} md={isFilterInTableTitle ? 12 : 3} item key={filter.filterName}>
                            {filter.title !== 'Date' && (
                                <Typography variant="caption" sx={{ color: COLORS.LABEL_COLOR, mb: 2 }}>
                                    {filter.title}
                                </Typography>
                            )}
                            {filter.filterType === FilterTypeEnum.TEXT && (
                                <TextField
                                    placeholder={filter.placeholder}
                                    inputRef={(element: any) => (inputRefs.current[id] = element)}
                                    size="small"
                                    defaultValue={tmpFilters?.[filter.filterName]}
                                    variant="outlined"
                                    ref={textSearchRef}
                                    onChange={(e: any) => {
                                        debounce(handleFilter(filter.filterName, e.target.value));
                                        if (isFilterInTableTitle) {
                                            textSearchRef.current?.scrollIntoView();
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        width: filter.width ? filter.width : 'undefined'
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                            {filter.filterType === FilterTypeEnum.LIST && (
                                <Autocomplete
                                    ref={(element: any) => (inputRefs.current[id] = element)}
                                    // defaultValue={getListFilterValue(filter)}
                                    multiple
                                    size="small"
                                    clearOnBlur
                                    options={paginatedOptions[filter.filterName] || nonPaginatedOptions[filter.filterName] || []}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} placeholder={filter.placeholder} />}
                                    onChange={(event: any, newValue: any) => {
                                        handleListFilter(newValue, filter);
                                    }}
                                    onInputChange={(event: any, newValue: any) => {
                                        debouncedSearch(filter, newValue);
                                    }}
                                />
                            )}
                            {filter.filterType === FilterTypeEnum.SELECT_ONE && (
                                <Autocomplete
                                    ref={(element: any) => (inputRefs.current[id] = element)}
                                    size="small"
                                    options={paginatedOptions[filter.filterName] || nonPaginatedOptions[filter.filterName] || []}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} placeholder={filter.placeholder} />}
                                    onChange={(event: any, newValue: any) => {
                                        handleListFilter(newValue, filter);
                                    }}
                                    onInputChange={(event: any, newValue: any) => {
                                        debouncedSearch(filter, newValue);
                                    }}
                                />
                            )}
                            {filter.filterType === FilterTypeEnum.CHECKBOX && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputRef={(element: any) => (inputRefs.current[id] = element)}
                                            defaultValue={tmpFilters?.[filter.filterName]}
                                            onClick={(e: any) => {
                                                handleFilter(filter.filterName, e.target.checked);
                                            }}
                                        />
                                    }
                                    label={filter.filterName}
                                />
                            )}
                            {filter.filterType === FilterTypeEnum.DATE && (
                                <CustomDateRangePicker filter={filter} handleFilter={handleFilter} />
                            )}
                        </Grid>
                    ))}
                    {isOpen &&
                        tableFilters?.slice(5, tableFilters.length).map((filter: ITableFilter, id: number) => (
                            <Grid xs={2} ml={id % 5 === 0 ? 6 : 0} item key={filter.filterName}>
                                {filter.filterType === FilterTypeEnum.TEXT && (
                                    <TextField
                                        inputRef={(element: any) => (inputRefs.current[id + 5] = element)}
                                        size="small"
                                        label={filter.filterName}
                                        defaultValue={tmpFilters?.[filter.filterName]}
                                        variant="outlined"
                                        onChange={(e: any) => {
                                            debounce(handleFilter(filter.filterName, e.target.value));
                                        }}
                                    />
                                )}
                                {filter.filterType === FilterTypeEnum.LIST && (
                                    <Autocomplete
                                        ref={(element: any) => (inputRefs.current[id + 5] = element)}
                                        // defaultValue={getListFilterValue(filter)}
                                        multiple
                                        size="small"
                                        clearOnBlur
                                        options={paginatedOptions[filter.filterName] || nonPaginatedOptions[filter.filterName] || []}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label={filter.filterName} />}
                                        onChange={(event: any, newValue: any) => {
                                            handleListFilter(newValue, filter);
                                        }}
                                        onInputChange={(event: any, newValue: any) => {
                                            debouncedSearch(filter, newValue);
                                        }}
                                    />
                                )}
                                {filter.filterType === FilterTypeEnum.SELECT_ONE && (
                                    <Autocomplete
                                        ref={(element: any) => (inputRefs.current[id + 5] = element)}
                                        // defaultValue={getListFilterValue(filter)}
                                        size="small"
                                        options={paginatedOptions[filter.filterName] || nonPaginatedOptions[filter.filterName] || []}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label={filter.filterName} />}
                                        onChange={(event: any, newValue: any) => {
                                            handleListFilter(newValue, filter);
                                        }}
                                        onInputChange={(event: any, newValue: any) => {
                                            debouncedSearch(filter, newValue);
                                        }}
                                    />
                                )}
                                {filter.filterType === FilterTypeEnum.CHECKBOX && (
                                    <FormControlLabel
                                        inputRef={(element: any) => (inputRefs.current[id + 5] = element)}
                                        control={
                                            <Checkbox
                                                defaultChecked={tmpFilters?.[filter.filterName]}
                                                onClick={(e: any) => {
                                                    handleFilter(filter.filterName, e.target.checked);
                                                }}
                                            />
                                        }
                                        label={filter.filterName}
                                    />
                                )}
                                {filter.filterType === FilterTypeEnum.DATE && (
                                    <CustomDateRangePicker filter={filter} handleFilter={handleFilter} />
                                )}
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </Box>
    );
};
export default TableFilters;
