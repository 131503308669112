import { CMS_API, formattedQuery } from 'store/apis';
import { QueryOrganizationsInterface } from './interfaces';

const ORGS_APIS = {
    query: (query: QueryOrganizationsInterface) => {
        const formatQuery: string[] = [CMS_API, '/v1/organizations/search', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default ORGS_APIS;
