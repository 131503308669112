import { SyntheticEvent } from 'react';

// material-ui
import { Alert, AlertColor, Button, Fade, Grow, IconButton, Slide, SlideProps, Typography } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
// assets
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/system';
import { useDispatch, useSelector } from 'store';
import { headerHeight } from 'store/constant';
import { closeSnackbar } from 'store/slices/snackbar';
import { KeyedObject } from 'types';
import { SnackbarProps, SnackBarType } from 'types/snackbar';
// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

const defaultHeaders = {
    [SnackBarType.success]: 'Successful!',
    [SnackBarType.error]: 'Something went wrong!',
    [SnackBarType.warning]: 'Warning!',
    [SnackBarType.info]: '',
    [SnackBarType.default]: ''
};

const colorMap = {
    [SnackBarType.success]: { background: 'rgba(212, 255, 229, 1)', borderColor: 'rgba(40, 214, 89, 0.6)' },
    [SnackBarType.error]: { background: 'rgba(252, 200, 200, 1)', borderColor: 'rgba(252, 87, 88, 0.6)' },
    [SnackBarType.warning]: { background: 'rgba(254, 243, 197, 1)', borderColor: 'rgba(255, 199, 39, 1)' },
    [SnackBarType.info]: { background: '#E3F2FD', borderColor: '#2196F3' },
    [SnackBarType.default]: { background: '#FFFFFF', borderColor: '#E0E0E0' }
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const snackbar = useSelector((state) => state.snackbar);
    const { actionButton, anchorOrigin, alert, close, message, open, transition, variant, header, hideDuration, action }: SnackbarProps =
        snackbar;
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    return (
        <>
            {/* default snackbar */}
            {variant === SnackBarType.default && (
                <MuiSnackbar
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={hideDuration}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={action}>
                                Undo
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant !== SnackBarType.default && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    open={open}
                    autoHideDuration={hideDuration}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: matchDownMd ? 'center' : 'right' }}
                    sx={{
                        mt: !matchDownMd ? 8 : 0,
                        maxWidth: '457px'
                    }}
                >
                    <Alert
                        color={alert.color ? alert.color : (variant as AlertColor)}
                        severity={alert.severity ? alert.severity : (variant as AlertColor)}
                        variant={alert.variant ? alert.variant : 'outlined'}
                        action={
                            <>
                                {actionButton && (
                                    <Button size="small" onClick={action} color={alert.color ? alert.color : (variant as AlertColor)}>
                                        Undo
                                    </Button>
                                )}
                                {close && (
                                    <IconButton sx={{ color: '#6F6F6A' }} size="small" aria-label="close" onClick={handleClose}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </>
                        }
                        sx={{
                            bgcolor: colorMap[variant as keyof typeof colorMap].background,
                            border: `1px solid ${colorMap[variant as keyof typeof colorMap].borderColor}`
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: '500',
                                lineHeight: '21.78px',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                                textWrap: 'break-word'
                            }}
                        >
                            {header || defaultHeaders[variant as keyof typeof defaultHeaders]}
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '20px',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                                textWrap: 'break-word'
                            }}
                        >
                            {message}
                        </Typography>
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export default Snackbar;
