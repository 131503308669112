import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import axiosServices from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import StopIcon from 'assets/images/actions/stop.svg';
import PauseIcon from 'assets/images/actions/pause.svg';
import ResumeIcon from 'assets/images/actions/resume.svg';
import { CampaignStatusEnum } from 'components/campaign-summary/campaign-summary.interface';
import { useState } from 'react';
import SimpleConfirmModal from 'components/common/SimpleConfirmModal';
import { COLORS } from 'constant';

interface IProps {
    id: number;
    data: any;
    type: string;
    setRows?: React.Dispatch<React.SetStateAction<never[]>>;
    refresh: () => void;
    isTableView: boolean;
}

const CampaignRowActions: React.FC<IProps> = ({ id, data, refresh, isTableView }) => {
    const [pauseConfirmationModalIsOpen, setPauseConfirmationModalIsOpen] = useState<boolean>(false);
    const [stopConfirmationModalIsOpen, setStopConfirmationModalIsOpen] = useState<boolean>(false);

    const handleUpdateCampaignStatus = async (live?: boolean, pause?: boolean, stop?: boolean) => {
        try {
            await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organization-campaigns/${id}`, {
                ...(live && { live: true }),
                ...(pause && { pause: true }),
                ...(stop && { stop: true })
            });
            refresh();
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Campaign ${live ? 'is live' : pause ? 'is paused' : 'has stopped'}!`,
                    variant: 'success',
                    close: true
                })
            );
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const isActiveTab =
        data.organizationCampaignStatus.type === CampaignStatusEnum.live ||
        data.organizationCampaignStatus.type === CampaignStatusEnum.paused;

    const isPaused = data.organizationCampaignStatus.type === CampaignStatusEnum.paused;

    const isLive = data.organizationCampaignStatus.type === CampaignStatusEnum.live;

    return (
        <Grid>
            {data.organizationCampaignStatus.type === CampaignStatusEnum.approved && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexDirection: isTableView ? 'row-reverse' : 'row'
                    }}
                >
                    {isTableView ? (
                        <Button
                            variant="text"
                            sx={{
                                display: 'flex',
                                width: '134px',
                                padding: '10px 16px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                borderRadius: '8px',
                                marginLeft: '10px',
                                color: COLORS.GREEN,
                                backgroundColor: '#F7F7F7',
                                '&:hover': {
                                    backgroundColor: '#f1f1f1'
                                }
                            }}
                            onClick={() => handleUpdateCampaignStatus(true, false, false)}
                        >
                            Mark as live
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ backgroundColor: '#17B26A', color: 'white', borderRadius: '8px', width: '120px' }}
                            disableElevation
                            onClick={() => {
                                handleUpdateCampaignStatus(true, false, false);
                            }}
                        >
                            Mark as live
                        </Button>
                    )}
                </Box>
            )}
            {isActiveTab && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            flexDirection: isTableView ? 'row-reverse' : 'row'
                        }}
                    >
                        {isTableView ? (
                            <Button
                                variant="text"
                                color="inherit"
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    borderRadius: '4px',
                                    padding: '8px',
                                    gap: '8px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#F7F7F7',
                                    '&:hover': {
                                        backgroundColor: '#f1f1f1'
                                    }
                                }}
                                aria-label={isPaused ? 'resume' : 'pause'}
                                onClick={() => {
                                    isLive ? setPauseConfirmationModalIsOpen(true) : handleUpdateCampaignStatus(true, false, false);
                                }}
                            >
                                <img src={isLive ? PauseIcon : ResumeIcon} alt={isLive ? 'Pause' : 'Resume'} />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        display: 'inline',
                                        fontFamily: 'Inter',
                                        fontSize: 14,
                                        fontStyle: 'normal',
                                        fontWeight: 500
                                    }}
                                >
                                    {isLive ? 'Pause' : 'Resume'}
                                </Typography>
                            </Button>
                        ) : (
                            <IconButton
                                aria-label={isPaused ? 'resume' : 'pause'}
                                onClick={() => {
                                    isLive ? setPauseConfirmationModalIsOpen(true) : handleUpdateCampaignStatus(true, false, false);
                                }}
                            >
                                <img src={isLive ? PauseIcon : ResumeIcon} alt={isLive ? 'Pause' : 'Resume'} />
                            </IconButton>
                        )}
                        <Button
                            variant="text"
                            color="inherit"
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                borderRadius: '4px',
                                padding: '8px',
                                gap: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#F7F7F7',
                                '&:hover': {
                                    backgroundColor: '#f1f1f1'
                                }
                            }}
                            onClick={() => {
                                setStopConfirmationModalIsOpen(true);
                            }}
                        >
                            <img src={StopIcon} alt="Stop" />
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'inline',
                                    color: 'var(--Red, #BA0000)',
                                    fontFamily: 'Inter',
                                    fontSize: 14,
                                    fontStyle: 'normal',
                                    fontWeight: 500
                                }}
                            >
                                Stop
                            </Typography>
                        </Button>
                    </Box>
                </>
            )}
            {pauseConfirmationModalIsOpen && (
                <SimpleConfirmModal
                    open={pauseConfirmationModalIsOpen}
                    onClose={() => setPauseConfirmationModalIsOpen(false)}
                    onConfirm={() => handleUpdateCampaignStatus(false, true, false)}
                    title="Are you sure you want to pause the campaign?"
                    confirmText="Yes Pause"
                />
            )}
            {stopConfirmationModalIsOpen && (
                <SimpleConfirmModal
                    open={stopConfirmationModalIsOpen}
                    onClose={() => setStopConfirmationModalIsOpen(false)}
                    onConfirm={() => handleUpdateCampaignStatus(false, false, true)}
                    title="Are you sure you want to stop the campaign?"
                    confirmText="Yes Stop"
                />
            )}
        </Grid>
    );
};

export default CampaignRowActions;
