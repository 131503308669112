import { LocationType } from 'components/locations/interfaces';
import { ILocation } from './location.interface';

export enum subscriptionType {
    leadCount = 'lead_count',
    budget = 'budget'
}

export type timeType = 'Immediately' | 'setDate';

export interface ICampaignRequest {
    name: string | null;
    organizationId: number | null;
    subscriptionType: subscriptionType;
    budget: number | null;
    leadCount: number | null;
    spendLimit?: number | null;
    startDate?: Date | null;
    endDate?: Date | null;
    locationIds: number[] | null;
    locations: ILocation[] | null;
    leadsPerDay?: number | null;
    budgetPerDay?: number | null;
    numberOfDays?: number | null;
    locationType: LocationType | null;
    campaignTimeType: timeType | null;
}

export interface ICreateCampaign {
    name: string;
    organizationId: number;
    subscriptionType: subscriptionType;
    budget: number;
    leadCount: number;
    spendLimit?: number;
    locationType: LocationType;
    locationIds: number[];
    startDate?: Date;
    endDate?: Date;
    numberOfDays?: number;
    // campaignTimeType: timeType;
}
