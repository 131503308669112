import { useMediaQuery } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/system';

const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(false);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setIsMobile(isMdDown);
    }, [isMdDown]);

    return { isMobile };
};

export default useDeviceType;
