import { createSlice } from '@reduxjs/toolkit';
import { queryOrganizations } from './actions';
import { QueryOrganizationsInitialStateInterface } from './interfaces';

// initial state
const initialState: QueryOrganizationsInitialStateInterface = {
    organizations: {
        organizationName: '',
        leadsCount: 0,
        pages: []
    }
};

// ==============================|| SLICE - cils ||============================== //

const organizations = createSlice({
    name: 'organizations',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryOrganizations.pending, (state) => {})
            .addCase(queryOrganizations.fulfilled, (state, action) => {
                state.organizations = action.payload;
            })
            .addCase(queryOrganizations.rejected, (state, action) => {})
            .addDefaultCase((state, action) => {});
    }
});

export default organizations.reducer;
