// material-ui
import { AlertProps, SnackbarOrigin } from '@mui/material';

// ==============================|| SNACKBAR TYPES  ||============================== //

export interface SnackbarProps {
    action: () => void;
    open: boolean;
    header: string;
    message: string;
    anchorOrigin: SnackbarOrigin;
    variant: SnackBarType; // type
    alert: AlertProps;

    transition: TransitionEnum;
    close: boolean;
    hideDuration: number;
    actionButton: boolean;
}

export enum SnackBarType {
    default = 'default',
    alert = 'alert',
    error = 'error',
    warning = 'warning',
    info = 'info',
    success = 'success'
}

export enum TransitionEnum {
    Fade = 'Fade',
    Grow = 'Grow',
    SlideDown = 'SlideDown',
    SlideLeft = 'SlideLeft',
    SlideRight = 'SlideRight',
    SlideUp = 'SlideUp'
}
