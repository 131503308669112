import { IHeaderData } from 'components/common/SearchableTable/interfaces';

export const headers: IHeaderData[] = [
    {
        name: 'ID',
        key: 'id',
        hidden: true
    },
    {
        name: 'Campaign name',
        key: 'organizationCampaign',
        subKeys: ['name']
        // clickable: true,
        // linkPrefix: ''
    },
    {
        name: 'Partner name',
        key: 'organizationCampaign',
        subKeys: ['organization', 'name']
    },
    {
        name: 'Location',
        key: 'organizationCampaign',
        subKeys: ['organizationCampaignEntities'],
        iterableKeys: ['location', 'name'],
        type: 'list',
        iterable: true
    },
    {
        name: 'Status',
        key: 'organizationCampaign',
        subKeys: ['organizationCampaignStatus', 'type'],
        statusChip: true
    },
    {
        name: 'est. Cost/Lead',
        key: 'organizationCampaign',
        subKey: 'costPerLead',
        valueType: 'price'
    },
    {
        name: 'est. Avg Lead',
        key: 'organizationCampaign',
        subKey: 'leadCount'
    },
    {
        name: 'Request/Partner Budget',
        key: 'organizationCampaign',
        subKey: 'id'
    },
    // {
    //     name: 'Approve/Reject',
    //     key: '',
    //     actionColumn: true,
    //     ActionColumnComponent: CampaignRequestRowActions
    // },
    {
        name: '',
        key: '',
        externalLinkCell: true,
        linkPrefix: 'campaign-requests'
    }
];
