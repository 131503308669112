import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from '../../store/slices/snackbar';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'utils/axios';
import { ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import CustomTypography from 'ui-component/CustomTypography';

interface Props {
    id: number;
    data: any;
    isOpen: boolean;
    handleClose: () => void;
    refresh: () => void;
}

interface Organization {
    id: number;
    name: string;
}

const EditFallbackScenarioDialog: React.FC<Props> = ({ id, data, isOpen, handleClose, refresh }) => {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<Organization[]>([]);
    const [failedOrganizations, setFailedOrganizations] = useState<Organization[]>([]);
    const initialSearchQuery: ISearchQueryType = {
        pageSize: 50,
        page: 1,
        name: '',
        hasActiveAgents: true
    };
    const [query, setQuery] = useState<ISearchQueryType>(initialSearchQuery);
    //
    const fetchOrganizations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/v1/organizations/query`, {
                params: query
            });
            setOrganizations(response.data.results);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Could not fetch organizations',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const fetchSelectedOrganizations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/v1/pages/${id}/fallback-organizations`);
            setSelectedOrganizations(response.data);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Could not fetch fallback organization',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        fetchOrganizations();
        fetchSelectedOrganizations();
    }, [query]);

    const handleSave = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND}/v1/pages/fallback-organizations`, {
                id,
                fallbackOrganizationIds: selectedOrganizations.map((organization) => organization.id)
            });

            if (!response.data?.failed) {
                refresh();
                handleClose();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Fallback scenario updated successfully',
                        variant: 'success',
                        close: true
                    })
                );
            } else {
                setFailedOrganizations(response.data.failed);
                // dispatch(
                //     openSnackbar({
                //         open: true,
                //         message: 'lol fol',
                //         variant: 'error',
                //         close: true
                //     })
                // );
            }
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Could not update fallback scenario',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
        setQuery({
            ...query,
            name: value,
            page: 1
        });
    };

    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '480px' } }} open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3">Edit CRM</Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="caption" sx={{ color: '#535862' }}>
                            Condition will be reviewed by finance before publish
                        </Typography>
                    </Grid> */}
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500]
                })}
            >
                <CloseIcon />
            </IconButton>
            <div />
            <DialogContent>
                <Autocomplete
                    multiple
                    value={selectedOrganizations}
                    onChange={(_event: React.SyntheticEvent, newValue: Organization[]) => {
                        setSelectedOrganizations(newValue);
                    }}
                    disableClearable
                    // onInputChange={handleInputChange}
                    options={organizations}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Organization"
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px'
                                }
                            }}
                        />
                    )}
                />
                {/* Show warning message when existing locations are found */}

                {failedOrganizations.length > 0 && (
                    <Grid item xs={12} mt={2}>
                        <Box
                            sx={{
                                backgroundColor: '#fdecea',
                                borderRadius: '8px',
                                padding: '12px',
                                border: '1px solid #f5c6cb',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            <Box>
                                <CustomTypography variant="body2" color="#d32f2f">
                                    These CRMs are not subscribed to the page, and hence are not eligible to be added as a fallback
                                    organization:
                                </CustomTypography>
                                <ul style={{ margin: 0, paddingLeft: '20px', color: '#d32f2f' }}>
                                    {failedOrganizations.map((organization) => (
                                        <li key={organization.id}>
                                            <CustomTypography maxWidth={250} noWrap tooltipText={organization.name} variant="body2">
                                                {organization.name}
                                            </CustomTypography>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </DialogContent>
            <div />
            <DialogActions sx={{ padding: '0px 24px 12px 24px' }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#015C9A', width: { md: '250px', xs: '100%' }, height: '40px', borderRadius: '8px' }}
                    onClick={() => {
                        handleSave();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFallbackScenarioDialog;
