import { Button, Grid } from '@mui/material';
import SearchableTable from 'components/common/SearchableTable';
import { IFilterOptions, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import { useEffect, useState } from 'react';
import { headers } from '../../components/campaigns/headers';
import CampaignsStatistics from 'components/campaigns/CampaignsStatistics';
import axiosServices from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import campaignsFilters from 'components/campaigns/table-filters';
import CampaignsEmpty from 'components/campaigns/CampaignsEmpty';
import CampaignsListItem from 'components/campaigns/CampaignsListItem';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';

const Campaigns = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [assignModalIsOpen, setAssignModalIsOpen] = useState(false);
    // const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});
    const initialSearchQuery: ISearchQueryType = {
        pageSize: 10,
        page: 1,
        statusType: 'active'
    };

    // const getFilterOptions = async () => {
    //     try {
    //         const res = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organizations`);
    //         const organizations = res.data;
    //         const partners = organizations.map((organization: { id: any; name: any }) => ({
    //             value: organization.id,
    //             label: organization.name
    //         }));
    //         setFilterOptions({ partners: partners });
    //     } catch (error) {
    //         dispatch(
    //             openSnackbar({
    //                 open: true,
    //                 message: 'Something went wrong',
    //                 variant: 'error',
    //                 // alert: {
    //                 //     color: 'error'
    //                 // },
    //                 close: true
    //             })
    //         );
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const createCampaignButton = (props: any) => {
        return (
            <>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#015C9A', width: { xs: '100%', md: '201px' }, height: '40px', borderRadius: '8px' }}
                    href={`campaigns/new`}
                    target="_blank"
                >
                    Create new campaign
                </Button>
            </>
        );
    };

    // useEffect(() => {
    //     setIsLoading(true);
    //     // getFilterOptions();
    // }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ResponsivePageTitleComponent title="Campaigns" mobileOnly={true} />
                        {!isLoading && (
                            <SearchableTable
                                url="/v1/organization-campaigns"
                                name="Campaigns"
                                title="Campaigns"
                                headers={headers}
                                tableFilters={campaignsFilters}
                                setData={() => {}}
                                initialSearchQuery={initialSearchQuery}
                                rowsPerPage={initialSearchQuery.pageSize}
                                filterOptions={filterOptions}
                                SecondaryActions={[createCampaignButton]}
                                TableStickySectionComponent={CampaignsStatistics}
                                TableEmptySectionComponent={CampaignsEmpty}
                                toggleFilter={{
                                    defaultValue: 'active',
                                    filterName: 'statusType',
                                    optionValues: ['active', 'approved', 'finished']
                                }}
                                ListViewComponent={CampaignsListItem}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Campaigns;
