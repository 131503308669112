import { createSlice } from '@reduxjs/toolkit';
import { QueryAdsInitialStateInterface } from './interfaces';
import { queryAds } from './actions';

// initial state
const initialState: QueryAdsInitialStateInterface = {
    ads: {
        ids: [],
        uids: [],
        names: [],
        externalIds: [],
        adsetIds: [],
        pageIds: [],
        locationIds: [],
        lastHourSpend: 0,
        totalSpend: 0,
        spentToday: 0,
        lastSpendResetAt: new Date(),
        averageCostPerLead: 0,
        leadCreationStartDate: new Date()
    }
};

// ==============================|| SLICE - cils ||============================== //

const ads = createSlice({
    name: 'ads',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryAds.pending, (state) => {})
            .addCase(queryAds.fulfilled, (state, action) => {
                state.ads = action.payload;
            })
            .addCase(queryAds.rejected, (state, action) => {})
            .addDefaultCase((state, action) => {});
    }
});

export default ads.reducer;
