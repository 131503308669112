// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FallbackScenariosIcon } from 'assets/images/sidebar-icons/fallbackScenariosIcon';
import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const fallbackScenariosMenuItem: NavItemType = {
    id: 'fallback-scenarios',
    title: <FormattedMessage id="fallback-scenarios" />,
    type: 'item',
    url: '/settings/fallback-scenarios',
    // icon: FallbackScenariosIcon,
    breadcrumbs: false
    // external: false,
    // target: false
};

export default fallbackScenariosMenuItem;
