import { FilterTypeEnum, ITableFilter } from 'components/common/SearchableTable/interfaces';

const campaignsFilters: ITableFilter[] = [
    { filterType: FilterTypeEnum.TEXT, filterName: 'campaignName', title: 'Search by campaign name' },
    {
        filterType: FilterTypeEnum.LIST,
        filterName: 'locationIds',
        title: 'Location',
        paginated: true,
        url: '/v1/locations/search',
        initialSearchQuery: {
            pageSize: 50,
            page: 1,
            name: '',
            addLocationTypeToName: true
        }
    },
    {
        filterType: FilterTypeEnum.LIST,
        filterName: 'organizationIds',
        // optionsKey: 'partners',
        title: 'Partner',
        paginated: true,
        url: '/v1/organizations/query',
        initialSearchQuery: {
            pageSize: 50,
            page: 1,
            name: ''
        }
    },
    { filterType: FilterTypeEnum.DATE, filterName: 'dateRange', title: 'Date' }
];

export default campaignsFilters;
