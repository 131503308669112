/* eslint-disable @typescript-eslint/dot-notation */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Checkbox, Chip, Collapse, Grid, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useConfig from 'hooks/useConfig';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { format, isAfter } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import SubCard from 'ui-component/cards/SubCard';
import { ICustomRowProps, IHeaderData } from './interfaces';
import { IconExternalLink } from '@tabler/icons';
import StatusChip from './StatusChip';
import CustomTypography from 'ui-component/CustomTypography';

const CustomTableRow: React.FC<ICustomRowProps> = ({
    rowData,
    headers,
    collapsedHeaders,
    collapsedKey,
    clickableRows,
    selectableRows,
    clickableRowKeys,
    handleClick,
    selectedRows,
    handleClickActionMenu,
    SideMenu,
    ActionColumn,
    type,
    statusType,
    setRows,
    refresh,
    selectedRowsData
}) => {
    const intl = useIntl();
    const { navType } = useConfig();
    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);

    const accessNestedData = (data: any, path: string | string[]) => {
        if (typeof path === 'string') {
            path = path.split('.'); // Convert string path to array for uniform handling
        }
        return path.reduce((acc, key) => (acc?.[key] ? acc[key] : null), data);
    };

    // Utility function to access nested properties using dot notation
    const prepareCellData = (header: IHeaderData, row: any): string => {
        let cellData: any = row[header['key']];
        let originalValue = !!cellData;

        if (cellData && header.subKey && typeof header.subKey === 'string') {
            header.subKeys = [header.subKey];
        }

        if (cellData && header.subKeys && header.subKeys.length > 0) {
            header.subKeys.forEach((key) => {
                cellData = accessNestedData(cellData, key);
            });
        }

        if (header['type'] === 'date' && cellData) {
            if (header['key'] === 'createdAt' && cellData) {
                cellData = format(new Date(cellData), 'dd-MM-yyyy');
            } else if (header['key'] === 'year') {
                cellData = intl.formatDate(cellData, {
                    year: 'numeric'
                });
            } else {
                if (header['key'] !== 'updated_at' && header['key'] !== 'created_at' && header['key'] !== 'last_installment_date') {
                    cellData = isAfter(new Date(cellData), new Date()) ? format(new Date(cellData), 'dd MMM yyyy') : 'Ready to move';
                } else {
                    cellData = format(new Date(cellData), 'dd MMM yyyy');
                }
            }
        }

        if (header['valueType'] === 'price' && cellData) {
            cellData = cellData.toLocaleString(undefined, {
                maximumFractionDigits: 2
            });
        }

        if (header['isBoolean']) {
            cellData = cellData ? 'Yes' : 'No';
        }
        if (originalValue && header['prefix']) {
            cellData = header['prefix'] + ' ' + cellData;
        }

        if (originalValue && header['suffix']) {
            cellData = cellData + ' ' + header['suffix'];
        }

        if (!cellData && header['defaultValue']) {
            cellData = header['defaultValue'];
        }

        if (cellData && header['type'] === 'list') {
            cellData = cellData.join(' - ');
        }

        return cellData;
    };

    const getCellColor = (header: IHeaderData, row: any): string => {
        let cellData: any = row[header['key']];

        if (header.color) {
            return header.color;
        }
        if (header.colorSubKeys && header.colorSubKeys.length > 0) {
            header.colorSubKeys.forEach((key) => {
                cellData = accessNestedData(cellData, key);
            });
        } else if (header['type'] === 'object' && cellData && header['colorKey']) {
            cellData = cellData[header['colorKey']];
        }

        return cellData;
    };

    const getCellBgColor = (header: IHeaderData, row: any): string => {
        let cellData: any = row[header['key']];

        let cellValue = prepareCellData(header, rowData);

        if (header.bgColorMap && header.bgColorMap[cellValue]) {
            return header.bgColorMap[cellValue];
        }
        if (header.bgColorSubKeys && header.bgColorSubKeys.length > 0) {
            header.bgColorSubKeys.forEach((key) => {
                cellData = accessNestedData(cellData, key);
            });
        } else if (header['type'] === 'object' && cellData && header['bgColorKey']) {
            cellData = cellData[header['bgColorKey']];
        }

        return cellData;
    };

    const getIterableData = (header: IHeaderData, row: any) => {
        let cellData: any = row[header['key']];

        if (cellData && header.subKeys && header.subKeys.length > 0) {
            header.subKeys.forEach((key) => {
                cellData = accessNestedData(cellData, key);
            });
        }

        if (cellData && header.iterableKeys && header.iterableKeys.length > 0) {
            const results = [];
            for (const item of cellData) {
                let itemData = item;
                header.iterableKeys.forEach((key) => {
                    itemData = accessNestedData(itemData, key);
                });
                results.push(itemData);
            }
            cellData = results;
        }

        if (cellData.length > 0) return cellData?.join(' - ');
        return header?.defaultValue;
    };
    // rowData, clickableRows, navigate, clickableRowKeys, handleClick, selectedRows, handleClickActionMenu
    return (
        <>
            <TableRow
                sx={{ py: 3 }}
                key={rowData['id']}
                tabIndex={2}
                hover={clickableRows}
                role="checkbox"
                onClick={(e) => {
                    if (clickableRows && clickableRowKeys) {
                        if (clickableRowKeys.newPage) {
                            window.open(clickableRowKeys['linkPrefix'] + rowData[clickableRowKeys['linkPostfixKey']]);
                        } else {
                            navigate(clickableRowKeys['linkPrefix'] + rowData[clickableRowKeys['linkPostfixKey']]);
                        }
                    }
                }}
            >
                {selectableRows && (
                    <TableCell
                        key={rowData.id + 'check'}
                        sx={{
                            pl: 3,
                            position: 'sticky',
                            left: 0,
                            zIndex: 9,
                            backgroundColor: navType === 'dark' ? '#212946' : 'white'
                        }}
                    >
                        <Checkbox onClick={(e) => handleClick(e, rowData)} checked={selectedRows[rowData.id] ?? false} />
                    </TableCell>
                )}
                {collapsedHeaders && (
                    <TableCell
                        key={rowData.id + 'check'}
                        sx={{
                            pl: 3,
                            backgroundColor: navType === 'dark' ? '#212946' : 'white'
                        }}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(!open);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
                {headers.map(
                    (header: IHeaderData, i: any) =>
                        !header['hidden'] && (
                            <TableCell align="left" key={rowData['id'] + i}>
                                {header['statusChip'] && prepareCellData(header, rowData) ? (
                                    <StatusChip label={prepareCellData(header, rowData)} />
                                ) : header['clickable'] ? (
                                    <Link to={header['linkPrefix'] + `${rowData['id']}`} target="_blank">
                                        {prepareCellData(header, rowData)}
                                    </Link>
                                ) : header['iterable'] ? (
                                    <Grid container spacing={1}>
                                        {getIterableData(header, rowData) && (
                                            <Grid item xs={12}>
                                                <CustomTypography
                                                    header={header.name}
                                                    tooltipText={getIterableData(header, rowData)}
                                                    maxWidth={200}
                                                    noWrap
                                                >
                                                    {getIterableData(header, rowData)}
                                                </CustomTypography>
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : header['isBoolean'] ? (
                                    <Chip
                                        label={prepareCellData(header, rowData)}
                                        color={prepareCellData(header, rowData) === 'Yes' ? 'primary' : 'error'}
                                        variant="outlined"
                                        size="small"
                                    />
                                ) : header['externalLinkCell'] ? (
                                    <IconButton color="info" href={`${header['linkPrefix']}/${rowData['id']}`} target="_blank">
                                        <IconExternalLink />
                                    </IconButton>
                                ) : header['CustomCellComponent'] ? (
                                    <header.CustomCellComponent data={prepareCellData(header, rowData)} />
                                ) : header['actionColumn'] && header['ActionColumnComponent'] ? (
                                    <header.ActionColumnComponent
                                        key={rowData['id']}
                                        selectedRowsData={selectedRowsData}
                                        id={rowData['id']}
                                        data={rowData}
                                        type={type}
                                        statusType={statusType}
                                        setRows={setRows}
                                        refresh={refresh}
                                    />
                                ) : (
                                    <CustomTypography
                                        header={header.name}
                                        tooltipText={prepareCellData(header, rowData)}
                                        maxWidth={200}
                                        noWrap
                                    >
                                        {prepareCellData(header, rowData)}
                                    </CustomTypography>
                                )}
                            </TableCell>
                        )
                )}
                {SideMenu && (
                    <TableCell
                        key={rowData.id + 'action'}
                        sx={{
                            pl: 3,
                            position: 'sticky',
                            right: 0,
                            zIndex: 9,
                            backgroundColor: navType === 'dark' ? '#212946' : 'white'
                        }}
                    >
                        <IconButton onClick={handleClickActionMenu('left', rowData.id)} component="span" size="large">
                            <MoreVertIcon sx={{ width: '16px', height: '16px' }} />
                        </IconButton>
                    </TableCell>
                )}
                {ActionColumn && (
                    <TableCell align="left" key={rowData['id']} sx={{ whiteSpace: 'nowrap' }}>
                        <ActionColumn id={rowData['id']} />
                    </TableCell>
                )}
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {open && (
                                <Box sx={{ margin: 1 }}>
                                    <TableContainer>
                                        <SubCard content={false}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow key={'collapsed-table'}>
                                                        {collapsedHeaders &&
                                                            collapsedHeaders.map((header: IHeaderData, k: number) => (
                                                                <TableCell key={k}>{header['name']}</TableCell>
                                                            ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {collapsedKey &&
                                                        rowData[collapsedKey].map((row: any, i: number) => (
                                                            <>
                                                                <TableRow key={i}>
                                                                    {collapsedHeaders &&
                                                                        collapsedHeaders.map((header: any, n: number) => (
                                                                            <TableCell align="left" key={n} sx={{ whiteSpace: 'nowrap' }}>
                                                                                {header['clickable'] ? (
                                                                                    <Link
                                                                                        to={header['linkPrefix'] + `${row['id']}`}
                                                                                        target="_blank"
                                                                                    >
                                                                                        {prepareCellData(header, row)}
                                                                                    </Link>
                                                                                ) : header['iterable'] ? (
                                                                                    <Grid container spacing={1}>
                                                                                        {row[header['key']] &&
                                                                                            row[header['key']].map(
                                                                                                (data: any, m: number) => (
                                                                                                    <Grid key={m} item xs={12}>
                                                                                                        {header['isBoolean'] ? (
                                                                                                            <Chip
                                                                                                                label={
                                                                                                                    data[header['subKey']]
                                                                                                                        ? 'Yes'
                                                                                                                        : 'No'
                                                                                                                }
                                                                                                                color={
                                                                                                                    data[header['subKey']]
                                                                                                                        ? 'primary'
                                                                                                                        : 'warning'
                                                                                                                }
                                                                                                                variant="outlined"
                                                                                                                size="small"
                                                                                                            />
                                                                                                        ) : (
                                                                                                            data[header['subKey']]
                                                                                                        )}
                                                                                                    </Grid>
                                                                                                )
                                                                                            )}
                                                                                    </Grid>
                                                                                ) : header['isBoolean'] ? (
                                                                                    <Chip
                                                                                        label={prepareCellData(header, row)}
                                                                                        color={
                                                                                            prepareCellData(header, row) === 'Yes'
                                                                                                ? 'primary'
                                                                                                : 'warning'
                                                                                        }
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                    />
                                                                                ) : (
                                                                                    <CustomTypography
                                                                                        header={header.name}
                                                                                        tooltipText={prepareCellData(header, rowData)}
                                                                                        maxWidth={200}
                                                                                        noWrap
                                                                                    >
                                                                                        {prepareCellData(header, rowData)}
                                                                                    </CustomTypography>
                                                                                )}
                                                                            </TableCell>
                                                                        ))}
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </SubCard>
                                    </TableContainer>
                                </Box>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
export default CustomTableRow;
