import { Button } from '@mui/material';
import Remove from '../../assets/images/icons/Remove.svg';
import { useState } from 'react';
import SimpleConfirmModal from 'components/common/SimpleConfirmModal';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
interface PreApprovedLocationsRowActionsProps {
    id: number;
    icon?: string;
    refresh: () => void;
}

const PreApprovedLocationsRowActions = ({ id, icon = Remove, refresh }: PreApprovedLocationsRowActionsProps) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleDelete = async () => {
        try {
            await axiosServices.delete(`${process.env.REACT_APP_BACKEND}/v1/pre-approved-locations/${id}`);
            refresh();
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Location removed successfully',
                    variant: 'success',
                    close: true
                })
            );
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    return (
        <>
            <Button
                variant="text"
                color="secondary"
                startIcon={<img src={icon} alt={'RemoveIcon'} />}
                onClick={() => {
                    setOpenDeleteDialog(true);
                }}
            ></Button>
            <SimpleConfirmModal
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={handleDelete}
                title="Are you sure you want to remove this location?"
                confirmText="Yes Remove"
                textAlign="center"
            />
        </>
    );
};

export default PreApprovedLocationsRowActions;
