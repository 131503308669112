import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Autocomplete,
    TextField,
    Box,
    IconButton
} from '@mui/material';
import { openSnackbar } from '../../store/slices/snackbar';
import { dispatch } from 'store';
import axiosServices from 'utils/axios';
import CloseIcon from '@mui/icons-material/Close';

export interface Props {
    isOpen: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: () => void;
}

type Page = { id: number; name: string };
type Platform = { id: number; name: string };
type Location = { id: number; name: string; locationType: string };
type Option = { value: number; label: string };

const AddPreApprovedDialog: React.FC<Props> = ({ isOpen, setDialogOpen, refresh }) => {
    const [availableOptions, setAvailableOptions] = useState<{ pages: Option[]; platforms: Option[] }>({ pages: [], platforms: [] });
    const [availableLocations, setAvailableLocations] = useState<Option[]>([]);
    const [selected, setSelected] = useState<{
        page: Option | null;
        platform: Option | null;
        locations: Option[];
    }>({
        page: null,
        platform: null,
        locations: []
    });
    const [existingLocations, setExistingLocations] = useState<string[]>([]);

    const clearSelection = () => {
        setSelected({
            page: null,
            platform: null,
            locations: []
        });
    };

    const handleClose = () => {
        clearSelection();
        setExistingLocations([]);
        setDialogOpen(false);
    };

    const fetchLocations = async () => {
        try {
            const locationsResponse = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/locations/available-to-pre-approve`, {
                params: {
                    platformIds: [selected.platform?.value],
                    pageIds: [selected.page?.value]
                }
            });
            setAvailableLocations(
                locationsResponse.data.map((location: Location) => ({
                    value: location.id,
                    label: `${location.name} (${location.locationType})`
                }))
            );
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error fetching location options',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        setSelected((prev) => ({ ...prev, locations: [] }));
        setExistingLocations([]);
        if (!selected.platform || !selected.page) return;
        fetchLocations();
    }, [selected.platform, selected.page]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const [platformsResponse, pagesResponse] = await Promise.all([
                    axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/platforms`),
                    axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/pages`)
                ]);
                const platforms = platformsResponse.data.map((platform: Platform) => ({
                    value: platform.id,
                    label: platform.name
                }));

                const pages = pagesResponse.data.map((page: Page) => ({
                    value: page.id,
                    label: page.name
                }));

                setAvailableOptions({ platforms, pages });
            } catch (error) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error fetching filter options',
                        variant: 'error',
                        close: true
                    })
                );
            }
        };

        fetchOptions();
    }, []);

    const handleSave = async () => {
        if (!selected.page || !selected.platform || !selected.locations.length) return;

        try {
            const response = await axiosServices.post(`${process.env.REACT_APP_BACKEND}/v1/pre-approved-locations`, {
                pageId: selected.page.value,
                platformId: selected.platform.value,
                locationIds: selected.locations.map((location) => location.value)
            });

            if (response.data.existing?.length) {
                // Notify user that some records already exist
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Some of the selected location(s) already exist.`,
                        variant: 'warning',
                        close: true
                    })
                );

                const existingLocationIds = response.data.existing.map((location: any) => location.locationId);
                // Find names of existing locations
                const existingNames = availableLocations
                    .filter((location) => existingLocationIds.includes(location.value))
                    .map((location) => location.label);

                setExistingLocations(existingNames);
            } else if (response.data.created?.length) {
                // Notify user that new records were created
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `${response.data.created.length} location(s) added successfully.`,
                        variant: 'success',
                        close: true
                    })
                );
                refresh();
                clearSelection();
                setExistingLocations([]);
                handleClose();
            }
        } catch (error) {
            console.error('Error saving location:', error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong. Please try again.',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '732px', maxHeight: { xs: '372', md: '600px' } } }}
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h3">Add New Location</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box></Box>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={availableOptions.platforms}
                            getOptionLabel={(option) => option.label}
                            value={selected.platform}
                            onChange={(_, newValue) => setSelected((prev) => ({ ...prev, platform: newValue }))}
                            renderInput={(params) => <TextField {...params} label="Select Platform" />}
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={availableOptions.pages}
                            getOptionLabel={(option) => option.label}
                            value={selected.page}
                            onChange={(_, newValue) => setSelected((prev) => ({ ...prev, page: newValue }))}
                            renderInput={(params) => <TextField {...params} label="Select Page" />}
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={availableLocations}
                            getOptionLabel={(option) => option.label}
                            value={selected.locations}
                            onChange={(_, newValue) => setSelected((prev) => ({ ...prev, locations: newValue }))}
                            renderInput={(params) => <TextField {...params} label="Select Location" />}
                            sx={{ width: '100%' }}
                            disabled={!selected.platform || !selected.page}
                        />
                    </Grid>
                </Grid>
                {/* Show warning message when existing locations are found */}

                {existingLocations.length > 0 && (
                    <Grid item xs={12} mt={2}>
                        <Box
                            sx={{
                                backgroundColor: '#fdecea',
                                borderRadius: '8px',
                                padding: '12px',
                                border: '1px solid #f5c6cb',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            <Box>
                                <Typography variant="body2" color="#d32f2f" fontWeight="bold">
                                    Some locations already exist:
                                </Typography>
                                <ul style={{ margin: 0, paddingLeft: '20px', color: '#d32f2f' }}>
                                    {existingLocations.map((name, index) => (
                                        <li key={index}>
                                            <Typography variant="body2">{name}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!selected.page || !selected.platform || !selected.locations.length}
                    variant="contained"
                    sx={{ backgroundColor: 'info', width: '166px', height: '40px', borderRadius: '8px' }}
                    onClick={handleSave}
                >
                    Add Location
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPreApprovedDialog;
