import { CMS_API, formattedQuery } from 'store/apis';
import { QueryLocationsInterface } from './interfaces';

const LOCATIONS_APIS = {
    query: (query: QueryLocationsInterface) => {
        const formatQuery: string[] = [CMS_API, '/v1/locations', '?'];

        return formattedQuery(formatQuery, query);
    }
};

export default LOCATIONS_APIS;
