// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { HomeIcon } from 'assets/images/sidebar-icons/homeIcon';
import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const homeMenuItem: NavItemType = {
    id: 'home',
    title: <FormattedMessage id="home" />,
    type: 'item',
    url: '/home',
    icon: HomeIcon,
    external: false,
    target: false
};

export default homeMenuItem;
