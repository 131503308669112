import React from 'react';
import { Grid, Pagination, PaginationItem, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { COLORS } from 'constant';

const PreviousComponent = () => (
    <Typography
        variant="body2"
        component="span"
        sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderRadius: '4px',
            borderColor: 'divider',
            px: 1,
            py: 0.5,
            '&:hover': {
                backgroundColor: '#e5e5e5'
            }
        }}
    >
        <ArrowBackIcon sx={{ fontSize: '1rem', mr: 1 }} />
        <Typography sx={{ display: { xs: 'none', md: 'block' } }}>Previous</Typography>
    </Typography>
);

const NextComponent = () => (
    <Typography
        variant="body2"
        component="span"
        sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderRadius: '4px',
            borderColor: 'divider',
            px: 1,
            py: 0.5,
            '&:hover': {
                backgroundColor: '#e5e5e5'
            }
        }}
    >
        <Typography sx={{ display: { xs: 'none', md: 'block' } }}>Next</Typography>
        <ArrowForwardIcon sx={{ fontSize: '1rem', ml: 1 }} />
    </Typography>
);

interface CustomPaginationProps {
    count: number;
    rowsPerPage: number;
    page: number;
    handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ count, rowsPerPage, page, handleChangePage }) => {
    let numberOfPages = Math.ceil(count / rowsPerPage);
    return (
        <Pagination
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                my: 2,
                width: '100%',
                '& .MuiPagination-ul': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& > li:not(:nth-of-type(4))': {
                        flex: '0 0 auto'
                    },
                    [`& > li:nth-of-type(${
                        count != 0 ? (page === 1 ? 2 : page === numberOfPages ? (numberOfPages === 2 ? 3 : 4) : 3) : 3
                    })`]: {
                        flex: '1 1 auto',
                        display: 'flex',
                        justifyContent: 'center'
                    },
                    '& .MuiPaginationItem-ellipsis': {
                        display: 'none'
                    },
                    '& .MuiPaginationItem-root': {
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }
                }
            }}
            count={numberOfPages}
            page={page}
            siblingCount={0}
            boundaryCount={0}
            onChange={handleChangePage}
            renderItem={(item) => {
                if (item.type === 'page' && item.page !== page) {
                    return null;
                } else if (item.type === 'start-ellipsis' || item.type === 'end-ellipsis') {
                    return null;
                } else if (item.type === 'page' && item.page === page) {
                    return (
                        <span
                            style={{
                                backgroundColor: COLORS.MAIN_BACKGROUND,
                                padding: '4px 8px',
                                borderRadius: '4px',
                                userSelect: 'none'
                            }}
                        >
                            Page {page} of {Math.ceil(count / rowsPerPage)}
                        </span>
                    );
                }

                return (
                    <PaginationItem
                        sx={{
                            '& .MuiPaginationItem-root': {
                                margin: '0 10px',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            '& .MuiPaginationItem-rounded': {
                                borderRadius: '100px'
                            }
                        }}
                        slots={{ previous: PreviousComponent, next: NextComponent }}
                        {...item}
                    />
                );
            }}
        />
    );
};

export default CustomPagination;
