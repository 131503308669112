import { Card, ImageList, ImageListItem, Link } from '@mui/material';
import { Stack } from '@mui/system';
import StatusChip from 'components/common/SearchableTable/StatusChip';
import { Campaign } from './interfaces/campaign.interface';
import StackeLayersIcon from '../../assets/images/icons/stacked-layers.svg';
import LocationIcon from '../../assets/images/icons/location2.svg';
import CustomTypography from 'ui-component/CustomTypography';
import CampaignRowActions from './CampaignRowActions';

const CampaignsListItem: React.FC<{ rowData: Campaign; refresh: () => void; isTableView: boolean }> = ({
    rowData,
    refresh,
    isTableView
}) => {
    return (
        <>
            <Card
                sx={{
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    borderBottom: '1px solid  #EAECF0',
                    boxShadow: '0px 1px #EAECF0',
                    borderRadius: '0px'
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack>
                        <Link href={`campaigns/${rowData.id}`} sx={{ alignSelf: 'end' }} underline="none" color="inherit">
                            <CustomTypography
                                maxWidth={150}
                                noWrap
                                header="Name"
                                tooltipText={rowData.name}
                                sx={{ color: '#015C9A', fontWeight: '500', fontSize: '16px' }}
                            >
                                {rowData.name}
                            </CustomTypography>
                        </Link>
                        <CustomTypography
                            maxWidth={150}
                            noWrap
                            header="ID"
                            variant="caption"
                            tooltipText={`ID: ${rowData.id}`}
                            sx={{ fontSize: '14px' }}
                        >
                            ID: {rowData.id}
                        </CustomTypography>
                    </Stack>

                    <StatusChip label={rowData.organizationCampaignStatus.name}></StatusChip>
                </Stack>
                <Stack direction="column" spacing={1} sx={{ backgroundColor: '#F7F7F7', padding: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1'
                            }}
                        >
                            <ImageListItem>
                                <img src={LocationIcon} alt="locationIcon" />
                            </ImageListItem>
                        </ImageList>
                        <CustomTypography sx={{ fontSize: '14px', fontWeight: 400 }}>location: </CustomTypography>
                        {rowData.organizationCampaignEntities.length != 0 && rowData.organizationCampaignEntities[0]?.location?.name && (
                            <CustomTypography
                                maxWidth={150}
                                noWrap
                                header="Location"
                                tooltipText={rowData.organizationCampaignEntities[0]?.location?.name}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}
                            >
                                {rowData.organizationCampaignEntities[0]?.location?.name}
                            </CustomTypography>
                        )}
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1'
                            }}
                        >
                            <ImageListItem>
                                <img src={StackeLayersIcon} alt="locationIcon" />
                            </ImageListItem>
                        </ImageList>
                        <CustomTypography sx={{ fontSize: '14px', fontWeight: 400 }}>partner:</CustomTypography>
                        {rowData.organization.name && (
                            <CustomTypography
                                noWrap
                                header="Partner"
                                maxWidth={150}
                                tooltipText={rowData.organization.name}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}
                            >
                                {rowData.organization.name}
                            </CustomTypography>
                        )}
                    </Stack>
                </Stack>
                <Stack direction="row" height="88px" justifyContent="space-between">
                    <Stack direction="column" paddingX={1}>
                        <Stack direction="row" spacing={1}>
                            <CustomTypography
                                header="Avg Cost / lead"
                                tooltipText={rowData.costPerLead}
                                maxWidth={70}
                                noWrap
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            >
                                {rowData.costPerLead}
                            </CustomTypography>
                            <CustomTypography sx={{ fontSize: '14px', fontWeight: '600' }}>EGP</CustomTypography>
                        </Stack>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500' }}>Avg Cost / lead</CustomTypography>
                    </Stack>
                    <Stack
                        direction="column"
                        paddingX={1}
                        sx={{
                            borderLeft: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: ' linear-gradient(180deg, #FFFFFF 0%, #BEBEBE 48.71%, #FCFCFC 100%)'
                        }}
                    >
                        <CustomTypography
                            header="Number of leads"
                            tooltipText={rowData.leadCount}
                            noWrap
                            maxWidth={70}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '600'
                            }}
                        >
                            {rowData.leadCount}
                        </CustomTypography>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500' }}>Number of leads</CustomTypography>
                    </Stack>
                    <Stack
                        direction="column"
                        paddingX={1}
                        sx={{
                            borderLeft: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: ' linear-gradient(180deg, #FFFFFF 0%, #BEBEBE 48.71%, #FCFCFC 100%)'
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <CustomTypography
                                header="Total Spent"
                                tooltipText={rowData.budgetSpent.spent}
                                noWrap
                                maxWidth={70}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            >
                                {rowData.budgetSpent.spent}
                            </CustomTypography>
                            <CustomTypography sx={{ fontSize: '14px', fontWeight: '600' }}>EGP</CustomTypography>
                        </Stack>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '400' }}>out of</CustomTypography>
                        <CustomTypography
                            header="Budget"
                            tooltipText={rowData.budgetSpent.budget}
                            noWrap
                            maxWidth={70}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400'
                            }}
                        >
                            {rowData.budgetSpent.budget}
                        </CustomTypography>
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500' }}>Spent / Total budget</CustomTypography>
                    </Stack>
                </Stack>
                <CampaignRowActions
                    id={rowData.id}
                    data={rowData}
                    type={rowData.organizationCampaignStatus.type}
                    refresh={refresh}
                    isTableView={isTableView}
                />
            </Card>
        </>
    );
};

export default CampaignsListItem;
