import { Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import EditIcon from '../../assets/images/icons/edit.svg';
import EditFallbackScenarioDialog from './EditFallbackScenarioDialog';

interface IProps {
    id: number;
    data: any;
    refresh: () => void;
}

const FallbackScenarioRowActions: React.FC<IProps> = ({ id, data, refresh }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    return (
        <Grid>
            {
                <>
                    <IconButton
                        size="medium"
                        onClick={() => {
                            setDialogIsOpen(true);
                        }}
                    >
                        <img src={EditIcon} alt={'Icon'} />
                    </IconButton>
                </>
            }
            {dialogIsOpen && (
                <EditFallbackScenarioDialog
                    data={data}
                    id={id}
                    isOpen={dialogIsOpen}
                    handleClose={() => {
                        setDialogIsOpen(false);
                    }}
                    refresh={refresh}
                />
            )}
        </Grid>
    );
};

export default FallbackScenarioRowActions;
