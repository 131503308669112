import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { COLORS } from 'constant';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { SearchLocationsRespose } from './interfaces';

interface Location {
    id: number;
    name: string;
}

interface LocationSelectorProps {
    locationType: string;
    label: string;
}

const LocationSelector: React.FC<LocationSelectorProps> = ({ locationType, label }) => {
    const [options, setOptions] = useState<Location[]>([]);
    const [selectedOption, setSelectedOption] = useState<Location | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    const fetchOptions = async (nameFilter: string = '') => {
        try {
            setLoading(true);

            const params = new URLSearchParams({
                locationType
            });
            params.append('pageSize', '50');
            if (nameFilter) {
                params.append('name', nameFilter);
            }

            const response = await axiosServices.get<SearchLocationsRespose>(
                `${process.env.REACT_APP_BACKEND}/v1/locations/search-locations?${params.toString()}`
            );
            setOptions(response.data.results);
        } catch (error) {
            console.error(`Error fetching locations (${locationType}):`, error);

            dispatch(
                openSnackbar({
                    open: true,
                    message: `Error fetching locations`,
                    variant: 'error',
                    // alert: {
                    //     color: 'error'
                    // },
                    close: true
                })
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, [locationType]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nameFilter = event.target.value;
        fetchOptions(nameFilter);
    };

    const handleCopy = () => {
        if (selectedOption) {
            const copyText = `${selectedOption.name} (${selectedOption.id})`;
            navigator.clipboard.writeText(copyText);

            dispatch(
                openSnackbar({
                    open: true,
                    // header: 'Successful!',
                    message: `Copied to clipboard ${copyText}`,
                    variant: 'success',
                    // alert: { variant: 'filled' },
                    close: true
                })
            );
        }
    };

    const createPlaceHolderFromLabel = (sentence: string) => sentence.replace(/^(\w+\s)(.*)$/, '$2s');

    return (
        <div>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" sx={{ color: COLORS.LABEL_COLOR, mb: 2 }}>
                    {label}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Autocomplete
                    disablePortal
                    options={options}
                    getOptionLabel={(option) => option.name}
                    sx={{
                        width: 500,
                        '& .MuiInputBase-root': {
                            height: 36,
                            padding: '0 10px'
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={createPlaceHolderFromLabel(label)} onChange={handleSearchChange} />
                    )}
                    value={selectedOption}
                    onChange={(event, newValue) => setSelectedOption(newValue)}
                    loading={loading}
                />
                <Button
                    onClick={handleCopy}
                    style={{
                        height: 36,
                        width: 60,
                        minWidth: 'auto',
                        padding: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 8,
                        border: '1px solid #ccc',
                        backgroundColor: 'white'
                    }}
                    disabled={!selectedOption}
                >
                    <ContentCopyIcon style={{ fontSize: 20 }} />
                </Button>
            </Stack>
        </div>
    );
};

export default LocationSelector;
