import React from 'react';
import { BaseLeadBalancerForm } from '../lead-balancer/BaseLeadBalancerForm';
import { FacebookFormData, FormOptions } from '../lead-balancer/types';

interface FacebookLeadBalancerFormProps {
    formData: FacebookFormData;
    formOptions: FormOptions;
    handleChange: (key: string, value: any) => void;
    inputRefs: React.RefObject<HTMLInputElement>[];
}

export const FacebookLeadBalancerForm: React.FC<FacebookLeadBalancerFormProps> = ({ formData, formOptions, handleChange, inputRefs }) => {
    return (
        <BaseLeadBalancerForm
            formData={formData}
            formOptions={formOptions}
            handleChange={handleChange}
            fields={['partner', 'page', 'weightType', 'ruleType', 'ruleValue', 'weight']}
            inputRefs={inputRefs}
        />
    );
};
