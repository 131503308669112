import { FacebookFormData } from 'components/lead-balancer/types';
import { useBaseLeadBalancer } from 'components/lead-balancer/hooks/useBaseLeadBalancer';

interface FacebookLeadBalancerProps {
    id?: number | null | undefined;
    data: FacebookFormData;
    refresh: () => Promise<void>;
}

export const useFacebookLeadBalancer = ({ id, data, refresh }: FacebookLeadBalancerProps) => {
    const base = useBaseLeadBalancer<FacebookFormData>({
        id,
        data,
        refresh,
        endpoint: '/v1/lead-balancer/facebook'
    });

    const handleSave = () => {
        const transformData = (formData: any) => ({
            organizationId: formData.organizationId ?? formData.organization.id,
            pageId: formData.pageId ?? formData.page.id,
            weightType: formData.weightType,
            ruleType: formData.ruleType,
            ruleValue: formData.ruleValue,
            locationId: formData.locationId,
            weight: formData.weight
        });

        return base.handleSave(transformData);
    };

    return {
        ...base,
        handleSave
    };
};
