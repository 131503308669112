export interface Ad {
    id: number;
    name: string;
    locationId?: number;
    locationName?: string;
}
export interface Adset {
    id?: number;
    name?: string;
    locationId?: number;
    locationName?: string;
}
export interface LocationInterface {
    id: number;
    name: string;
}

export interface AdData {
    ads: Ad[];
    locations: Location[];
}

export interface AdsetData {
    adsets: Adset[];
}

export enum LocationType {
    compound = 'Compound',
    area = 'Area',
    developer = 'Developer',
    custom = 'Custom'
}
export interface AdSummary {
    id: number;
    name: string;
    externalId: string;
    leadsCount: number;
    adsetId: number;
    pageId: number;
    pageName: string;
    locationId: number;
    locationName: string;
    averageCostPerLead: number;
    costPerLeadTotalSpend: number;
    totalSpend: number;
    spentToday: number;
    lastHourSpend: number;
    platformId: number;
    lastSpendResetAt: string;
}
