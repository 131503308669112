import React from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import CampaignIcon from '../../assets/images/icons/campaigns.svg';
import SearchIcon from '../../assets/images/icons/search.svg';

const CampaignsEmpty: React.FC<{ isEmpty: boolean }> = ({ isEmpty = true }) => {
    const data = {
        title: isEmpty ? 'No campaigns' : 'No campaigns found',
        description: isEmpty
            ? 'You didn’t create any campaigns till now. Try our easy and trusted way to generate qualified leads'
            : 'Your search did not match any campaigns. Please try again or create a new one.'
    };
    return (
        <>
            <Stack direction="column" alignItems="center" spacing={1} p={6}>
                {isEmpty ? (
                    <img src={CampaignIcon} style={{ width: 30, height: 30, marginBottom: 20 }} alt={'Icon'} />
                ) : (
                    <img src={SearchIcon} style={{ width: 30, height: 30, marginBottom: 20 }} alt={'Icon'} />
                )}
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    {data.title}
                </Typography>
                {/* <Typography sx={{ width: 352, textAlign: 'center' }} variant="body1">
                    {data.description}
                </Typography> */}
            </Stack>
        </>
    );
};

export default CampaignsEmpty;
