// project import
import compoundsMenuItem from './compounds';

// types
import { NavItemType } from 'types';
import locationsMenuItem from './locations';
import campaignRequestsMenuItem from './campaign-requests';
import campaignsMenuItem from './campaigns';
import partnersWalletsMenuItem from './partners-wallets';
import settingsMenuItem from './settings';
import partnersMenuItem from './partners';
import leadBalancerMenuItem from './lead-balancer';
import websiteLeadBalancerMenuItem from './website-lead-balancer';
import homeMenuItem from './home';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [
        homeMenuItem,
        campaignsMenuItem,
        campaignRequestsMenuItem,
        compoundsMenuItem,
        locationsMenuItem,
        partnersMenuItem,
        partnersWalletsMenuItem,
        leadBalancerMenuItem,
        websiteLeadBalancerMenuItem,
        settingsMenuItem
    ]
};

export default menuItems;
