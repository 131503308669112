import { IHeaderData } from 'components/common/SearchableTable/interfaces';
export const homeHeaders: IHeaderData[] = [
    {
        name: 'Name',
        key: 'name',
        type: 'text'
    },
    {
        name: 'Avg. Cost / lead',
        key: 'costPerLead',
        valueType: 'price',
        defaultValue: '-',
        suffix: 'EGP'
    }
];
