import { FormattedMessage } from 'react-intl';
import { CampaignsIcon } from 'assets/images/sidebar-icons/campaignsIcon';
import { NavItemType } from 'types';
import { Icon } from '@mui/material';
import { Icon24Hours, Icon3dCubeSphere } from '@tabler/icons';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const campaignsMenuItem: NavItemType = {
    id: 'campaigns',
    title: <FormattedMessage id="campaigns" />,
    type: 'item',
    url: '/campaigns',
    icon: CampaignsIcon,
    external: false,
    target: false
};

export default campaignsMenuItem;
