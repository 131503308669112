import { createSlice } from '@reduxjs/toolkit';
import { QueryLocationsInitialStateInterface } from './interfaces';
import { queryLocations } from './actions';

// initial state
const initialState: QueryLocationsInitialStateInterface = {
    locations: {
        locationType: ''
    }
};

// ==============================|| SLICE - cils ||============================== //

const locations = createSlice({
    name: 'locations',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryLocations.pending, (state) => {})
            .addCase(queryLocations.fulfilled, (state, action) => {
                state.locations = action.payload;
            })
            .addCase(queryLocations.rejected, (state, action) => {})
            .addDefaultCase((state, action) => {});
    }
});

export default locations.reducer;
