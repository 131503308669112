import { Grid, IconButton, Link, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { ICampaignStatistics } from './interfaces/campaign-statistics.interface';
import axiosServices from 'utils/axios';
import { ICampaignStatisticsFilter } from './interfaces/campaign-statistics-filter.interface';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import diagonalArrow from '../../assets/images/icons/arrow-diagonal.svg';
import { CampaignStatusEnum } from 'components/campaign-summary/campaign-summary.interface';
const CampaignsStatistics: React.FC<ICampaignStatisticsFilter> = (filters) => {
    const [campaignStatistics, setCampaignStatistics] = useState<ICampaignStatistics>({} as ICampaignStatistics);

    const getCampaignStatistics = async () => {
        const response = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organization-campaigns/analytics`, {
            params: {
                organizationIds: filters.organizationIds,
                startDate: filters.startDate,
                endDate: filters.endDate,
                campaignName: filters.campaignName ? filters.campaignName : undefined,
                locationIds: filters.locationIds,
                campaignStatus: CampaignStatusEnum.pending
            }
        });
        setCampaignStatistics(response.data);
    };

    const formatNumber = (num: number) => {
        return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
    };
    useEffect(() => {
        getCampaignStatistics();
    }, [filters]);

    return (
        <Grid container sx={{ px: { xs: 2, md: 0 }, height: { lg: 218 } }} spacing={2}>
            <Grid item xs={6} lg sm={6}>
                <MainCard sx={{ color: 'black', height: { xs: 146, md: '100%' }, border: 1, borderColor: '#EAECF0', borderRadius: '12px' }}>
                    <Stack spacing={2}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', height: 52 }} color="inherit">
                            {'Campaign requests pending'}
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 22, md: 32 }, fontWeight: 600, lineHeight: '44px' }}>
                            {campaignStatistics.campaignsCount ? formatNumber(campaignStatistics.campaignsCount) : 0}
                        </Typography>
                    </Stack>
                </MainCard>
            </Grid>
            <Grid item xs={6} lg sm={6}>
                <MainCard sx={{ color: 'black', height: { xs: 146, md: '100%' }, border: 1, borderColor: '#EAECF0', borderRadius: '12px' }}>
                    <Stack spacing={2}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', height: { md: 48 } }} color="inherit">
                            {'Leads generated'}
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 22, md: 32 }, fontWeight: 600, lineHeight: '44px' }}>
                            {campaignStatistics.leadsGeneratedCount ? formatNumber(campaignStatistics.leadsGeneratedCount) : 0}
                        </Typography>
                    </Stack>
                </MainCard>
            </Grid>
            <Grid item xs={6} lg sm={6}>
                <MainCard sx={{ color: 'black', height: { xs: 146, md: '100%' }, border: 1, borderColor: '#EAECF0', borderRadius: '12px' }}>
                    <Stack spacing={2}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', height: { md: 48 } }} color="inherit">
                            {'Amount spent'}
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 22, md: 32 }, fontWeight: 600, lineHeight: '44px' }}>
                            {campaignStatistics.amountSpent ? formatNumber(campaignStatistics.amountSpent) : 0}
                        </Typography>
                    </Stack>
                </MainCard>
            </Grid>
            <Grid item xs={6} lg sm={6}>
                <MainCard sx={{ color: 'black', height: { xs: 146, md: '100%' }, border: 1, borderColor: '#EAECF0', borderRadius: '12px' }}>
                    <Stack spacing={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', height: { md: 48 } }} color="inherit">
                                {'Avg cost / lead'}
                            </Typography>
                            <IconButton aria-label="delete" size="small">
                                <MoreVertIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography sx={{ display: { xs: 'none', md: 'block' }, fontSize: 12, fontWeight: 600, lineHeight: '44px' }}>
                                {'EGP'}
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 22, md: 32 }, fontWeight: 600, lineHeight: '44px' }}>
                                {campaignStatistics.averageCostPerLead ? formatNumber(campaignStatistics.averageCostPerLead) : 0}
                            </Typography>
                        </Box>
                    </Stack>
                </MainCard>
            </Grid>
            <Grid
                item
                xs={6}
                lg
                sm={6}
                sx={{
                    height: { xs: 146, md: '100%' }
                }}
            >
                <MainCard
                    sx={{
                        bgcolor: '#003A6C',
                        color: 'white',
                        border: 1,
                        height: '100%',
                        borderColor: '#EAECF0',
                        borderRadius: '12px'
                    }}
                >
                    <Stack sx={{ gap: 2 }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', height: { md: 48 } }} color="inherit">
                            {'My balance'}
                        </Typography>
                        <Stack sx={{ gap: { xs: 0, md: 1 } }}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 600, lineHeight: '44px' }}>{'EGP'}</Typography>
                                <Typography sx={{ fontSize: { xs: 12, md: 24 }, fontWeight: 600, lineHeight: '44px' }}>{'--'}</Typography>
                            </Box>
                            <Link href="/partners-wallets" sx={{ alignSelf: 'end' }} underline="none" color="inherit">
                                <Typography
                                    sx={{ fontSize: 14, fontWeight: 600, lineHeight: '20px', display: 'flex', gap: 1 }}
                                    color="inherit"
                                >
                                    {'Wallet details'}
                                    <img src={diagonalArrow} alt="icon" />
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default CampaignsStatistics;
