import { IHeaderData } from 'components/common/SearchableTable/interfaces';
import CampaignRequestRowActions from './FacebookLeadBalancerRowActions';

export const headers: IHeaderData[] = [
    {
        name: 'ID',
        key: 'id',
        hidden: true
    },
    {
        name: 'Partner name',
        key: 'organization',
        subKeys: ['name']
        // clickable: true,
        // linkPrefix: ''
    },
    {
        name: 'Page',
        key: 'page',
        subKeys: ['name']
    },
    {
        name: 'Weight type',
        key: 'weightType'
    },
    {
        name: 'Rule type',
        key: 'ruleType'
    },
    {
        name: 'Rule value',
        key: 'ruleValue'
    },
    {
        name: 'Location',
        key: 'location',
        subKeys: ['name']
    },
    {
        name: 'Weight',
        key: 'weight'
    },
    {
        name: 'Edit/Delete',
        key: '',
        actionColumn: true,
        ActionColumnComponent: CampaignRequestRowActions
    }
];
