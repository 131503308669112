import { IHeaderData } from 'components/common/SearchableTable/interfaces';
import PreApprovedLocationsRowActions from './PreApprovedLocationsRowActions';

export const headers: IHeaderData[] = [
    {
        name: 'Location Name',
        key: 'locationName',
        type: 'text'
    },
    {
        name: 'Platform',
        key: 'platformName'
        // CustomCellComponent: PlatformNameCell
    },
    {
        name: 'Page',
        key: 'pageName',
        type: 'text'
    },
    {
        name: 'Created At',
        key: 'createdAt',
        type: 'date'
    },
    {
        name: 'Action',
        key: 'action',
        actionColumn: true,
        ActionColumnComponent: PreApprovedLocationsRowActions
    }
];
