/* eslint-disable @typescript-eslint/dot-notation */
import { useState, useCallback } from 'react';
import { Checkbox, TableSortLabel, TextField } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ICustomHeaderProps } from './interfaces';
import { Box } from '@mui/material';

const CustomTableHeader: React.FC<ICustomHeaderProps> = ({ name, header, order, orderBy, handleSort, handleFilter, filterOptions }) => {
    const [isOpen, setIsOpen] = useState(false);

    function debounce(fn: Function, ms = 1000) {
        let timeoutId: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: any[]) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn.apply(this, args), ms);
        };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedTextSearch = useCallback(debounce(handleFilter), []);

    const handleListFilter = (values: any) => {
        if (values.length > 0) {
            const list = values.map((value: any) => value.value);
            handleFilter(header['filterName'], list);
        } else {
            handleFilter(header['filterName'], null);
        }
    };

    return (
        <TableCell sortDirection={orderBy === header.key ? order : undefined} sx={{ backgroundColor: '#F9FAFB' }}>
            <Box sx={{ whiteSpace: 'nowrap', color: '#475467', fontWeight: 500, fontSize: 12 }}>
                {header['sortable'] ? (
                    <TableSortLabel
                        active={orderBy === header.key}
                        direction={orderBy === header.key ? order : 'asc'}
                        onClick={() => {
                            handleSort(header.key);
                        }}
                    >
                        {name}
                    </TableSortLabel>
                ) : (
                    name
                )}
                {header['hasFilter'] && (
                    <IconButton
                        aria-label="filter"
                        onClick={() => {
                            if (isOpen === true) handleFilter(header['filterName'], null);
                            setIsOpen(!isOpen);
                        }}
                    >
                        <FilterAltIcon color={isOpen ? 'primary' : 'disabled'} />
                    </IconButton>
                )}
            </Box>
            {isOpen && (
                <>
                    {header['filterType'] && (header['filterType'] === 'text' || header['filterType'] === 'number') && (
                        <TextField
                            id="standard-multiline-flexible"
                            label={name}
                            type={header['filterType']}
                            multiline
                            maxRows={4}
                            variant="standard"
                            onChange={(e: any) => {
                                optimizedTextSearch(header['filterName'], e.target.value);
                            }}
                        />
                    )}
                    {header['filterType'] && header['filterType'] === 'list' && (
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={
                                filterOptions && header['optionsKey'] && filterOptions[header['optionsKey']]
                                    ? filterOptions[header['optionsKey']]
                                    : []
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(event: any, newValue: any) => {
                                handleListFilter(newValue);
                            }}
                            sx={{ width: '150px' }}
                            renderInput={(params) => <TextField {...params} variant="standard" label={name} placeholder={name} />}
                        />
                    )}
                    {header['filterType'] && header['filterType'] === 'checkbox' && (
                        <Checkbox onClick={(e: any) => handleFilter(header['filterName'], e.target.check)} />
                    )}
                </>
            )}
        </TableCell>
    );
};

export default CustomTableHeader;
