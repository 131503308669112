import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import HorizontalBar from './HorizontalBar';
import Sidebar from './Sidebar';

import LAYOUT_CONST, { COLORS } from 'constant';
import useConfig from 'hooks/useConfig';
import navigation from 'menu-items';
import { useDispatch, useSelector } from 'store';
import { closedDrawerWidth, drawerWidth, headerHeight } from 'store/constant';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconChevronRight } from '@tabler/icons';
import ProfileSection from './Header/ProfileSection';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
    layout: string;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter + 200
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - closedDrawerWidth) : '20px',
            width: `calc(100% - ${drawerWidth}px)`
            // marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 72
        }
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '16px',
        //     marginTop: 72
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '0px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '0px',
        //     marginRight: '0px',
        //     marginTop: 72
        // }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter + 200
        }),
        marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? '20px' : 0,
        // marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 72,
        width: `calc(100% - ${drawerWidth}px)`
        // [theme.breakpoints.up('md')]: {
        // marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 72
    })
    // [theme.breakpoints.down('md')]: {
    //     marginLeft: '20px',
    //     marginTop: 72
    // },
    // [theme.breakpoints.down('sm')]: {
    //     marginLeft: '10px',
    //     marginTop: 72
    // }
    // }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { drawerType, container, layout } = useConfig();

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER && !matchDownMd) {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerType]);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER && !matchDownMd) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            dispatch(openDrawer(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <Toolbar disableGutters sx={{ height: headerHeight, borderBottom: '1px solid' + COLORS.LAYOUT_BORDER }}>
                <Header />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    return (
        <>
            {/* header */}
            <AppBar enableColorOnDark position="sticky" color="inherit" elevation={0} sx={{ bgcolor: theme.palette.background.default }}>
                {header}
            </AppBar>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {!matchDownMd && <ProfileSection />}

                {/* horizontal menu-list bar */}
                {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

                {/* drawer */}
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

                {/* main content */}
                <Main
                    id="main-content"
                    theme={theme}
                    open={drawerOpen}
                    layout={layout}
                    sx={{
                        margin: '0px',
                        marginLeft: '1px',
                        borderRadius: '0px',
                        bgcolor: COLORS.MAIN_BACKGROUND,
                        borderLeft: '1px solid' + COLORS.LAYOUT_BORDER,
                        padding: matchDownMd ? '0px' : '20px'
                        // backgroundColor: 'burlywood',
                        // marginTop: '72px'
                    }}
                >
                    <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                        {/* breadcrumb */}
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                </Main>
            </Box>
        </>
    );
};

export default MainLayout;
