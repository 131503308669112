/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
import { Checkbox, Grid, IconButton, Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createRef, useEffect, useRef, useState } from 'react';
import { ArrangementOrder } from 'types';
import MainCard from 'ui-component/cards/MainCard';
import CustomTableHeader from './CustomTableHeader';
import CustomTableRow from './CustomTableRow';
import { IHeaderData, IProps, ISearchQueryType } from './interfaces';
import LoadingLottie from 'ui-component/LoadingLottie';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import TableFilters from './TableFilters';
import TableToggleFilter from './TableToggleFilter';
import CustomPagination from './CustomPagination';
import GridIcon from '../../../assets/images/icons/grid.svg';
import ListIcon from '../../../assets/images/icons/list.svg';
import HandSwipeLottie from '../../../assets/lotties/handSwipe.json';
import Lottie from 'lottie-react';
import useDeviceType from 'hooks/useDeviceType';
import CustomTypography from 'ui-component/CustomTypography';
import checkedCheckBox from '../../../assets/images/icons/checkedCheckBox.svg';
import checkBox from '../../../assets/images/icons/checkbox.svg';

const SearchableTable: React.FC<IProps> = ({
    url,
    name,
    title,
    headers,
    requestMethod,
    tableFilters,
    inTitleFilters,
    collapsedHeaders,
    collapsedKey,
    clickableRows,
    clickableRowKeys,
    initialSearchQuery,
    filterOptions,
    setData,
    rowsPerPage,
    SecondaryActions,
    SideMenu,
    BulkActions,
    TableStickySectionComponent,
    TableEmptySectionComponent,
    toggleFilter,
    ActionColumn,
    ListViewComponent,
    selectableListItem = false,
    enableSwipeLottie = true
}) => {
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState<any>({});
    const [order, setOrder] = useState<ArrangementOrder>('desc');
    const [orderBy, setOrderBy] = useState<string>('id');
    const [searchQuery, setSearchQuery] = useState<ISearchQueryType>(initialSearchQuery);
    const [selectedRows, setSelectedRows] = useState<any>({});
    const [selectedRowsData, setSelectedRowsData] = useState<any>({});
    const [isRowsSelected, setIsRowsSelected] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [rowID, setRowID] = useState<number | undefined>(undefined);
    const [placement, setPlacement] = useState<any>();
    const [openActionMenu, setOpenActionMenu] = useState<boolean>(false);
    const inputRefs = useRef(Array.from({ length: 0 }, () => createRef()));
    const { isMobile } = useDeviceType();
    const [isTableView, setIsTableView] = useState(true);
    const mainCardRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!isMobile) setIsTableView(true);
    }, [isMobile]);

    const noResultsFound = (count: number) => {
        if (count < 1) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'No Results Found',
                    variant: 'info',
                    close: true
                })
            );
        }
    };

    const failedToFetch = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: 'Failed To Fetch Data',
                variant: 'error',
                close: true
            })
        );
    };

    const refresh = async () => {
        setIsLoading(true);
        setIsRowsSelected(false);
        setSelectedRows({});
        setSelectedRowsData({});
        try {
            let res1 = await axiosServices.get(url, { params: searchQuery });
            let res = res1.data;

            let newRows = [...rows];
            const startIndex = (searchQuery['page'] - 1) * searchQuery['pageSize'];
            const endIndex = searchQuery['page'] * searchQuery['pageSize'];
            const newRowsData: [] = res.results;
            newRows.splice(startIndex, endIndex - startIndex, ...newRowsData);

            setRows([...newRows]);
            if (setData) setData([...newRows]);
            setCount(res.total);
        } catch (err) {}
        setIsLoading(false);
    };

    const getRows = async (searchQuery: ISearchQueryType) => {
        try {
            let res1 = await axiosServices.get(url, { params: searchQuery });
            let res = res1.data;
            setRows(res.results);
            if (setData) setData(res[name]);

            setCount(res.total);
            noResultsFound(res.total);
        } catch (err) {
            failedToFetch();
        }
        setIsLoading(false);
    };

    // handle going to specific page
    const loadMore = async (page: number) => {
        searchQuery['page'] = page;
        setSearchQuery({ ...searchQuery });
        try {
            let res1 = await axiosServices.get(url, { params: searchQuery });
            let res = res1.data;

            const newRows = rows.concat(res.results);
            setRows([...newRows]);
            if (setData) setData(newRows?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage));
            noResultsFound(res.total);
        } catch (err) {
            failedToFetch();
        }
    };

    useEffect(() => {
        setPage(1);
        setRows([]);
        let newSearchQuery: ISearchQueryType = { ...initialSearchQuery };
        setSearchQuery({ ...newSearchQuery });
        getRows(newSearchQuery);
    }, [initialSearchQuery]);

    const handleChangePage = (_event: any, newPage: number) => {
        setIsLoading(true);
        if (rows.length < count && rows.length < (newPage + 1) * rowsPerPage) {
            loadMore(newPage);
        }
        setPage(newPage);
        setIsLoading(false);
        if (mainCardRef.current && isMobile) {
            mainCardRef.current.scrollIntoView();
        }
    };

    const handleFilter = (key: string | string[], value: any, overrideFilters: boolean = false) => {
        let newfilters = { ...filters };

        let newSearchQuery: ISearchQueryType = { ...initialSearchQuery };
        if (overrideFilters) {
            setFilters({});
        }
        if (key === 'dateRange') {
            newfilters['startDate'] = value.startDate;
            newfilters['endDate'] = value.endDate;
            newSearchQuery['startDate'] = value.startDate;
            newSearchQuery['endDate'] = value.endDate;
        }
        if (key === 'reset') {
            setFilters({});
        } else {
            if (Array.isArray(key)) {
                key.forEach((k, index) => {
                    newfilters[k] = value[index];
                });
            } else {
                newfilters[key] = value;
            }
            if (Object.keys(newfilters).length > 0) {
                Object.entries(newfilters).forEach(([key, value]) => {
                    if (value != null && value !== '' && key !== 'dateRange') {
                        newSearchQuery[key] = value;
                    }
                });
            }
            setFilters({ ...newfilters });
        }

        // TODO :: check if needed >> this is to clear selected when changing type
        if (title === 'Locations' && key === 'type') {
            setSelectedRows({});
            setSelectedRowsData({});
            setIsRowsSelected(false);
        }

        setRows([]);
        setSearchQuery({ ...newSearchQuery });
        setPage(1);
        getRows(newSearchQuery);
    };

    const handleClick = (e: any, row: any) => {
        e.stopPropagation();
        if (selectedRows[row.id]) {
            delete selectedRows[row.id];
            delete selectedRowsData[row.id];
        } else {
            selectedRows[row.id] = true;
            selectedRowsData[row.id] = row;
        }
        setSelectedRows({ ...selectedRows });
        setSelectedRowsData({ ...selectedRowsData });
    };

    const handleSelectAllRows = (e: any) => {
        e.stopPropagation();
        let selectedRowsTmp = { ...selectedRows };
        if (isRowsSelected) {
            //unselect all
            Object.keys(selectedRowsTmp).forEach((k) => {
                delete selectedRowsTmp[k];
                delete selectedRowsData[k];
            });
        } else {
            // select all
            rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row: any) => {
                selectedRowsTmp[row.id] = true;
                selectedRowsData[row.id] = row;
            });
        }
        setSelectedRows({ ...selectedRowsTmp });
        setSelectedRowsData({ ...selectedRowsData });
        setIsRowsSelected(!isRowsSelected);
    };

    const handleClickActionMenu = (newPlacement: any, rowID: number) => (event: React.MouseEvent | undefined) => {
        event?.stopPropagation();
        setAnchorEl(event?.currentTarget);
        setPlacement(newPlacement);
        setRowID(rowID);
        setOpenActionMenu((prev: any) => placement !== newPlacement || !prev);
    };

    const getSortDirection = (key: string): ArrangementOrder => {
        const isAsc = orderBy === key && order === 'asc';
        const sortDirection: ArrangementOrder = isAsc ? 'desc' : 'asc';
        return sortDirection;
    };

    const handleSort = (key: string) => {
        const sortDirection: ArrangementOrder = getSortDirection(key);
        setOrderBy(key);
        setOrder(sortDirection);

        let newSearchQuery: ISearchQueryType = { ...searchQuery };
        newSearchQuery['orderBy'] = {
            field: key,
            direction: sortDirection
        };

        setRows([]);
        setSearchQuery({ ...newSearchQuery });
        setPage(1);
        getRows(newSearchQuery);
    };

    const handleClearFilters = () => {
        inputRefs.current.forEach((ref: any) => {
            if (ref) {
                if (ref?.getElementsByClassName('MuiAutocomplete-clearIndicator')?.[0]) {
                    const ele = ref.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                    if (ele) ele.click();
                } else if (ref.type === 'checkbox') {
                    ref.checked = false;
                } else {
                    ref.value = '';
                }
            }
        });
        handleFilter('reset', '');
    };

    return (
        <Grid container rowSpacing={2}>
            {tableFilters && (
                <Grid item xs={12}>
                    <TableFilters
                        inputRefs={inputRefs}
                        handleClearFilters={handleClearFilters}
                        filters={filters}
                        tableFilters={tableFilters}
                        handleFilter={handleFilter}
                        filterOptions={filterOptions}
                    />
                </Grid>
            )}
            {TableStickySectionComponent && (
                <Grid item xs={12}>
                    <TableStickySectionComponent {...filters} />
                </Grid>
            )}
            {isTableView && isMobile && enableSwipeLottie && rows?.length != 0 && (
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start">
                        <Lottie
                            animationData={HandSwipeLottie}
                            loop
                            autoPlay
                            style={{ height: '5vh', width: '12vh', marginRight: '-1vh', marginLeft: '1vh' }}
                        />
                        <Typography sx={{ fontWeight: 500, fontSize: '14px', whiteSpace: 'nowrap' }}>
                            Swipe right and left to see more details
                        </Typography>
                    </Stack>
                </Grid>
            )}
            {toggleFilter && !isMobile && (
                <Grid item xs={12}>
                    <TableToggleFilter
                        handleClearFilters={handleClearFilters}
                        toggleFilter={toggleFilter}
                        filters={filters}
                        tableFilters={tableFilters}
                        handleFilter={handleFilter}
                        filterOptions={filterOptions}
                        inputRefs={inputRefs}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <MainCard
                    ref={mainCardRef}
                    title={title}
                    content={false}
                    isFilterInTableTitle={inTitleFilters}
                    sx={{ border: '1px solid #EAECF0' }}
                    secondary={
                        <Stack direction="row" spacing={1} alignItems="center">
                            {isMobile && ListViewComponent && (
                                <>
                                    <IconButton
                                        onClick={() => {
                                            setIsTableView(true);
                                        }}
                                        sx={{ width: 44, height: 44 }}
                                        style={isTableView ? { backgroundColor: '#015C9A' } : { backgroundColor: '#F5F5F5' }}
                                        aria-label="grid"
                                    >
                                        <img
                                            style={{
                                                filter: isTableView
                                                    ? ''
                                                    : ' brightness(0) saturate(100%) invert(23%) sepia(34%) saturate(4130%) hue-rotate(185deg) brightness(100%) contrast(99%)'
                                            }}
                                            src={GridIcon}
                                            alt={'Icon'}
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setIsTableView(false);
                                        }}
                                        sx={{ width: 44, height: 44 }}
                                        style={!isTableView ? { backgroundColor: '#015C9A' } : { backgroundColor: '#F5F5F5' }}
                                        aria-label="list"
                                    >
                                        <img
                                            style={{
                                                filter: !isTableView
                                                    ? ''
                                                    : ' brightness(0) saturate(100%) invert(23%) sepia(34%) saturate(4130%) hue-rotate(185deg) brightness(100%) contrast(99%)'
                                            }}
                                            src={ListIcon}
                                            alt={'Icon'}
                                        />
                                    </IconButton>
                                </>
                            )}
                            {!isMobile &&
                                SecondaryActions?.map((Action, i) => <Action key={i} refresh={refresh} searchQuery={searchQuery} />)}

                            {!isMobile &&
                                BulkActions &&
                                BulkActions.map((Action, i: number) => (
                                    <Action
                                        key={i}
                                        type={searchQuery.type}
                                        selectedRows={Object.keys(selectedRows).map((k) => parseInt(k))}
                                        selectedRowsData={selectedRowsData}
                                        setRows={setRows}
                                        refresh={refresh}
                                        disabled={Object.keys(selectedRows).length === 0}
                                    />
                                ))}
                            {inTitleFilters && (
                                <Grid item xs={12}>
                                    <TableFilters
                                        inputRefs={inputRefs}
                                        handleClearFilters={handleClearFilters}
                                        filters={filters}
                                        tableFilters={inTitleFilters}
                                        handleFilter={handleFilter}
                                        filterOptions={filterOptions}
                                        isFilterInTableTitle={inTitleFilters.length > 0}
                                    />
                                </Grid>
                            )}
                        </Stack>
                    }
                    subHeader={
                        toggleFilter &&
                        isMobile && (
                            <Stack direction="column">
                                <TableToggleFilter
                                    handleClearFilters={handleClearFilters}
                                    toggleFilter={toggleFilter}
                                    filters={filters}
                                    tableFilters={tableFilters}
                                    handleFilter={handleFilter}
                                    filterOptions={filterOptions}
                                    inputRefs={inputRefs}
                                />
                            </Stack>
                        )
                    }
                >
                    {/* {Object.keys(selectedRows).length > 0 && (
                        <TableToolbar
                            numSelected={Object.keys(selectedRows).length}
                            title={title}
                            BulkActions={BulkActions}
                            selectedRows={selectedRows}
                            refresh={refresh}
                        />
                    )} */}

                    {isTableView && (
                        <TableContainer>
                            <LoadingLottie loading={isLoading} />
                            {!isLoading &&
                                ((rows && rows.length != 0) || !TableEmptySectionComponent ? ( // if rows are empty and TableEmptySectionComponent is not provided, show empty table
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow key="header">
                                                {BulkActions && (
                                                    <TableCell
                                                        key="header-check"
                                                        sx={{
                                                            pl: 3,
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 99
                                                        }}
                                                    >
                                                        <Checkbox color="primary" onClick={handleSelectAllRows} checked={isRowsSelected} />
                                                    </TableCell>
                                                )}
                                                {collapsedHeaders && (
                                                    <TableCell
                                                        key={'collapse-arrow'}
                                                        sx={{
                                                            pl: 3
                                                        }}
                                                    />
                                                )}
                                                {headers.map(
                                                    (header: IHeaderData, i: number) =>
                                                        !header['hidden'] && (
                                                            <CustomTableHeader
                                                                key={i}
                                                                name={header['name']}
                                                                header={header}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                handleSort={handleSort}
                                                                handleFilter={handleFilter}
                                                                filterOptions={filterOptions}
                                                            />
                                                        )
                                                )}
                                                {SideMenu && (
                                                    <TableCell
                                                        key="header-action"
                                                        sx={{
                                                            pl: 3,
                                                            position: 'sticky',
                                                            right: 0,
                                                            zIndex: 99
                                                        }}
                                                    >
                                                        Actions
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!isLoading &&
                                                rows
                                                    ?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                                    .map((rowData: any) => (
                                                        <CustomTableRow
                                                            selectableRows={BulkActions ? true : false}
                                                            selectedRowsData={selectedRowsData}
                                                            key={rowData['id']}
                                                            rowData={rowData}
                                                            headers={headers}
                                                            collapsedHeaders={collapsedHeaders}
                                                            collapsedKey={collapsedKey}
                                                            clickableRows={clickableRows}
                                                            clickableRowKeys={clickableRowKeys}
                                                            handleClick={handleClick}
                                                            selectedRows={selectedRows}
                                                            handleClickActionMenu={handleClickActionMenu}
                                                            SideMenu={SideMenu}
                                                            ActionColumn={ActionColumn}
                                                            type={searchQuery.type || searchQuery.statusType}
                                                            setRows={setRows}
                                                            refresh={refresh}
                                                        />
                                                    ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <TableEmptySectionComponent isEmpty={Object.keys(filters).length === 0} />
                                ))}
                        </TableContainer>
                    )}
                    {!isTableView &&
                        ListViewComponent &&
                        ((rows && rows.length != 0) || !TableEmptySectionComponent ? (
                            <>
                                <Stack
                                    direction="row"
                                    spacing={0}
                                    sx={{
                                        // justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}
                                >
                                    {selectableListItem && (
                                        <>
                                            <Checkbox
                                                color="default"
                                                size="small"
                                                onClick={handleSelectAllRows}
                                                checked={isRowsSelected}
                                                icon={<img src={checkBox} alt="unchecked" />}
                                                checkedIcon={<img src={checkedCheckBox} alt="checked" />}
                                                sx={{ ml: -0.5 }}
                                            />
                                            <CustomTypography>Select all</CustomTypography>{' '}
                                        </>
                                    )}
                                </Stack>
                                <Stack spacing={1} sx={{ backgroundColor: '#EAECF0' }}>
                                    {rows?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((rowData: any) =>
                                        selectableListItem ? (
                                            <Grid
                                                container
                                                direction="row"
                                                gap={0}
                                                key={rowData['id']}
                                                alignItems="center"
                                                sx={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: !selectedRows[rowData['id']] ? '#EAECF0' : '#015C9A',
                                                    transition: 'background-color 0.2s ease'
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={1}
                                                    alignItems="center"
                                                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                                                >
                                                    <Checkbox
                                                        color="success"
                                                        checked={selectedRows[rowData['id']] || isRowsSelected}
                                                        onChange={(e) => handleClick(e, rowData)}
                                                        icon={<img src={checkBox} alt="unchecked" />}
                                                        sx={{ ml: -0.5 }}
                                                        checkedIcon={<img src={checkedCheckBox} alt="checked" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <ListViewComponent
                                                        key={rowData['id']}
                                                        rowData={rowData}
                                                        refresh={refresh}
                                                        isTableView={isTableView}
                                                        selectableRows={!!BulkActions}
                                                        selectedRows={selectedRows}
                                                        selectedRowsData={selectedRowsData}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <ListViewComponent
                                                key={rowData['id']}
                                                rowData={rowData}
                                                refresh={refresh}
                                                isTableView={isTableView}
                                                selectableRows={!!BulkActions}
                                                selectedRows={selectedRows}
                                                selectedRowsData={selectedRowsData}
                                            />
                                        )
                                    )}
                                </Stack>
                            </>
                        ) : (
                            <TableEmptySectionComponent isEmpty={Object.keys(filters).length === 0} />
                        ))}
                    {/* <TablePagination
                        sx={{
                            '& .MuiTablePagination-toolbar': {
                                padding: 0
                            }
                        }}
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    /> */}
                    {rows && <CustomPagination count={count} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} />}
                    {SideMenu && (
                        <SideMenu
                            anchorEl={anchorEl}
                            rowID={rowID}
                            placement={placement}
                            openActionMenu={openActionMenu}
                            refresh={refresh}
                        />
                    )}
                </MainCard>
            </Grid>
            {isMobile && (
                <Grid
                    sx={{ position: 'sticky', bottom: '2px', zIndex: 10, backgroundColor: 'white', padding: 2, paddingBottom: 4 }}
                    item
                    xs={12}
                >
                    <Stack direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent="space-between">
                        {SecondaryActions?.map((Action, i) => (
                            <Action sx={{ width: '100%' }} key={i} refresh={refresh} searchQuery={searchQuery} />
                        ))}
                        {BulkActions &&
                            BulkActions.map((Action, i: number) => (
                                <Action
                                    key={i}
                                    type={searchQuery.type}
                                    selectedRows={Object.keys(selectedRows).map((k) => parseInt(k))}
                                    selectedData={rows.filter((row: any) => selectedRows[row.id])}
                                    selectedRowsData={selectedRowsData}
                                    setRows={setRows}
                                    refresh={refresh}
                                    disabled={Object.keys(selectedRows).length === 0}
                                />
                            ))}
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};
export default SearchableTable;
