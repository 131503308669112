// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PeopleIcon from '@mui/icons-material/People';
import { RequestsIcon } from 'assets/images/sidebar-icons/requestsIcon';
import { NavItemType } from 'types';

// constant
const icons = {
    PeopleIcon,
    RequestsIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const campaignRequestsMenuItem: NavItemType = {
    id: 'campaign-requests',
    title: <FormattedMessage id="campaign-requests" />,
    type: 'item',
    url: '/campaign-requests',
    icon: RequestsIcon,
    external: false,
    target: false
};

export default campaignRequestsMenuItem;
