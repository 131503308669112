// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { WalletsIcon } from 'assets/images/sidebar-icons/walletsIcon';
import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const partnersWalletsMenuItem: NavItemType = {
    id: 'partners-wallets',
    title: <FormattedMessage id="partners-wallets" />,
    type: 'item',
    url: '/partners-wallets',
    icon: WalletsIcon,
    external: false,
    target: false
};

export default partnersWalletsMenuItem;
