import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import {
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    AuthenticationDetails,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoAccessToken
} from 'amazon-cognito-identity-js';

// reducer - state management
import { LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';
import axios from '../utils/axios';
import { useNavigate } from 'react-router-dom';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID || ''
});

export const setSession = (serviceToken?: string | null, expTime?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('expTime', expTime ?? '');
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('expTime');
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = localStorage.getItem('serviceToken');
                if (serviceToken) {
                    const user = userPool.getCurrentUser();
                    if (!user)
                        dispatch({
                            type: LOGOUT
                        });
                    else {
                        user.getSession((error: Error, session: CognitoUserSession | null) => {
                            if (session) setUserState(session);
                            else throw error;
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const setUserState = async (session: CognitoUserSession) => {
        const id_token: CognitoIdToken = session.getIdToken();
        const payload = id_token.payload;
        setSession(session.getIdToken().getJwtToken(), payload.exp);
        // await getCurrentUsersApi();
        if (payload) {
            dispatch({
                type: 'LOGIN',
                payload: {
                    isLoggedIn: true,
                    user: {
                        cognitoId: payload.sub,
                        email: payload.email,
                        name: payload.name,
                        phone: payload.phone,
                        groups: payload['cognito:groups']
                    }
                }
            });
        }
    };

    const refreshUserState = () => {
        const user = userPool.getCurrentUser();
        const storageRefreshToken = `CognitoIdentityServiceProvider.${
            process.env.REACT_APP_AWS_CLIENT_ID
        }.${user?.getUsername()}.refreshToken`;
        const refreshToken = localStorage.getItem(storageRefreshToken);
        if (refreshToken && user) {
            return new Promise((resolve, reject) => {
                user.refreshSession(new CognitoRefreshToken({ RefreshToken: refreshToken }), (err, result) => {
                    if (err) {
                        logout();
                        reject();
                    }
                    if (result) {
                        setUserState(result);
                        resolve(result);
                    }
                });
            });
        }
        throw new Error('Session Ended, Please Login Again');
    };

    const login = async (email: string, password: string) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        const authData = new AuthenticationDetails({
            Username: email,
            Password: password
        });
        return new Promise((resolve, reject) => {
            usr.authenticateUser(authData, {
                onSuccess: (session: CognitoUserSession) => {
                    setUserState(session);
                    resolve(session);
                },
                onFailure: (_err) => {
                    reject(_err);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    dispatch({
                        type: 'PASSWORD_CHALLENGE',
                        payload: {
                            isLoggedIn: false,
                            user: {
                                email: email,
                                password: password
                            }
                        }
                    });
                    resolve({ data: { challenge: 'PASSWORD_CHALLENGE' } });
                }
            });
        });
    };

    const register = (email: string, password: string, firstName: string, lastName: string) =>
        new Promise((success, rej) => {
            //   userPool.signUp(
            //     email,
            //     password,
            //     [
            //       new CognitoUserAttribute({ Name: "email", Value: email }),
            //       new CognitoUserAttribute({
            //         Name: "name",
            //         Value: `${firstName} ${lastName}`,
            //       }),
            //     ],
            //     [],
            //     async (err, result) => {
            //       if (err) {
            //         rej(err);
            //         return;
            //       }
            //       success(result);
            //     }
            //   );
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            loggedInUser.signOut();
            dispatch({ type: LOGOUT });
            navigate('login', { replace: true });
        }
    };

    const authorize = async (authorizationCode: string) => {
        const body = `grant_type=authorization_code&client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&code=${authorizationCode}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`;
        const tokens = await axios.post(`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        const accessToken = new CognitoAccessToken({
            AccessToken: tokens.data.access_token
        });
        const refreshToken = new CognitoRefreshToken({
            RefreshToken: tokens.data.refresh_token
        });
        const idToken = new CognitoIdToken({
            IdToken: tokens.data.id_token
        });
        const sessionData = {
            IdToken: idToken,
            AccessToken: accessToken,
            RefreshToken: refreshToken
        };
        localStorage.setItem('serviceToken', tokens.data.id_token);
        const session = new CognitoUserSession(sessionData);
        var userData = {
            Username: accessToken.payload.username,
            Pool: userPool
        };
        // create a cognito user using the userData object
        var cognitoUser = new CognitoUser(userData);

        // set the cognito user session w/ the CognitoUserSession
        cognitoUser.setSignInUserSession(session);
        setUserState(session);
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                login,
                authorize,
                refreshUserState,
                logout,
                register
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
