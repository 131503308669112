import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from './axios';
import { AxiosRequestConfig } from 'axios';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

const WithAxios = ({ children }: { children: JSX.Element }) => {
    const { refreshUserState } = useAuth();
    const dispatch = useDispatch();

    const refreshTokens = async (config: AxiosRequestConfig<any>) => {
        const serviceToken = localStorage.getItem('serviceToken');
        if (!serviceToken) {
            return config;
        }

        const expTime = parseInt(localStorage.getItem('expTime') ?? '0');

        const currentTime = Date.now() / 1000;
        const timeLeft = Math.floor(expTime - currentTime);

        if (timeLeft < 10) {
            const refreshedTokens = await refreshUserState();
            if (config.headers) config.headers.Authorization = `Bearer ${refreshedTokens.idToken.jwtToken}`;
        }
        return config;
    };

    useEffect(() => {
        axios.interceptors.request.use(
            async (config) => {
                config = await refreshTokens(config);
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error?.response.data?.message ?? 'An error has occurred, please try again later.',
                        variant: 'error',
                        // alert: {
                        //     color: 'error'
                        // },
                        close: true
                    })
                );
                throw error;
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
};

export default WithAxios;
