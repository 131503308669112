import React from 'react';
import { BaseLeadBalancerForm } from '../lead-balancer/BaseLeadBalancerForm';
import { WebsiteFormData, FormOptions } from '../lead-balancer/types';

interface WebsiteLeadBalancerFormProps {
    formData: WebsiteFormData;
    formOptions: FormOptions;
    handleChange: (key: string, value: any) => void;
    inputRefs: React.RefObject<HTMLInputElement>[];
}

export const WebsiteLeadBalancerForm: React.FC<WebsiteLeadBalancerFormProps> = ({ formData, formOptions, handleChange, inputRefs }) => {
    return (
        <BaseLeadBalancerForm
            formData={formData}
            formOptions={formOptions}
            handleChange={handleChange}
            fields={['partner', 'weightType', 'ruleType', 'ruleValue', 'weight']}
            inputRefs={inputRefs}
        />
    );
};
