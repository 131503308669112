import { memo, useMemo } from 'react';

// material-ui
import { Avatar, Box, Drawer, Icon, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party

// project imports
import LogoSection from '../LogoSection';
import MenuList from '../MenuList';
import MiniDrawerStyled from './MiniDrawerStyled';

import LAYOUT_CONST, { COLORS } from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth, headerHeight } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import burgerIcon from 'assets/images/navbar/burgerIcon.svg';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { layout, drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2 }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawerContent = <MenuList />;
    // const drawerContent = (
    //     <>
    //         <MenuList />
    //         {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && (
    //             <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
    //                 {/* <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} /> */}
    //             </Stack>
    //         )}
    //     </>
    // );

    const drawerSX = {
        paddingLeft: '16px',
        paddingRight: '16px',
        marginTop: '12px',
        paddingTop: '30px'
    };

    const drawer = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <Box sx={drawerSX}>{drawerContent}</Box>
                ) : (
                    <Box
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            ...drawerSX
                        }}
                    >
                        {drawerContent}
                    </Box>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, drawerOpen, drawerType]
    );

    return (
        <Box
            component="nav"
            style={{ width: matchUpMd ? (drawerOpen ? '196px' : '220px') : 'auto' }}
            sx={{ flexShrink: { md: 0 } }}
            aria-label="mailbox folders"
            borderRight={matchUpMd ? '1px solid' + COLORS.LAYOUT_BORDER : 'none'}
        >
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                            height: headerHeight,
                            ml: '16px'
                        }}
                    >
                        {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: 'transparent',
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                    '&:hover': {
                                        background: theme.palette.secondary.light
                                    },
                                    width: 40,
                                    height: 40
                                }}
                                onClick={() => dispatch(openDrawer(!drawerOpen))}
                            >
                                <Icon>
                                    <img src={burgerIcon} alt="menu icon" width="20" height="20" />
                                </Icon>
                            </Avatar>
                        )}
                        <LogoSection />
                    </Stack>
                    {drawer}
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    <Box sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider', minHeight: 64 }}>
                        <LogoSection />
                    </Box>
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
