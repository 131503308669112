import { Card, ImageList, ImageListItem, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PlatformsIcon from '../../assets/images/icons/platforms.svg';
import { PreApprovedLocation } from './interfaces/preapproved.interface';
import Trash from '../../assets/images/icons/trash.svg';
import PreApprovedLocationsRowActions from './PreApprovedLocationsRowActions';
import { format } from 'date-fns';

type PreApprovedListItemProps = {
    rowData: PreApprovedLocation;
    refresh: () => void;
};

const PreApprovedListItem: React.FC<PreApprovedListItemProps> = ({ rowData, refresh }) => {
    return (
        <>
            <Card
                sx={{
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    borderBottom: '1px solid  #EAECF0',
                    boxShadow: '0px 1px #EAECF0',
                    borderRadius: '0px'
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                    <Stack>
                        <Typography sx={{ color: '#015C9A', fontWeight: '500', fontSize: '16px' }}>{rowData.locationName}</Typography>
                        {/* <Typography variant="caption" sx={{ fontSize: '14px' }}>
                            ID: {rowData.id}
                        </Typography> */}
                    </Stack>
                    <PreApprovedLocationsRowActions id={rowData.id} icon={Trash} refresh={refresh} />
                </Stack>
                <Stack direction="column" spacing={1} sx={{ backgroundColor: '#F7F7F7', padding: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1'
                            }}
                        >
                            <ImageListItem>
                                <img src={PlatformsIcon} alt="platformsIcon" />
                            </ImageListItem>
                        </ImageList>
                        <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Platform: </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                textOverflow: 'ellipsis',
                                maxWidth: 120,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >
                            {rowData.platformName}
                        </Typography>
                        {/* <PlatformNameCell name={rowData.platformName} /> */}
                        {/* //TODO:: to be updated to a chip with platform icon after merging fallback story*/}
                    </Stack>
                </Stack>

                <Stack direction="row" height="80px" justifyContent="space-between" paddingY={1.5}>
                    <Stack direction="column" paddingX={1} flex={1}>
                        <Stack direction="row" spacing={1}>
                            <Typography
                                sx={{
                                    color: 'var(--Dark-grey, #101828)',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}
                            >
                                {rowData.pageName}
                            </Typography>
                        </Stack>
                        <Typography
                            sx={{
                                color: '#475467',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px'
                            }}
                        >
                            Page
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        paddingX={1}
                        flex={1}
                        sx={{
                            borderLeft: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: ' linear-gradient(180deg, #FFFFFF 0%, #BEBEBE 48.71%, #FCFCFC 100%)'
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Dark-grey, #101828)',
                                leading: '142.857%',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >
                            {format(new Date(rowData.createdAt), 'dd-MM-yyyy')}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#475467',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px'
                            }}
                        >
                            Created At
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
        </>
    );
};

export default PreApprovedListItem;
