import React from 'react';
import { ArrangementOrder } from 'types';

export interface IHeaderData {
    name: string;
    parentKey?: string;
    key: string;
    subKeys?: string[];
    subKey?: string;
    hasFilter?: boolean;
    filterType?: string;
    filterName?: string;
    optionsKey?: string;
    iterable?: boolean;
    iterableKeys?: string[];
    iterableKey?: string;
    isBoolean?: boolean;
    clickable?: boolean;
    linkPrefix?: string;
    export?: boolean;
    hidden?: boolean;
    type?: string;
    valueType?: string;
    sortable?: boolean;
    colorKey?: string;
    color?: string;
    bgColorKey?: string;
    bgColorMap?: { [key: string]: string };
    bgColorSubKeys?: string[];
    colorSubKeys?: string[];
    defaultValue?: any;
    externalLinkCell?: boolean;
    actionColumn?: boolean;
    ActionColumnComponent?: any;
    CustomCellComponent?: React.FC<ICustomCellProps>;
    prefix?: string;
    suffix?: string;
    statusChip?: boolean;
}
export enum FilterTypeEnum {
    TEXT = 'text',
    LIST = 'list',
    DATE = 'date',
    CHECKBOX = 'checkbox',
    SELECT_ONE = 'selectOne'
}

export interface ITableFilter {
    filterType: FilterTypeEnum | string;
    filterName: string;
    optionsKey?: string;
    title?: string;
    placeholder?: string;
    width?: string;
    paginated?: boolean;
    url?: string;
    initialSearchQuery?: ISearchQueryType;
    gridSize?: number;
}

export interface IToggleFilter {
    filterName: string;
    optionValues: string[];
    defaultValue: string;
    // handleClearFilters: any;
}

export interface ITableFiltersProps {
    tableFilters?: ITableFilter[];
    handleFilter: any;
    filterOptions?: any;
    filters?: any;
    toggleFilter?: IToggleFilter;
    inputRefs: any;
    handleClearFilters: any;
    isFilterInTableTitle?: boolean;
}
export interface IProps {
    url: string;
    name: string;
    title: string;
    headers: IHeaderData[];
    initialSearchQuery: ISearchQueryType;
    filterOptions?: {};
    setData?: any;
    rowsPerPage: number;
    SecondaryActions?: any[];
    SideMenu?: any;
    BulkActions?: any[];
    clickableRows?: boolean;
    clickableRowKeys?: {
        linkPrefix: string;
        linkPostfixKey: string;
        newPage?: boolean;
    };
    collapsedHeaders?: IHeaderData[];
    collapsedKey?: string;
    requestMethod?: any;
    tableFilters?: ITableFilter[];
    inTitleFilters?: ITableFilter[];
    TableStickySectionComponent?: any;
    TableEmptySectionComponent?: any;
    toggleFilter?: IToggleFilter;
    ActionColumn?: any;
    ListViewComponent?: React.FC<{
        rowData: any;
        refresh: () => void;
        isTableView: boolean;
        selectableRows?: boolean;
        selectedRows?: any;
        selectedRowsData?: any;
    }>;
    enableSwipeLottie?: boolean;
    selectableListItem?: boolean;
}
export interface ICustomHeaderProps {
    name: string;
    header: IHeaderData;
    handleFilter: any;
    filterOptions?: IFilterOptions;
    order: ArrangementOrder;
    orderBy: string;
    handleSort: any;
}
export interface ICustomRowProps {
    rowData: any;
    headers: IHeaderData[];
    clickableRows?: boolean;
    clickableRowKeys?: {
        linkPrefix: string;
        linkPostfixKey: string;
        newPage?: boolean;
    };
    handleClick: any;
    selectedRows: any;
    handleClickActionMenu: any;
    SideMenu?: any;
    collapsedHeaders?: IHeaderData[];
    collapsedKey?: string;
    selectableRows?: boolean;
    ActionColumn?: any;
    type?: string;
    statusType?: string;
    setRows?: React.Dispatch<React.SetStateAction<never[]>>;
    refresh?: any;
    selectedRowsData?: any;
}
export interface IFilterOptions {
    [key: string]: IOption[];
}
export interface IOption {
    value: any;
    label: string;
    isMandatory?: boolean;
}

export interface IActionMenuProps {
    anchorEl: any;
    rowID: number;
    placement: any;
    openActionMenu: boolean;
    refresh?: any;
}

export interface ISearchQueryType {
    pageSize: number;
    page: number;
    [key: string]: any;
}

export interface ICustomCellProps {
    data: any;
}
