import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import MainCard from 'ui-component/cards/MainCard';
import axiosServices from 'utils/axios';
import arrowLeft from '../../../assets/images/icons/arrow-left.svg';
import arrowRight from '../../../assets/images/icons/arrow-right.svg';
import walletIcon from '../../../assets/images/icons/walletIcon.svg';
import { filterDimensions } from '../../../store/constant';
import { searchableListProps } from './interfaces';
import Pagination from '../Pagination';
import { COLORS } from 'constant';

const SearchableList = ({ url, name, title, button, subTitle }: searchableListProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let res1 = await axiosServices.get(`${url}`, {
                params: {
                    name: searchQuery,
                    page: page,
                    pageSize: 10
                }
            });
            setData(res1.data.results);
            setTotalPages(Math.ceil(res1.data.total / 10)); // 10 items per page
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    // alert: { color: 'error' },
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, searchQuery]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(1); // reset to first page when searching
    };

    return (
        <Grid>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: { sm: 'row', xs: 'col' },
                    justifyContent: 'space-between',
                    alignItems: { sm: 'center' },
                    mb: '19px',
                    gap: '16px'
                }}
            >
                {title && <Typography variant="h3">{title}</Typography>}
                <Stack>
                    <Typography variant="caption" sx={{ color: COLORS.LABEL_COLOR, mb: 1 }}>
                        Search by partner name
                    </Typography>
                    <TextField
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={handleSearch}
                        sx={{
                            width: { sm: filterDimensions.width, xs: '100%' },
                            height: { sm: filterDimensions.height }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
            </Stack>
            <MainCard>
                {subTitle && <Typography variant="h3">{subTitle}</Typography>}
                <Stack spacing={2}>
                    {data?.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                px: { sm: 2, xs: 0 },
                                py: { sm: 2, xs: 2 },
                                borderBottom: '1px solid #E5E7EB',
                                '&:hover': {
                                    backgroundColor: '#F9FAFB'
                                }
                            }}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '8px',
                                        backgroundColor: '#F2F4F7',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img src={walletIcon} alt="wallet" style={{ width: '44px', height: '44px' }} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
                                    <Typography
                                        sx={{
                                            maxWidth: { xs: 100, md: '100%' },
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'no-wrap',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                    <Typography>({item.id})</Typography>
                                </Box>
                            </Stack>

                            <Button
                                sx={{
                                    color: '#015C9A',
                                    whiteSpace: 'nowrap',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        textDecoration: 'underline'
                                    }
                                }}
                                onClick={button?.navigateTo ? () => navigate(`${button.navigateTo}/${item.id}`) : () => navigate(``)}
                                endIcon={<img src={button?.icon} alt="arrow" />}
                            >
                                {button?.title}
                            </Button>
                        </Box>
                    ))}
                </Stack>

                {/* Pagination */}
                <Pagination page={page} totalPages={totalPages} setPage={setPage} />
            </MainCard>
        </Grid>
    );
};

export default SearchableList;
