// third-party
import { FormattedMessage } from 'react-intl';

// assets
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const leadBalancerMenuItem: NavItemType = {
    id: 'facebook-lead-balancer',
    title: <FormattedMessage id="Facebook Lead Balancer" />,
    type: 'item',
    url: '/facebook-lead-balancer',
    icon: AltRouteIcon,
    external: false,
    target: false
};

export default leadBalancerMenuItem;
