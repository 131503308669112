import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import LOCATIONS_APIS from './apis';
import { QueryLocationsInterface } from './interfaces';

export const queryLocations = createAsyncThunk(
    'locations/query',
    async (
        requestData: {
            query?: QueryLocationsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;
        try {
            const { data } = await axios.get(LOCATIONS_APIS.query(query ? query : {}));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
