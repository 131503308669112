import { createRef, useRef, useState } from 'react';
import axiosServices from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import { BaseFormData, FormOptions } from '../types';
import { LocationType } from 'components/locations/interfaces';

interface BaseLeadBalancerProps<T extends BaseFormData> {
    id?: number | null | undefined;
    data: T;
    refresh: () => Promise<void>;
    endpoint: string;
    onChange?: (key: string, value: any) => void;
}

export const useBaseLeadBalancer = <T extends BaseFormData>({ id, data, refresh, endpoint, onChange }: BaseLeadBalancerProps<T>) => {
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
    const [formOptions, setFormOptions] = useState<FormOptions>({
        partners: [],
        weightTypes: [],
        ruleTypes: [],
        locations: []
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formData, setFormData] = useState<T>(data);
    const inputRefs = useRef(Array.from({ length: 0 }, () => createRef()));

    const getFormOptions = async () => {
        try {
            const locationType = data?.ruleType === 'area' ? 'area' : 'compound';
            const [organizationsRes, pagesRes, locationsRes] = await Promise.all([
                axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organizations`),
                axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/pages`),
                axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/locations/${locationType}`)
            ]);

            const partners = organizationsRes.data.map((org: { id: any; name: any }) => ({
                value: org.id,
                label: org.name
            }));

            const pages = pagesRes.data.map((page: { id: any; name: any }) => ({
                value: page.id,
                label: page.name
            }));

            const locations = locationsRes.data[0].map((location: { id: any; name: any }) => ({
                value: location.id,
                label: location.name
            }));

            setFormOptions({
                partners,
                pages,
                locations,
                weightTypes: [
                    { value: 'location', label: 'Location' },
                    { value: 'generic', label: 'Generic' }
                ],
                ruleTypes: [
                    { value: 'area', label: 'Area' },
                    { value: 'compound', label: 'Compound' }
                ]
            });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const getLocations = async (locationType: LocationType) => {
        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/locations/${locationType}`);
            return response.data[0];
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Failed to fetch locations',
                    variant: 'error',
                    close: true
                })
            );
            return [];
        }
    };

    const handleChange = (key: string, value: any) => {
        if (key === 'weightType') {
            setFormData((prev) => ({
                ...prev,
                [key]: value,
                ruleType: null,
                ruleValue: null,
                weight: null
            }));
            return;
        }

        if (key === 'ruleType' && formData.weightType === 'location') {
            getLocations(value === 'area' ? LocationType.area : LocationType.compound).then((locationsRes) => {
                const locations = locationsRes.map((location: { id: any; name: any }) => ({
                    value: location.id,
                    label: location.name
                }));
                setFormOptions({ ...formOptions, locations: locations });
            });
        }

        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    const handleClearInputs = () => {
        inputRefs.current.forEach((ref: any) => {
            if (ref) {
                console.log('ref: ', ref);
                if (ref?.getElementsByClassName('MuiAutocomplete-clearIndicator')?.[0]) {
                    const ele = ref.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                    if (ele) ele.click();
                } else if (ref.type === 'checkbox') {
                    ref.checked = false;
                } else {
                    ref.value = '';
                }
            }
        });
    };

    const handleSave = async (transformData: (data: any) => any) => {
        try {
            const payload = transformData(formData);
            if (id) {
                await axiosServices.patch(`${process.env.REACT_APP_BACKEND}${endpoint}/${id}`, payload);
            } else {
                await axiosServices.post(`${process.env.REACT_APP_BACKEND}${endpoint}`, payload);
            }

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Lead balancer rule updated',
                    variant: 'success',
                    close: true
                })
            );
            setUpdateModalIsOpen(false);
            handleClearInputs();
            setFormData(data);
            await refresh();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const handleDelete = async () => {
        try {
            await axiosServices.delete(`${process.env.REACT_APP_BACKEND}${endpoint}/${id}`);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Lead balancer rule deleted',
                    variant: 'success',
                    close: true
                })
            );
            setDeleteModalIsOpen(false);
            await refresh();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    return {
        updateModalIsOpen,
        setUpdateModalIsOpen,
        deleteModalIsOpen,
        setDeleteModalIsOpen,
        formOptions,
        setFormOptions,
        isLoading,
        setIsLoading,
        formData,
        setFormData,
        handleChange,
        handleSave,
        handleDelete,
        getFormOptions,
        inputRefs,
        handleClearInputs
    };
};
