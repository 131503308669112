import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import ADSETS_APIS from './apis';
import { QueryAdsetsInterface } from './interfaces';

export const queryAdsets = createAsyncThunk(
    'adsets/query',
    async (
        requestData: {
            query?: QueryAdsetsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;

        try {
            const { data } = await axios.get(ADSETS_APIS.queryAdsets(query ? query : {}));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
