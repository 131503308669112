import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Grid } from '@mui/material';
import { IConfirmModalProps } from './interfaces';

const ConfirmModal: React.FC<IConfirmModalProps> = ({
    title,
    secondaryTitle,
    hasCommentSection,
    commentPlaceholder,
    confirmButtonTitle,
    confirmButtonColor,
    isOpen,
    handleConfirm,
    handleClose,
    requiredComment
}) => {
    const [comment, setComment] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const handleSubmit = () => {
        if (hasCommentSection && !comment.trim() && requiredComment) {
            setError(true);
            return;
        }

        handleConfirm(comment);
        handleClose();
        // Reset states
        setComment('');
        setError(false);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
        if (error && event.target.value.trim()) {
            setError(false);
        }
    };

    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '480px', maxHeight: '372px' } }} open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" sx={{ color: '#535862' }}>
                            {secondaryTitle}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            {hasCommentSection && (
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="caption" sx={{ color: '#888' }}>
                                Comment
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required={requiredComment}
                                fullWidth
                                error={error}
                                helperText={error ? 'Comment cannot be empty' : ''}
                                id="outlined-multiline-static"
                                placeholder={commentPlaceholder ?? ''}
                                multiline
                                rows={4}
                                value={comment}
                                onChange={handleCommentChange}
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        color: '#F04438'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        setComment('');
                        setError(false);
                    }}
                    variant="outlined"
                    sx={{
                        color: '#414651',
                        border: '1px solid #D5D7DA',
                        borderColor: '#D5D7DA',
                        width: '90px',
                        heigh: '40px',
                        borderRadius: '8px'
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={requiredComment && !comment.trim()}
                    variant="contained"
                    sx={{ backgroundColor: confirmButtonColor, width: '81px', heigh: '40px', borderRadius: '8px' }}
                    onClick={handleSubmit}
                >
                    {confirmButtonTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
