import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import SearchableTable from 'components/common/SearchableTable';
import { IFilterOptions, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import axiosServices from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import { headers } from '../../components/pre-approved-locations/headers';
import tableFilters from '../../components/pre-approved-locations/table-filters';
import NewPreApprovedButton from '../../components/pre-approved-locations/NewPreApprovedButton';
import PreApprovedListItem from 'components/pre-approved-locations/PreApprovedListItem';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';

const PreApprovedLocations = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});

    const [initialSearchQuery, setInitialSearchQuery] = useState<ISearchQueryType>({
        pageSize: 10,
        page: 1
    });

    const getFilterOptions = async () => {
        try {
            const [platformsResponse, pagesResponse] = await Promise.all([
                axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/platforms`),
                axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/pages`)
            ]);

            const platforms = platformsResponse.data.map((option: { id: any; name: any }) => ({
                value: option.id,
                label: option.name
            }));

            const pages = pagesResponse.data.map((option: { id: any; name: any }) => ({
                value: option.id,
                label: option.name
            }));

            setFilterOptions({ pages: pages, platforms: platforms });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error fetching filter options',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getFilterOptions();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ResponsivePageTitleComponent title="Pre-approved Locations" mobileOnly={true} />
                        {!isLoading && (
                            <SearchableTable
                                url={'/v1/pre-approved-locations'}
                                name="locations"
                                title="Pre-approved Locations"
                                headers={headers}
                                tableFilters={tableFilters}
                                setData={() => {}}
                                initialSearchQuery={initialSearchQuery}
                                rowsPerPage={initialSearchQuery.pageSize}
                                filterOptions={filterOptions}
                                SecondaryActions={[NewPreApprovedButton]}
                                ListViewComponent={PreApprovedListItem}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PreApprovedLocations;
