import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    Typography as MuiTypography,
    Stack,
    Tooltip
} from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import useDeviceType from 'hooks/useDeviceType';
interface CustomTypographyProps extends TypographyProps {
    tooltipText?: string | number | React.ReactNode;
    header?: string;
}
const extractPixels = (value: number | string): number => {
    let pixels = 0;
    if (typeof value === 'number') {
        pixels = value;
    } else {
        const match = value.match(/\d+/);
        pixels = match ? parseInt(match[0], 10) : 0;
    }
    return pixels;
};
const CustomTypography: React.FC<CustomTypographyProps> = ({ header, tooltipText, children, ...props }) => {
    const { isMobile } = useDeviceType();
    const [isWrapped, setIsWrapped] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const typographyRef = React.createRef<HTMLParagraphElement>();
    const [maxWidth, setMaxWidth] = useState<string | number>();
    const getTypographyStyle = () => {
        if (typographyRef.current) {
            let style = window.getComputedStyle(typographyRef.current);
            if (
                style &&
                extractPixels(style.maxWidth) &&
                ((style.whiteSpace === 'nowrap' && style.overflow === 'hidden') || props.noWrap) &&
                tooltipText
            ) {
                setMaxWidth(style.maxWidth);
                const isOverflowing = typographyRef.current.scrollWidth > extractPixels(style.maxWidth);

                setOffsetX(extractPixels(style.maxWidth) - extractPixels(style.maxWidth) / 4);
                if (isOverflowing) {
                    setIsWrapped(true);
                }
            }
        }
    };
    useEffect(() => {
        getTypographyStyle();
    }, []);
    return (
        <Box maxWidth={maxWidth || '100%'}>
            <Tooltip
                title={!isMobile && isWrapped && tooltipText ? tooltipText : ''}
                placement="top-start"
                arrow
                slotProps={{
                    tooltip: {
                        sx: {
                            fontSize: '14px',
                            lineHeight: '20px'
                        }
                    },
                    popper: {
                        modifiers: [
                            {
                                name: 'arrow',
                                options: {
                                    padding: 5
                                }
                            },
                            {
                                name: 'offset',
                                options: {
                                    offset: [offsetX, 0]
                                }
                            }
                        ]
                    }
                }}
            >
                <Stack flexDirection="row" alignItems="center">
                    <MuiTypography {...props} ref={typographyRef}>
                        {children}
                    </MuiTypography>
                    {isMobile && isWrapped && (
                        <Link underline="none" sx={{ color: '#196BDE', fontSize: 'inherit', whiteSpace: 'nowrap' }} onClick={handleOpen}>
                            See More
                        </Link>
                    )}
                </Stack>
            </Tooltip>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '403px', maxHeight: '372px', margin: '13px' } }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {header && (
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            {header}
                        </DialogTitle>
                    )}
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Divider />
                <DialogContent>
                    <Typography sx={{ wordBreak: 'break-word' }} gutterBottom>
                        {tooltipText}
                    </Typography>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
export default CustomTypography;
