import { CMS_API, formattedQuery } from 'store/apis';
import { QueryAdsetsInterface } from './interfaces';

const ADSETS_APIS = {
    queryAdsets: (query: QueryAdsetsInterface) => {
        const formatQuery: string[] = [CMS_API, '/v1/adsets/adsets-without-ads', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default ADSETS_APIS;
