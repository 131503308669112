import { Button } from '@mui/material';
import { useState } from 'react';
import AddPreApprovedDialog from './AddPreApprovedDialog';

const NewPreApprovedButton: React.FC<{ refresh: () => void }> = ({ refresh }) => {
    const [locationDialogIsOpen, setLocationDialogIsOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                variant="contained"
                sx={{ backgroundColor: '#015C9A', width: { xs: '100%', md: '166px' }, height: '44px', borderRadius: '8px' }}
                onClick={() => {
                    setLocationDialogIsOpen(true);
                }}
            >
                Add Location
            </Button>

            <AddPreApprovedDialog isOpen={locationDialogIsOpen} setDialogOpen={setLocationDialogIsOpen} refresh={refresh} />
        </>
    );
};

export default NewPreApprovedButton;
