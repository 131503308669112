import { FilterTypeEnum, ITableFilter } from 'components/common/SearchableTable/interfaces';

const fallbackScenariosFilters: ITableFilter[] = [
    { filterType: FilterTypeEnum.TEXT, filterName: 'name', title: 'Search by Page Name', placeholder: 'Search' },
    {
        filterType: FilterTypeEnum.LIST,
        filterName: 'platformIds',
        optionsKey: 'platforms',
        title: 'Platform',
        placeholder: 'Select Platform'
    },
    {
        filterType: FilterTypeEnum.LIST,
        filterName: 'fallbackOrganizationIds',
        // optionsKey: 'partners',
        title: 'CRM',
        placeholder: 'Select CRM',
        paginated: false,
        url: '/v1/organizations/query',
        initialSearchQuery: {
            pageSize: 10,
            page: 1,
            name: ''
        }
    }
];

export default fallbackScenariosFilters;
