// material-ui
import { Avatar, Icon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { Box, Stack, useMediaQuery } from '@mui/system';
import BellIcon from 'assets/images/navbar/bell.svg';
import burgerIcon from 'assets/images/navbar/burgerIcon.svg';
import SettingsIcon from 'assets/images/navbar/moon.svg';
import ProfileSection from './ProfileSection';
import MobileSection from './MobileSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();

    return (
        <Stack
            direction="row"
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
                // pt: '10px'
            }}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: 'transparent',
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <Icon>
                            <img src={burgerIcon} alt="menu icon" width="20" height="20" />
                        </Icon>
                    </Avatar>
                )}
                <LogoSection />
            </Stack>
            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
            <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: 'transparent',
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.secondary.light
                        }
                    }}
                >
                    <img src={SettingsIcon} alt="settings icon" width="20" height="20" />
                </Avatar>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: 'transparent',
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.secondary.light
                        }
                    }}
                >
                    <img src={BellIcon} alt="bell icon" width="20" height="20" />
                </Avatar>
                <ProfileSection />
            </Stack>
        </Stack>
    );
};

export default Header;
