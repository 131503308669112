import { Grid, Stack, Typography } from '@mui/material';
import LocationSelector from './LocationSelector';
import MainCard from 'ui-component/cards/MainCard';
import { mobilePadding } from 'store/constant';

const Compounds = () => {
    return (
        <Grid
            container
            gap={2}
            sx={{
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}
        >
            <Grid item xs={12} sx={{ marginX: { xs: `${mobilePadding}px`, md: 0 } }}>
                <Typography variant="h3" sx={{ alignItems: 'center', mt: 2 }}>
                    Manage Compounds
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <MainCard>
                    <Stack spacing={1.5}>
                        <LocationSelector locationType="Compound" label="Select Compound" />
                        <LocationSelector locationType="Area" label="Select Area" />
                        <LocationSelector locationType="Developer" label="Select Developer" />
                        <LocationSelector locationType="Custom" label="Select Custom Location" />
                    </Stack>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default Compounds;
