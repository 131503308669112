import { Grid, Box, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';

import { ITableFiltersProps } from './interfaces';
import useDeviceType from 'hooks/useDeviceType';

const TableToggleFilter: React.FC<ITableFiltersProps> = ({ handleClearFilters, toggleFilter, filters, handleFilter }) => {
    const { isMobile } = useDeviceType();
    return (
        <Box>
            <Grid item xs={12}>
                <ToggleButtonGroup
                    color="secondary"
                    exclusive
                    aria-label="Toggle filter"
                    size="small"
                    fullWidth={!isMobile ? false : true}
                    value={(toggleFilter?.filterName && filters[toggleFilter.filterName]) || toggleFilter?.defaultValue}
                    onChange={(event: React.MouseEvent<HTMLElement>, newValue: any) => {
                        handleFilter(toggleFilter?.filterName, newValue, true);
                    }}
                >
                    {toggleFilter?.optionValues &&
                        toggleFilter.optionValues.map((optionValue: string) => (
                            <ToggleButton
                                key={optionValue}
                                sx={{
                                    color: '#344054',
                                    border: '1px solid #D5D7DA',
                                    borderColor: '#D5D7DA',
                                    height: '40px',
                                    borderRadius: '8px',
                                    backgroundColor: 'white'
                                }}
                                value={optionValue}
                            >
                                {optionValue} (123)
                            </ToggleButton>
                        ))}
                </ToggleButtonGroup>
            </Grid>
        </Box>
    );
};
export default TableToggleFilter;
