import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, IconButton } from '@mui/material';
import SearchableTable from 'components/common/SearchableTable';
import { IFilterOptions, ISearchQueryType } from 'components/common/SearchableTable/interfaces';
import PartnersListItem from 'components/partners/PartnersListItem';
import partnersFilters from 'components/partners/table-filters';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { headers } from '../../components/partners/headers';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';
const Partners = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({});

    const initialSearchQuery: ISearchQueryType = {
        pageSize: 10,
        page: 1
    };

    const getFilterOptions = async () => {
        try {
            const options = [
                {
                    value: 1,
                    label: 'With CRM'
                },
                {
                    value: 0,
                    label: 'Without CRM'
                }
            ];
            setFilterOptions({ hasCRM: options });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    // alert: {
                    //     color: 'error'
                    // },

                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(false);
        getFilterOptions();
    }, []);

    const optionsButton = (props: any) => {
        return (
            <>
                <IconButton aria-label="more" id="long-button" aria-haspopup="true">
                    <MoreVertIcon />
                </IconButton>
            </>
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ResponsivePageTitleComponent title="Partners" mobileOnly={true} />
                        {!isLoading && (
                            <SearchableTable
                                url="/v1/organizations/search"
                                name="Partners"
                                title="Partners"
                                headers={headers}
                                tableFilters={partnersFilters}
                                setData={() => {}}
                                initialSearchQuery={initialSearchQuery}
                                rowsPerPage={initialSearchQuery.pageSize}
                                filterOptions={filterOptions}
                                // SecondaryActions={[optionsButton]}
                                ListViewComponent={PartnersListItem}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Partners;
