import { Autocomplete, Box, Button, Card, CardContent, Checkbox, Chip, Divider, Grid, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { IOrganizationSummary, IPage } from 'components/partner-summary/partner-summary.interface';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import LeadsIcon from '../../assets/images/icons/leads.svg';
import useDeviceType from 'hooks/useDeviceType';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';

const PartnerSummary = () => {
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isMobile } = useDeviceType();

    const [organizationData, setOrganizationData] = useState<{
        summary: IOrganizationSummary | null;
        availablePages: IPage[];
        originalState: {
            hasCRM: boolean;
            activeAgents: number;
            pages: IPage[];
        } | null;
    }>({
        summary: null,
        availablePages: [],
        originalState: null
    });

    const getOrganizationSummary = async () => {
        try {
            const [orgResponse, pagesResponse] = await Promise.all([
                axiosServices.get<any>(`${process.env.REACT_APP_BACKEND}/v1/organizations/${id}`),
                axiosServices.get<any>(`${process.env.REACT_APP_BACKEND}/v1/pages`)
            ]);

            const orgData = orgResponse.data;
            const summary: IOrganizationSummary = {
                organization: {
                    name: orgData.organization.name,
                    id: orgData.organization.id
                },
                pages: orgData.pages || [],
                hasCRM: orgData.hasCRM,
                activeAgents: orgData.activeAgents
            };

            setOrganizationData({
                summary,
                availablePages: pagesResponse.data,
                originalState: {
                    hasCRM: orgData.hasCRM,
                    activeAgents: orgData.activeAgents,
                    pages: orgData.pages || []
                }
            });
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (_event: any, newSelectedPages: IPage[]) => {
        if (!organizationData.summary) return;

        setOrganizationData((prev) => ({
            ...prev,
            summary: prev.summary ? { ...prev.summary, pages: newSelectedPages } : null
        }));
    };

    const handleActiveAgentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!organizationData.summary) return;

        const value = event.target.value === '' ? '' : parseInt(event.target.value);
        setOrganizationData((prev) => ({
            ...prev,
            summary: prev.summary ? { ...prev.summary, activeAgents: value === '' ? 0 : value } : null
        }));
    };

    const handleCRMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!organizationData.summary) return;

        setOrganizationData((prev) => ({
            ...prev,
            summary: prev.summary ? { ...prev.summary, hasCRM: event.target.checked } : null
        }));
    };

    const hasChanges = () => {
        if (!organizationData.summary || !organizationData.originalState) return false;

        return (
            organizationData.summary.hasCRM !== organizationData.originalState.hasCRM ||
            organizationData.summary.activeAgents !== organizationData.originalState.activeAgents ||
            JSON.stringify(organizationData.summary.pages.map((p) => p.id).sort()) !==
                JSON.stringify(organizationData.originalState.pages.map((p) => p.id).sort())
        );
    };

    const handleSave = async () => {
        if (!organizationData.summary) return;

        try {
            const { summary, originalState } = organizationData;
            const selectedPageIds = summary.pages.map((page) => page.id);

            await axiosServices.post(`${process.env.REACT_APP_BACKEND}/v1/organizations/subscribe-to-pages/${summary.organization.id}`, {
                pages: selectedPageIds
            });

            if (originalState && (summary.hasCRM !== originalState.hasCRM || summary.activeAgents !== originalState.activeAgents)) {
                await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organizations/${summary.organization.id}`, {
                    hasCRM: summary.hasCRM,
                    activeAgents: summary.activeAgents
                });
            }

            // Update original state after successful save
            setOrganizationData((prev) => ({
                ...prev,
                originalState: {
                    hasCRM: summary.hasCRM,
                    activeAgents: summary.activeAgents,
                    pages: summary.pages
                }
            }));

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes saved successfully',
                    variant: 'success',
                    close: true
                })
            );
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Failed to save changes',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        getOrganizationSummary();
    }, [id]);

    if (isLoading || !organizationData.summary) {
        return null; // or a loading spinner
    }

    return (
        <>
            <Grid container gap={3} direction="column">
                <Grid item xs={12} md={8}>
                    <ResponsivePageTitleComponent title="Manage Partner" mobileOnly={true} />
                    <Card sx={{ paddingX: { xs: '12px', md: 0 } }}>
                        <CardContent>
                            <Stack direction="column" spacing={2} alignItems="flex-start">
                                <Typography
                                    sx={{
                                        color: '#101828',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: '28px'
                                    }}
                                >
                                    Partner Name: {organizationData.summary.organization.name}
                                </Typography>
                                <Stack direction="row" alignItems="center" gap={1} mb={2}>
                                    <img src={LeadsIcon} alt={'Icon'} />
                                    <Typography variant="h5">Eligible Partner with CRM</Typography>
                                    <Checkbox checked={organizationData.summary.hasCRM} onChange={handleCRMChange} />
                                </Stack>

                                <Divider variant="middle" flexItem />

                                <Typography variant="body1">
                                    <strong>Facebook Pages</strong>
                                </Typography>

                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="body2">Pages assigned for this partner</Typography>
                                    <Autocomplete
                                        multiple
                                        options={organizationData.availablePages}
                                        sx={{
                                            width: { xs: '100%', md: '500px' },
                                            maxWidth: { xs: '100%', md: '500px' },
                                            '& .MuiChip-root': { borderRadius: '40px' }
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={organizationData.summary.pages}
                                        onChange={handlePageChange}
                                        disableCloseOnSelect
                                        disableClearable
                                        renderTags={(value: readonly IPage[], getTagProps) =>
                                            value.map((option: IPage, index: number) => {
                                                const { key, ...tagProps } = getTagProps({ index });
                                                return (
                                                    option.id && (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option.name || 'Unnamed Page'}
                                                            key={key}
                                                            {...tagProps}
                                                        />
                                                    )
                                                );
                                            })
                                        }
                                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add Pages" />}
                                    />
                                </Box>
                                <Divider variant="middle" flexItem />

                                <Box>
                                    <Typography variant="body1">
                                        <strong>Active Agents</strong>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body2">
                                        Edit the number of active agents for {organizationData.summary.organization.name}
                                    </Typography>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={organizationData.summary.activeAgents || ''}
                                        onChange={handleActiveAgentsChange}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+') {
                                                e.preventDefault();
                                            }
                                        }}
                                        inputProps={{
                                            min: 0,
                                            step: 1,
                                            pattern: '[0-9]*'
                                        }}
                                    />
                                </Box>
                            </Stack>
                            {!isMobile && (
                                <Stack direction="row" justifyContent="flex-end" width="100%">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#015C9A',
                                            borderRadius: '8px',
                                            // width: '150px',
                                            '&:disabled': {
                                                backgroundColor: '#B2DDFF',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={handleSave}
                                        disabled={!hasChanges()}
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {isMobile && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 2,
                        backgroundColor: 'white',
                        borderTop: '1px solid #EAECF0',
                        zIndex: 1200
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#015C9A',
                            borderRadius: '8px',
                            width: '100%',
                            '&:disabled': {
                                backgroundColor: '#B2DDFF',
                                color: 'white'
                            }
                        }}
                        onClick={handleSave}
                        disabled={!hasChanges()}
                    >
                        Save
                    </Button>
                </Box>
            )}
        </>
    );
};

export default PartnerSummary;
