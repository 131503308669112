import React from 'react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { FormOptions } from './types';

interface Organization {
    id: string | number;
    name: string;
}

interface Page {
    id: string | number;
    name: string;
}

interface BaseFormData {
    organization?: Organization;
    page?: Page;
    organizationId?: string | number;
    pageId?: string | number;
    weightType?: string;
    ruleType?: string;
    ruleValue?: string;
    weight?: number;
}

const FIELD_CONFIGS: Record<string, any> = {
    partner: {
        label: 'Partner',
        type: 'autocomplete',
        optionsKey: 'partners',
        valueKey: 'organizationId',
        defaultValueKey: 'organization'
    },
    page: {
        label: 'Page',
        type: 'autocomplete',
        optionsKey: 'pages',
        valueKey: 'pageId',
        defaultValueKey: 'page'
    },
    weightType: {
        label: 'Weight type',
        type: 'autocomplete',
        optionsKey: 'weightTypes'
    },
    ruleType: {
        label: 'Rule Type',
        type: 'autocomplete',
        optionsKey: 'ruleTypes',
        showIf: (formData: BaseFormData) => formData.weightType === 'location'
    },
    ruleValue: {
        label: 'Rule value',
        type: 'autocomplete',
        optionsKey: 'locations',
        showIf: (formData: BaseFormData) => formData.weightType === 'location'
    },
    weight: {
        label: 'Weight',
        type: 'number',
        min: 0,
        max: 100
    }
};

interface BaseLeadBalancerFormProps {
    formData: BaseFormData;
    formOptions: FormOptions;
    handleChange: (key: string, value: any) => void;
    fields: string[];
    inputRefs: React.RefObject<HTMLInputElement>[];
}

export const BaseLeadBalancerForm: React.FC<BaseLeadBalancerFormProps> = ({ formData, formOptions, handleChange, fields, inputRefs }) => {
    const renderField = (fieldName: string) => {
        const config = FIELD_CONFIGS[fieldName];

        if (config.showIf && !config.showIf(formData)) {
            return null;
        }

        return (
            <Grid item xs={12} key={fieldName}>
                <Typography variant="caption" sx={{ color: '#535862', mb: 2 }}>
                    {config.label}
                </Typography>
                {config.type === 'autocomplete' ? (
                    <Autocomplete
                        ref={(element: any) => {
                            if (inputRefs && Array.isArray(inputRefs)) {
                                inputRefs[config.label] = element;
                            }
                        }}
                        size="small"
                        clearOnBlur
                        options={formOptions[config.optionsKey as keyof FormOptions] || []}
                        defaultValue={
                            config.defaultValueKey && formData[config.defaultValueKey as keyof BaseFormData]
                                ? {
                                      value: (formData[config.defaultValueKey as keyof BaseFormData] as Organization | Page)?.id,
                                      label: (formData[config.defaultValueKey as keyof BaseFormData] as Organization | Page)?.name
                                  }
                                : formData[fieldName as keyof BaseFormData]
                                ? { value: formData[fieldName as keyof BaseFormData], label: formData[fieldName as keyof BaseFormData] }
                                : null
                        }
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(event: any, newValue: any) => {
                            if (config.valueKey === 'ruleValue' || fieldName === 'ruleValue') {
                                handleChange(config.valueKey || fieldName, newValue?.label);
                                handleChange('locationId', newValue?.value);
                            } else {
                                handleChange(config.valueKey || fieldName, newValue?.value);
                            }
                        }}
                    />
                ) : (
                    <TextField
                        ref={(element: any) => {
                            if (inputRefs && Array.isArray(inputRefs)) {
                                inputRefs[config.label] = element;
                            }
                        }}
                        fullWidth
                        size="small"
                        type={config.type}
                        value={formData[fieldName as keyof BaseFormData] ?? 0}
                        inputProps={{ min: config.min, max: config.max }}
                        onChange={(event) => handleChange(fieldName, event.target.value)}
                    />
                )}
            </Grid>
        );
    };

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                maxWidth: 400
            }}
        >
            <Grid container rowSpacing={1}>
                {fields.map(renderField)}
            </Grid>
        </Box>
    );
};
