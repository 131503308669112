import { createStaticRanges } from 'react-date-range';
import { startOfDay, endOfDay } from 'date-fns';
import dayjs from 'dayjs';

const tomorrow = dayjs().add(1, 'day');
export const defaultStaticRanges = createStaticRanges([
    {
        label: 'Next Week',
        range: () => ({
            startDate: startOfDay(tomorrow.toDate()),
            endDate: endOfDay(tomorrow.add(6, 'day').toDate())
        })
    },
    {
        label: 'Next Month',
        range: () => ({
            startDate: startOfDay(tomorrow.toDate()),
            endDate: endOfDay(tomorrow.add(29, 'day').toDate())
        })
    }
]);

export default defaultStaticRanges;
