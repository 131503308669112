// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { PartnersIcon } from 'assets/images/sidebar-icons/partnersIcon';
import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const partnersMenuItem: NavItemType = {
    id: 'partners',
    title: <FormattedMessage id="partners" />,
    type: 'item',
    url: '/partners',
    icon: PartnersIcon,
    external: false,
    target: false
};

export default partnersMenuItem;
