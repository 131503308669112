import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button, Divider } from '@mui/material';
import WalletIcon from '../../assets/images/icons/wallet.svg';
import CalenderIcon from '../../assets/images/icons/calender.svg';
import LeadsIcon from '../../assets/images/icons/leads.svg';
import LocationIcon from '../../assets/images/icons/location.svg';
import SpentIcon from '../../assets/images/icons/spent.svg';
import {
    IActionLogStatusUpdates,
    ICampaignSummary,
    IOrganizationCampaignApproval
} from '../../components/campaign-summary/campaign-summary.interface';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import ConfirmModal from 'components/common/ConfirmModal';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import ActionLog from 'components/common/ActionLog';
import connectorIcon from '../../assets/images/icons/Connector.svg';
import SimpleConfirmModal from 'components/common/SimpleConfirmModal';
import { COLORS } from 'constant';
import ResponsivePageTitleComponent from 'components/common/ResponsivePageTitle';

const CampaignRequestSummary = () => {
    interface RouteParams {
        ownerID: string;
        [key: string]: string | undefined;
    }
    const { id } = useParams<RouteParams>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [campaignSummary, setCampaignSummary] = useState<ICampaignSummary | null>(null);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
    const [actionLogStatusUpdates, setActionLogStatusUpdates] = useState<IActionLogStatusUpdates>({
        statusUpdates: [],
        organizationName: ''
    });
    const [simpleConfirmModalIsOpen, setSimpleConfirmModalIsOpen] = useState<boolean>(false);

    const getCampaignSummary = async () => {
        try {
            const response = await axiosServices.get<IOrganizationCampaignApproval>(
                `${process.env.REACT_APP_BACKEND}/v1/organization-campaign-approvals/${id}`
            );
            const resData = response.data;
            const campaignSummaryTemp: ICampaignSummary = {
                name: resData.organizationCampaign.name,
                budgetPerDay: resData.organizationCampaign.budget / resData.organizationCampaign.numberOfDays,
                startDate: resData.organizationCampaign.startDate,
                endDate: resData.organizationCampaign.endDate,
                numberOfDays: resData.organizationCampaign.numberOfDays,
                startImmediately: resData.organizationCampaign.startImmediately,
                Locations: resData.organizationCampaign.organizationCampaignEntities?.map((entity) => entity?.location?.name),
                expectedLeadsPerDay: resData.organizationCampaign.leadCount / resData.organizationCampaign.numberOfDays,
                exclusiveLeads: false,
                maximumCampaignSpent: resData.organizationCampaign.spendLimit,
                deletedAt: resData.deletedAt,
                approved: resData.approved,
                rejected: resData.rejected,
                statusUpdates: resData.statusUpdates
            };

            setActionLogStatusUpdates({
                statusUpdates: resData.statusUpdates || [],
                organizationName: resData.organizationCampaign.organization?.name
            });
            setCampaignSummary(campaignSummaryTemp);
        } catch (error) {
            console.error('Error fetching approval:', error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaign request not found',
                    variant: 'info',
                    // alert: {
                    //     color: 'info'
                    // },

                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleApprove = async () => {
        setIsLoading(true);
        try {
            await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organization-campaign-approvals/${id}`, {
                approved: true
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaign request approved',
                    variant: 'success',
                    // alert: {
                    //     color: 'success'
                    // },

                    close: true
                })
            );
            await getCampaignSummary();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    // alert: {
                    //     color: 'error'
                    // },

                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleReject = async (comment?: string | null) => {
        setIsLoading(true);
        try {
            await axiosServices.patch(`${process.env.REACT_APP_BACKEND}/v1/organization-campaign-approvals/${id}`, {
                rejected: true,
                comment: comment
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaign request rejected',
                    variant: 'success',
                    // alert: {
                    //     color: 'success'
                    // },

                    close: true
                })
            );
            await getCampaignSummary();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    // alert: {
                    //     color: 'error'
                    // },

                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCampaignSummary();
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    {/* Campaign Summary */}
                    <Grid item xs={12} md={8}>
                        <ResponsivePageTitleComponent title="Request Details" mobileOnly={true} />
                        <Card sx={{ paddingX: { xs: '12px', md: 0 } }}>
                            <CardContent>
                                <Typography variant="h4" sx={{ mb: 2 }}>
                                    Campaign summary
                                </Typography>
                                {/* <Typography variant="subtitle2" sx={{ mb: 2, color: '#888' }}>
                                    Partner campaign data created
                                </Typography> */}
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body1">
                                        <strong>Campaign Name</strong>: {campaignSummary?.name}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={WalletIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Budget per day</Typography>
                                        <Typography>
                                            {campaignSummary?.budgetPerDay &&
                                            !isNaN(campaignSummary.budgetPerDay) &&
                                            Number.isFinite(campaignSummary.budgetPerDay)
                                                ? campaignSummary.budgetPerDay.toLocaleString(undefined, {
                                                      maximumFractionDigits: 0
                                                  })
                                                : '--'}{' '}
                                            EGP / day
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={CalenderIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Campaign Time</Typography>
                                        <Typography>
                                            {campaignSummary?.startDate && campaignSummary?.endDate ? (
                                                <>
                                                    From <strong>{format(new Date(campaignSummary.startDate), 'dd/MM/yyyy')}</strong> to{' '}
                                                    <strong>{format(new Date(campaignSummary.endDate), 'dd/MM/yyyy')}</strong>
                                                    <strong> ({campaignSummary?.numberOfDays} days)</strong>
                                                </>
                                            ) : (
                                                '--'
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={LocationIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Location</Typography>
                                        <Typography>{campaignSummary?.Locations?.join(' - ')}</Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={LeadsIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Expected leads</Typography>
                                        <Typography>
                                            {campaignSummary?.expectedLeadsPerDay &&
                                                !isNaN(campaignSummary.budgetPerDay) &&
                                                Number.isFinite(campaignSummary.budgetPerDay) &&
                                                Math.trunc(campaignSummary.expectedLeadsPerDay)}{' '}
                                            / day
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={LeadsIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Exclusive leads</Typography>
                                        <Typography>Off</Typography>
                                    </Box>
                                </Box>
                                <Divider /> */}
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img src={SpentIcon} alt={'Icon'} />
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="h5">Maximum campaign spent</Typography>
                                        <Typography>
                                            {' '}
                                            {campaignSummary?.maximumCampaignSpent?.toLocaleString(undefined, {
                                                maximumFractionDigits: 0
                                            })}{' '}
                                            EGP
                                        </Typography>
                                    </Box>
                                </Box>
                                {!campaignSummary?.deletedAt && (
                                    <>
                                        <Divider />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                mt: 3,
                                                justifyContent: { xs: 'center', md: 'flex-end' }
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#BA0000',
                                                    width: { xs: '100%', md: '81px' },
                                                    heigh: '40px',
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        backgroundColor: '#A30000'
                                                    }
                                                }}
                                                onClick={() => setSimpleConfirmModalIsOpen(true)}
                                                disabled={isLoading}
                                            >
                                                Reject
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: COLORS.GREEN,
                                                    width: { xs: '100%', md: '81px' },
                                                    heigh: '40px',
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        backgroundColor: COLORS.DARK_GREEN
                                                    }
                                                }}
                                                onClick={() => handleApprove()}
                                                disabled={isLoading}
                                            >
                                                Approve
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Actions Log */}
                    <Grid item xs={12} md={4}>
                        <ActionLog
                            title="Actions Log"
                            subtitle="All actions taken on this campaign"
                            actionLogStatusUpdates={actionLogStatusUpdates}
                            connectorIcon={connectorIcon}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ height: 24 }} />
            </Box>
            {confirmModalIsOpen && (
                <ConfirmModal
                    title="Reject request"
                    secondaryTitle="Type the reason of rejection"
                    hasCommentSection={true}
                    requiredComment={true}
                    commentPlaceholder={'Write a brief why you rejecting this request...'}
                    confirmButtonTitle="Reject"
                    confirmButtonColor="#BA0000"
                    isOpen={confirmModalIsOpen}
                    handleConfirm={handleReject}
                    handleClose={() => {
                        setConfirmModalIsOpen(false);
                    }}
                />
            )}
            {simpleConfirmModalIsOpen && (
                <SimpleConfirmModal
                    open={simpleConfirmModalIsOpen}
                    onClose={() => setSimpleConfirmModalIsOpen(false)}
                    onConfirm={() => setConfirmModalIsOpen(true)}
                    title={'Are you sure you want to reject the campaign?'}
                    confirmText={'Yes Reject'}
                />
            )}
        </Box>
    );
};

export default CampaignRequestSummary;
