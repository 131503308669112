import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const preApprovedLocationsMenuItem: NavItemType = {
    id: 'pre-approved-locations',
    title: <FormattedMessage id="Pre-approved Locations" />,
    type: 'item',
    url: '/settings/pre-approved-locations',
    breadcrumbs: false
};

export default preApprovedLocationsMenuItem;
