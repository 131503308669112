import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    FormControlLabel,
    Grid,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import CreateCampaignSummary from 'components/campaign-form/CreateCampaignSummary';
import CustomizedRadioButton from 'components/campaign-form/CustomizedRadioButton';
import {
    ICampaignRequestCustomErrors,
    ICampaignRequestErrors
} from 'components/campaign-form/interfaces/campaign-request-errors.interface';
import { ICampaignRequest, ICreateCampaign, subscriptionType } from 'components/campaign-form/interfaces/campaign-request.interface';
import { ILocation } from 'components/campaign-form/interfaces/location.interface';
import { IOptions } from 'components/campaign-form/interfaces/options.interface';
import SubmissionView from 'components/campaign-form/SubmissionView';
import { IOrganization } from 'components/campaign-summary/campaign-summary.interface';
import { CustomNumericFormat } from 'components/common/customNumericFormat';
import { LocationType } from 'components/locations/interfaces';
import React, { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import * as Yup from 'yup';
import CustomDateRangePicker from 'ui-component/CustomDateRangePicker/CustomDateRangePicker';
import moment from 'moment';
import { FilterTypeEnum } from 'components/common/SearchableTable/interfaces';
import { startOfDay } from 'date-fns';
import defaultStaticRanges from './default-date-ranges';
import { COLORS } from 'constant';
import useDeviceType from 'hooks/useDeviceType';

// Validation schema with Yup
const createCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Campaign name is required'),
    organizationId: Yup.number().required('Partner is required').moreThan(0, 'Partner is required'),
    locationType: Yup.string().required('Location type is required'),
    locationIds: Yup.array(Yup.number()).when('locationType', {
        is: LocationType.compound,
        then: (schema) => schema.min(5, 'Please select at least five projects to proceed.'),
        otherwise: (schema) => schema.min(1, 'Location is required')
    }),
    subscriptionType: Yup.string().required('Campaign target is required'),
    budget: Yup.number().required('Minimum budget to start with is 1,000 EGP.').min(1000, 'Minimum budget to start with is 1,000 EGP'),
    leadCount: Yup.number().required(),
    startDate: Yup.date().required('Start date is required').min(startOfDay(new Date()), 'Campaign start date should not be in the past'),
    endDate: Yup.date().required('End date is required'),
    numberOfDays: Yup.number().min(7, 'Campaign duration should be at least 7 days')
});

interface ISearchQueryType {
    pageSize: number;
    page: number;
    name: string;
    locationTypes?: LocationType[]; // optional to be used for locations query only
}

const CampaignForm: React.FC = () => {
    const { isMobile } = useDeviceType();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [options, setOptions] = useState<IOptions | null>(null);
    const [errors, setErrors] = useState<ICampaignRequestErrors | null>(null);
    const [customErrors, setCustomErrors] = useState<ICampaignRequestCustomErrors>({
        general: null,
        budget: null,
        numberOfDays: null
    });
    const [campaignRequest, setCampaignRequest] = useState<ICampaignRequest>({
        name: null,
        organizationId: null,
        subscriptionType: subscriptionType.budget,
        budget: 0,
        leadCount: 0,
        locationType: LocationType.area,
        locationIds: [],
        locations: [],
        campaignTimeType: 'setDate'
    });

    const [partners, setPartners] = useState<IOrganization[]>([]);
    const [locations, setLocations] = useState<ILocation[]>([]);

    const [partnerQuery, setPartnerQuery] = useState<ISearchQueryType>({
        pageSize: 20,
        page: 1,
        name: ''
    });

    const [locationQuery, setLocationQuery] = useState<ISearchQueryType>({
        pageSize: 20,
        page: 1,
        name: '',
        locationTypes: [LocationType.area]
    });
    const handleDateFilter = (key: string | string[], value: any, overrideFilters: boolean = false) => {
        let numOfDays = moment(value.endDate).diff(moment(value.startDate), 'days');
        if (numOfDays >= 0) {
            numOfDays = numOfDays + 1;
        }

        if (value.startDate != null && (value.startDate < startOfDay(new Date()) || value.endDate < startOfDay(new Date()))) {
            setCustomErrors((prev) => ({ ...prev, numberOfDays: 'Campaign start date should not be in the past' }));
        } else if (numOfDays && numOfDays < 7) {
            setCustomErrors((prev) => ({ ...prev, numberOfDays: 'Campaign duration should be at least 7 days' }));
        } else {
            setCustomErrors((prev) => ({ ...prev, numberOfDays: null }));
        }
        setCampaignRequest((prev) => ({
            ...prev,
            startDate: value.startDate ? new Date(value.startDate) : null,
            endDate: value.endDate ? new Date(value.endDate) : null,
            numberOfDays: numOfDays
        }));
    };

    const handleCreateCampaign = async () => {
        setIsActionLoading(true);
        try {
            const createCampaign: ICreateCampaign = {
                name: campaignRequest.name ?? '',
                organizationId: campaignRequest.organizationId ?? 0,
                subscriptionType: campaignRequest.subscriptionType,
                budget: campaignRequest.budget ?? 0,
                leadCount: Math.trunc(campaignRequest.leadCount ?? 0),
                locationType: campaignRequest.locationType ?? LocationType.area,
                locationIds: campaignRequest.locationIds ?? [],
                startDate: campaignRequest.startDate ?? undefined,
                spendLimit: campaignRequest.spendLimit ?? undefined,
                endDate: campaignRequest.endDate ?? undefined,
                numberOfDays: campaignRequest.numberOfDays ?? undefined
            };

            await createCampaignValidationSchema
                .validate(createCampaign, { abortEarly: false })
                .then(async function () {
                    await createCampaignRequest(createCampaign);
                })
                .catch(function (err: any) {
                    let errorsTmp: any = {};
                    err.inner.forEach((e: any) => {
                        errorsTmp[e.path.replace('.', '_')] = e.message;
                    });
                    setErrors({ ...errorsTmp });
                    customErrors.general = 'Please fill the missing info.';
                    setCustomErrors({ ...customErrors });
                });
        } catch (e) {
            openSnackbar({
                open: true,
                message: 'Something went wrong',
                variant: 'error',
                close: true
            });
        }

        setIsActionLoading(false);
    };

    const createCampaignRequest = async (createCampaign: ICreateCampaign) => {
        try {
            await axiosServices.post(`${process.env.REACT_APP_BACKEND}/v1/organization-campaigns`, { ...createCampaign });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaign request has been submitted',
                    variant: 'success',
                    close: true
                })
            );
            setIsSuccess(true);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsActionLoading(false);
        }
    };

    const fetchPartners = async () => {
        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/organizations/query`, {
                params: partnerQuery
            });
            setPartners(response.data.results);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Could not fetch partners',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BACKEND}/v1/locations/live-locations`, {
                params: locationQuery
            });
            setLocations(response.data.results);
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Could not fetch locations',
                    variant: 'error',
                    close: true
                })
            );
        }
    };

    const handlePartnerSearch = (_event: React.SyntheticEvent, value: string) => {
        setPartnerQuery({
            ...partnerQuery,
            name: value,
            page: 1
        });
    };

    const handleLocationSearch = (_event: React.SyntheticEvent, value: string) => {
        setLocationQuery({
            ...locationQuery,
            name: value,
            page: 1
        });
    };

    const handleLocationTypeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.value as LocationType;
        await handleChange('locationType', newType);
        await handleChange('locationIds', []);
        await handleChange('locations', []);

        setLocationQuery({
            ...locationQuery,
            locationTypes: [newType],
            page: 1,
            name: ''
        });
    };

    const handleChange = async (key: keyof ICampaignRequest, value: any) => {
        setCampaignRequest((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const handleChangeBudget = (value: any) => {
        if (Number(value) < 1000) {
            customErrors.budget = 'The budget set is too low for the target location. Minimum budget to start with is 1,000 EGP.';
        } else {
            customErrors.budget = null;
        }
        setCustomErrors({ ...customErrors });

        if (Number(value)) {
            const leadCount = Number(value) / (campaignRequest?.locations?.[0]?.costPerLead ?? 1);
            handleChange('budget', value);
            handleChange('leadCount', Number(leadCount));
        }
    };

    const fetchInitialData = async () => {
        setIsLoading(true);
        try {
            await fetchPartners();
            await fetchLocations();
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Could not fetch initial data',
                    variant: 'error',
                    close: true
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    // initial data fetch
    useEffect(() => {
        fetchInitialData();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            // Skip the initial load
            fetchPartners();
        }
    }, [partnerQuery]);

    useEffect(() => {
        if (!isLoading) {
            // Skip the initial load
            fetchLocations();
        }
    }, [locationQuery]);

    const CreateRequestButton = () => {
        return (
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#015C9A',
                    width: { xs: '100%', md: '164px' },
                    height: '48px',
                    borderRadius: '8px'
                }}
                disabled={isActionLoading}
                onClick={handleCreateCampaign}
            >
                Create request
            </Button>
        );
    };

    return (
        <Grid container spacing={2}>
            {isSuccess ? (
                <SubmissionView />
            ) : (
                !isLoading && (
                    <>
                        {/* Main Content */}
                        <Box sx={{ flex: 1, pl: 2, mt: 2 }}>
                            {/* Error Message */}
                            {customErrors &&
                                Object.entries(customErrors).map(
                                    ([key, error]) =>
                                        error !== null && (
                                            <Alert key={key} severity="error" sx={{ marginBottom: 2 }}>
                                                {error}
                                            </Alert>
                                        )
                                )}
                            <Grid container spacing={2}>
                                {/* Form Section */}
                                <Grid item xs={12} md={8}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h4" gutterBottom sx={{ color: COLORS.BLUE }}>
                                                Create a new campaign
                                            </Typography>
                                            <Grid container mt={3} spacing={1}>
                                                {/* Location Section */}

                                                <Grid item xs={12}>
                                                    <Typography variant="inherit" sx={{ color: '#344054' }}>
                                                        Partner
                                                    </Typography>
                                                    <Autocomplete
                                                        key={'partners-auto'}
                                                        onChange={(event: any, newValue: IOrganization | null) => {
                                                            handleChange('organizationId', newValue?.id);
                                                        }}
                                                        onInputChange={handlePartnerSearch}
                                                        options={partners}
                                                        getOptionLabel={(option) => option.name}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        fullWidth
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                    {errors?.organizationId && (
                                                        <Typography variant="caption" sx={{ color: 'red' }}>
                                                            {errors?.organizationId}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="inherit" sx={{ color: '#344054' }}>
                                                        Campaign name
                                                    </Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        value={campaignRequest?.name}
                                                        onChange={(e) => handleChange('name', e.target.value)}
                                                    />
                                                    {errors?.name && (
                                                        <Typography variant="caption" sx={{ color: 'red' }}>
                                                            {errors?.name}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1">Choose Location</Typography>
                                                    <RadioGroup
                                                        row
                                                        defaultValue={LocationType.area}
                                                        value={campaignRequest?.locationType}
                                                        onChange={handleLocationTypeChange}
                                                        aria-labelledby="demo-customized-radios"
                                                        name="customized-radios"
                                                    >
                                                        <FormControlLabel
                                                            value={LocationType.area}
                                                            control={<CustomizedRadioButton />}
                                                            label="Area"
                                                            color="#344054"
                                                        />
                                                        <FormControlLabel
                                                            value={LocationType.compound}
                                                            control={<CustomizedRadioButton />}
                                                            label="Specific Project"
                                                            color="#344054"
                                                        />
                                                    </RadioGroup>
                                                    {campaignRequest.locationType === LocationType.compound ? (
                                                        <Autocomplete
                                                            key={'compound-auto'}
                                                            multiple
                                                            disableCloseOnSelect
                                                            onChange={(event: any, newValue: ILocation[]) => {
                                                                handleChange(
                                                                    'locationIds',
                                                                    newValue.map((value: ILocation) => value.id)
                                                                );
                                                                handleChange('locations', newValue);
                                                            }}
                                                            onInputChange={handleLocationSearch}
                                                            options={locations}
                                                            getOptionLabel={(option) => option.name}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            sx={{ width: '100%' }}
                                                            size="small"
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    ) : (
                                                        <Autocomplete
                                                            key={'area-auto'}
                                                            onChange={(event: any, newValue: ILocation | null) => {
                                                                handleChange('locationIds', [newValue?.id]);
                                                                handleChange('locations', [newValue]);
                                                            }}
                                                            onInputChange={handleLocationSearch}
                                                            options={locations}
                                                            getOptionLabel={(option) => option.name}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            sx={{ width: '100%' }}
                                                            size="small"
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    )}
                                                    {errors?.locationIds && (
                                                        <Typography variant="caption" sx={{ color: 'red' }}>
                                                            {errors?.locationIds}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ mt: 2 }} />
                                            {/* Budget Section */}
                                            <Grid container mt={1} columnSpacing={1} rowSpacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1">Campaign Target</Typography>
                                                    <RadioGroup
                                                        row
                                                        defaultValue={subscriptionType.budget}
                                                        onChange={(e) => handleChange('subscriptionType', e.target.value)}
                                                    >
                                                        {/* <FormControlLabel value={subscriptionType.leadCount} control={<Radio />} label="Set by leads per day" /> */}
                                                        <FormControlLabel
                                                            value={subscriptionType.budget}
                                                            control={<CustomizedRadioButton />}
                                                            label="Set by budget"
                                                            color="#344054"
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="inherit" sx={{ color: '#344054', marginBottom: 0 }}>
                                                        Budget
                                                    </Typography>
                                                    <TextField
                                                        title={
                                                            campaignRequest?.subscriptionType === subscriptionType.budget
                                                                ? 'Budget'
                                                                : 'Leads / day'
                                                        }
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{
                                                            inputComponent: CustomNumericFormat as any
                                                        }}
                                                        onChange={(e) => {
                                                            if (campaignRequest?.subscriptionType === subscriptionType.budget) {
                                                                handleChangeBudget(e.target.value);
                                                            }
                                                        }}
                                                    />
                                                    {errors?.budget && (
                                                        <Typography variant="caption" sx={{ color: 'red' }}>
                                                            {errors?.budget}
                                                        </Typography>
                                                    )}
                                                </Grid>

                                                {/* <Grid item xs={6}>
                                                <Typography variant="caption" sx={{ color: '#535862' }}>
                                                    Campaign spent limit
                                                </Typography>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        inputComponent: CustomNumericFormat as any
                                                    }}
                                                    size="small"
                                                    onChange={(e) => handleChange('spendLimit', Number(e.target.value))}
                                                />
                                            </Grid> */}
                                            </Grid>
                                            <Divider sx={{ margin: 2 }} />
                                            {/* Time Section */}

                                            {/* <CampaignDurationDatePicker
                                                setCampaignRequest={setCampaignRequest}
                                                setCustomErrors={setCustomErrors}
                                            /> */}
                                            <CustomDateRangePicker
                                                handleFilter={handleDateFilter}
                                                filter={{
                                                    filterType: FilterTypeEnum.DATE,
                                                    filterName: 'date',
                                                    title: 'Date'
                                                }}
                                                staticRanges={defaultStaticRanges}
                                            />
                                            {(errors?.startDate || errors?.endDate) && (
                                                <Stack>
                                                    <Typography variant="caption" sx={{ color: 'red' }}>
                                                        {errors?.startDate}
                                                    </Typography>
                                                    <Typography variant="caption" sx={{ color: 'red' }}>
                                                        {errors?.endDate}
                                                    </Typography>
                                                </Stack>
                                            )}

                                            {!isMobile && (
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                                    <CreateRequestButton />
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Summary Section */}
                                <CreateCampaignSummary campaignRequest={campaignRequest} />
                            </Grid>
                            {isMobile && (
                                <Grid
                                    sx={{
                                        position: 'sticky',
                                        bottom: '2px',
                                        zIndex: 10,
                                        backgroundColor: 'white',
                                        padding: 2,
                                        paddingBottom: 4
                                    }}
                                    item
                                    xs={12}
                                >
                                    <CreateRequestButton />
                                </Grid>
                            )}
                        </Box>
                    </>
                )
            )}
        </Grid>
    );
};

export default CampaignForm;
