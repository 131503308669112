/* eslint-disable prettier/prettier */
import { Card, Divider, ImageList, ImageListItem, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import pagesIcon from '../../assets/images/icons/pagesIcon.svg';
import { Organization } from './interfaces/organization.interface';
import CustomTypography from 'ui-component/CustomTypography';

const PartnersListItem: React.FC<{ rowData: Organization }> = ({ rowData }) => {
    const renderPages = () => {
        let pages = 'No Pages Available';
        if (rowData.pages && rowData.pages.length > 0 && rowData.pages[0]?.name) {
            pages = rowData.pages.map((page) => page.name).join(', ');
        }
        return (
            <CustomTypography
                noWrap
                tooltipText={pages}
                header="Facebook Pages"
                sx={{
                    color: '#101828',
                    leadingTrim: 'both',
                    textEdge: 'cap',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px'
                }}
                maxWidth={150}
            >
                {pages}
            </CustomTypography>
        );
    };

    return (
        <Card
            sx={{
                pt: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                borderBottom: '1px solid #EAECF0',
                boxShadow: '0px 1px #EAECF0',
                borderRadius: '0px'
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack>
                    <Link href={`partners/${rowData.id}`} target="_blank" sx={{ alignSelf: 'end' }} underline="none" color="inherit">
                        <Typography sx={{ color: '#015C9A', fontWeight: '500', fontSize: '16px', pl: 1 }}>{rowData.name}</Typography>
                    </Link>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} sx={{ backgroundColor: '#F7F7F7', padding: 1 }}>
                <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1',
                                flexShrink: 0
                            }}
                        >
                            <ImageListItem>
                                <img src={pagesIcon} alt="pages icon" />
                            </ImageListItem>
                        </ImageList>
                        <CustomTypography noWrap sx={{ fontSize: '14px', fontWeight: 400 }}>
                            Facebook Pages:
                        </CustomTypography>
                        {renderPages()}
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                height="88px"
                justifyContent="space-between"
                divider={<Divider variant="middle" orientation="vertical" flexItem />}
            >
                <Stack direction="column" paddingX={1}>
                    <Stack direction="column" spacing={0.5}>
                        <Typography
                            sx={{
                                color: '#101828',
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px'
                            }}
                        >
                            {rowData.leadCount}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#475467',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px'
                            }}
                        >
                            Leads
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="column" paddingX={1}>
                    <Stack direction="column" spacing={0.5}>
                        <Typography
                            sx={{
                                color: '#101828',
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px'
                            }}
                        >
                            {rowData.activeCampaignsCount}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#475467',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px'
                            }}
                        >
                            Active Campaigns
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="column" paddingX={1}>
                    <Stack direction="column" spacing={0.5}>
                        <Typography
                            sx={{
                                color: '#101828',
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                                whiteSpace: 'normal',
                                maxWidth: '150px'
                            }}
                        >
                            {rowData.budgetSpent.spent} EGP of {rowData.budgetSpent.budget} EGP
                        </Typography>
                        <Typography
                            sx={{
                                color: '#475467',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px'
                            }}
                        >
                            Spent / Total budget
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};

export default PartnersListItem;
