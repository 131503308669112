import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Loader from 'ui-component/Loader';

const Authorize = () => {
    const [searchParams] = useSearchParams();
    const { authorize } = useAuth();

    useEffect(() => {
        const authorizationCode = searchParams.get('code');
        authorize(authorizationCode!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Loader />;
};

export default Authorize;
