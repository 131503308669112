// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LocationsIcon } from 'assets/images/sidebar-icons/locationsIcon';

import { NavItemType } from 'types';

// constant
const icons = {
    LocationsIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const locationsMenuItem: NavItemType = {
    id: 'locations',
    title: <FormattedMessage id="locations" />,
    url: '/locations',
    icon: LocationsIcon,
    type: 'item',
    external: false,
    target: false
};

export default locationsMenuItem;
