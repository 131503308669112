// theme constant
export const gridSpacing = 3;
export const drawerWidth = 264;
export const closedDrawerWidth = 72;
export const appDrawerWidth = 320;
export const headerHeight = 72;
export const mobilePadding = 16;

export const filterDimensions = {
    width: '418px',
    height: '44px'
};
