import { Card, ImageList, ImageListItem, Stack } from '@mui/material';
import PlatformNameCell from 'components/common/PlatformNameCell';
import CustomTypography from 'ui-component/CustomTypography';
import pagesIcon from '../../assets/images/icons/pagesIcon.svg';
import FallbackScenarioRowActions from './FallbackScenarioRowActions';
import { PagesFallbackOrganizations } from './interfaces/pagesFallbackOrganizations.interface';

interface Props {
    rowData: PagesFallbackOrganizations;
    refresh: () => void;
}

const FallbackScenariosListItem: React.FC<Props> = ({ rowData, refresh }) => {
    const concatOrganizationNames = rowData.pageFallbackOrganizations
        .map((organization) => organization.fallbackOrganization.name)
        .join(' - ');

    return (
        <>
            <Card
                sx={{
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    borderBottom: '1px solid  #EAECF0',
                    boxShadow: '0px 1px #EAECF0',
                    borderRadius: '0px'
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <PlatformNameCell data={rowData.platform.name} />
                    <Stack direction="row" spacing={1}>
                        <FallbackScenarioRowActions id={rowData.id} data={rowData} refresh={refresh} />
                    </Stack>
                </Stack>

                <Stack direction="column" spacing={1} sx={{ backgroundColor: '#F7F7F7', padding: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ImageList
                            sx={{
                                width: 26,
                                height: 26,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E1E1E1'
                            }}
                        >
                            <ImageListItem>
                                <img src={pagesIcon} alt="locationIcon" />
                            </ImageListItem>
                        </ImageList>
                        <CustomTypography sx={{ fontSize: '14px', fontWeight: 400 }}>Page: </CustomTypography>
                        <CustomTypography sx={{ fontSize: '14px', fontWeight: 600, color: '#101828' }}>{rowData.name}</CustomTypography>
                    </Stack>
                </Stack>
                <Stack direction="column" height="46px" spacing={1}>
                    {concatOrganizationNames ? (
                        <>
                            <CustomTypography
                                maxWidth={250}
                                noWrap
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#101828'
                                }}
                                tooltipText={concatOrganizationNames}
                            >
                                {concatOrganizationNames}
                            </CustomTypography>
                            <CustomTypography sx={{ fontSize: '12px', fontWeight: '500', color: '#475467' }}>CRM</CustomTypography>
                        </>
                    ) : (
                        <CustomTypography sx={{ fontSize: '12px', fontWeight: '500', color: '#475467' }}>
                            No Fallback CRMs Assigned
                        </CustomTypography>
                    )}
                </Stack>
            </Card>
        </>
    );
};

export default FallbackScenariosListItem;
