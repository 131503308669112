import { Chip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

interface IProps {
    label: string;
}

const backgroundColor: { [key: string]: string } = {
    active: '#09FE7733',
    finished: '#09FE7733',
    live: '#09FE7733',
    approved: '#09FE7733',
    pending: '#F8B00733',
    paused: '#FF1E0033',
    rejected: '#FF1E0033',
    stopped: '#FF1E0033'
};

const textColor: { [key: string]: string } = {
    active: '#079455',
    finished: '#079455',
    live: '#079455',
    approved: '#079455',
    pending: '#E68248',
    paused: '#C83D3D',
    rejected: '#C83D3D',
    stopped: '#C83D3D'
};

const StatusChip: React.FC<IProps> = ({ label }) => {
    const background = backgroundColor[label.toLowerCase()];
    const text = textColor[label.toLowerCase()];

    return (
        <>
            <Chip
                icon={<CircleIcon color="inherit" sx={{ fontSize: 8 }} />}
                label={label.charAt(0).toUpperCase() + label.slice(1)}
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    bgcolor: background,
                    color: text,
                    borderRadius: '8px',
                    height: 24,
                    width: 100,
                    fontWeight: 500,
                    fontSize: 12,
                    paddingX: '4px'
                }}
            />
        </>
    );
};

export default StatusChip;
