// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import organizations from './slices/organizations';
import locations from './slices/locations';
import ads from './slices/ads';
import adsets from './slices/adsets';
import pages from './slices/pages';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    organizations,
    locations,
    ads,
    adsets,
    pages
});

export default reducer;
