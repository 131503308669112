import { createSlice } from '@reduxjs/toolkit';
import { queryAdsets } from './actions';
import { QueryAdsetsInitialStateInterface } from './interfaces';

// initial state
const initialState: QueryAdsetsInitialStateInterface = {
    adsets: {
        adsetName: ''
    }
};

// ==============================|| SLICE - cils ||============================== //

const adsets = createSlice({
    name: 'adsets',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryAdsets.pending, (state) => {})
            .addCase(queryAdsets.fulfilled, (state, action) => {
                state.adsets = action.payload;
            })
            .addCase(queryAdsets.rejected, (state, action) => {})
            .addDefaultCase((state, action) => {});
    }
});

export default adsets.reducer;
