import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography, Grid, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SimpleConfirmModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
    textAlign?: string;
    hasCloseButton?: boolean;
}

const SimpleConfirmModal: React.FC<SimpleConfirmModalProps> = ({
    open,
    onClose,
    title,
    onConfirm,
    confirmText = 'Yes',
    cancelText = 'No',
    textAlign,
    hasCloseButton = true
}) => {
    const handleClose = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClose();
    };

    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '480px', maxHeight: '372px' } }} open={open} onClose={onClose}>
            {hasCloseButton && (
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    onTouchEnd={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500]
                    })}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <DialogContent sx={{ paddingTop: { xs: 3, md: '20px' }, paddingBottom: { xs: 1, md: '20px' } }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: '#101828',
                                textAlign: textAlign ? textAlign : { xs: 'center', md: 'left' }
                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        justifyContent: 'center',
                        paddingX: { xs: 2, md: 0 },
                        flexDirection: { xs: 'column-reverse', md: 'row' }
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'white',
                                color: '#015C9A',
                                width: { xs: '100%', md: '193px' },
                                height: '40px',
                                borderRadius: '8px',
                                border: '1px solid #015C9A',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    boxShadow: 'none'
                                }
                            }}
                            onClick={() => {
                                onClose();
                                onConfirm();
                            }}
                        >
                            {confirmText}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                backgroundColor: '#015C9A',
                                width: { xs: '100%', md: '193px' },
                                height: '40px',
                                borderRadius: '8px',
                                color: 'white',
                                border: '1px solid #D5D7DA',
                                '&:hover': {
                                    backgroundColor: '#015C9A',
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            {cancelText}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default SimpleConfirmModal;
