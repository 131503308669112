import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { RefObject, useCallback, useEffect } from 'react';
import { Box } from '@mui/system';
import { FacebookFormData } from '../lead-balancer/types';
import { useWebsiteLeadBalancer } from './hooks/useWebsiteLeadBalancer';
import { WebsiteLeadBalancerForm } from './WebsiteLeadBalancerForm';

interface CreateLeadBalancerFormProps {
    refresh: () => Promise<void>;
    id?: number;
    initialData?: FacebookFormData;
}
const CreateWebsiteLeadBalancerForm = ({ refresh, id, initialData }: CreateLeadBalancerFormProps) => {
    const {
        updateModalIsOpen: isOpen,
        setUpdateModalIsOpen: setIsOpen,
        formOptions,
        isLoading,
        formData,
        handleChange,
        handleSave,
        getFormOptions,
        setFormData,
        inputRefs,
        handleClearInputs
    } = useWebsiteLeadBalancer({
        id: null,
        data: {
            organizationId: undefined,
            weightType: undefined,
            ruleType: undefined,
            ruleValue: undefined,
            weight: undefined
        },
        refresh
    });

    const handleClose = () => {
        setFormData({
            organizationId: undefined,
            weightType: undefined,
            ruleType: undefined,
            ruleValue: undefined,
            weight: undefined
        });
        handleClearInputs();
        setIsOpen(false);
    };

    const memoizedFormOptionsCallback = useCallback(() => {
        getFormOptions();
    }, []);

    useEffect(() => {
        memoizedFormOptionsCallback();
    }, [memoizedFormOptionsCallback]);

    return (
        <Grid>
            <Button variant="contained" sx={{ backgroundColor: '#015C9A', borderRadius: '8px' }} onClick={() => setIsOpen(true)}>
                {id ? 'Edit' : 'Create'} Lead Balancer Rule
            </Button>
            {!isLoading && (
                <Dialog sx={{ '& .MuiDialog-paper': { width: '480px', maxHeight: '500px' } }} open={isOpen} onClose={handleClose}>
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h3">{id ? 'Edit' : 'Create'} Website Lead Balancer Rule</Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                width: '100%',
                                maxWidth: 400
                            }}
                        >
                            <WebsiteLeadBalancerForm
                                formData={formData}
                                formOptions={formOptions}
                                handleChange={handleChange}
                                inputRefs={inputRefs as unknown as RefObject<HTMLInputElement>[]}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            sx={{
                                color: '#414651',
                                border: '1px solid #D5D7DA',
                                borderColor: '#D5D7DA',
                                width: '90px',
                                heigh: '40px',
                                borderRadius: '8px'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#015C9A', width: '90px', heigh: '40px', borderRadius: '8px' }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Grid>
    );
};

export default CreateWebsiteLeadBalancerForm;
