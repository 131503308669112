// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { SettingsIcon } from 'assets/images/sidebar-icons/settingsIcon';
import { NavItemType } from 'types';
import fallbackScenariosMenuItem from './fallback-scenarios';
import preApprovedLocationsMenuItem from './pre-approved-locations';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const settingsMenuItem: NavItemType = {
    id: 'settings-item-group-collapse',
    type: 'group',
    children: [
        {
            id: 'settings-collapse',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: SettingsIcon,
            breadcrumbs: false,
            children: [fallbackScenariosMenuItem, preApprovedLocationsMenuItem]
        }
    ]
};

export default settingsMenuItem;
