import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Divider, Card, CardContent, Button } from '@mui/material';
import { ICampaignRequest } from 'components/campaign-form/interfaces/campaign-request.interface';
import { ILocation } from 'components/campaign-form/interfaces/location.interface';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import WalletIcon from '../../assets/images/icons/wallet.svg';
import CalenderIcon from '../../assets/images/icons/calender.svg';
import LeadsIcon from '../../assets/images/icons/leads.svg';
import LocationIcon from '../../assets/images/icons/location.svg';
import { format } from 'date-fns';
import { COLORS } from 'constant';
import useDeviceType from 'hooks/useDeviceType';
interface IProps {
    campaignRequest: ICampaignRequest;
}

const CreateCampaignSummary: React.FC<IProps> = ({ campaignRequest }) => {
    const [showFullSummary, setShowFullSummary] = useState(false);
    const { isMobile } = useDeviceType();

    const calculateAverageCost = () => {
        const locations = campaignRequest.locations || [];
        if (locations.length === 0) return null;

        let totalCost = 0;
        let count = 0;
        for (const location of locations) {
            if (location.costPerLead != null) {
                totalCost += Number(location.costPerLead);
                count++;
            }
        }
        let averageCost = totalCost / count;
        return count > 0 ? averageCost : null;
    };

    const calculateExpectedLeadsPerDay = () => {
        if (campaignRequest?.locations?.length === 0 || !calculateAverageCost()) return { min: '--', max: '--' };
        if (campaignRequest?.budget && campaignRequest?.numberOfDays && campaignRequest.locations?.length) {
            const maxLeads = campaignRequest.budget / campaignRequest.numberOfDays / (calculateAverageCost() || 1);
            const minLeads = 0.7 * maxLeads;

            if (minLeads < 10) {
                return {
                    min: minLeads.toFixed(1),
                    max: maxLeads.toFixed(1)
                };
            } else {
                return {
                    min: Math.floor(minLeads),
                    max: Math.floor(maxLeads)
                };
            }
        }
        return { min: '--', max: '--' };
    };
    const expectedLeads = calculateExpectedLeadsPerDay();

    useEffect(() => {
        if (!isMobile) setShowFullSummary(true);
    }, [isMobile]);

    return (
        <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', background: { xs: COLORS.MAIN_BACKGROUND, md: 'unset' } }}>
                <Box sx={{ flexGrow: 1, background: { xs: COLORS.MAIN_BACKGROUND, md: 'unset' } }}>
                    <Grid container spacing={3}>
                        {/* Campaign Summary */}
                        <Grid item xs={12}>
                            <Card sx={{ background: { xs: COLORS.MAIN_BACKGROUND, md: 'unset' } }}>
                                <CardContent
                                    sx={{
                                        backgroundColor: { xs: '#F6F6F6', md: 'unset' },
                                        borderRadius: { xs: 2, md: 0 },
                                        margin: { xs: 1, md: 0 },
                                        boxShadow: { xs: '0px 2px 3px 0px rgba(0,0,0,0.2)', md: 'unset' }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box>
                                            <Typography variant="h4" sx={{ mb: 0.5 }}>
                                                Campaign summary
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ mb: 2, color: '#888' }}>
                                                Check your campaign summary here
                                            </Typography>
                                        </Box>
                                        <Button
                                            color="primary"
                                            aria-label="Show full summary"
                                            onClick={() => setShowFullSummary(!showFullSummary)}
                                            sx={{ display: { xs: 'flex', md: 'none' } }}
                                        >
                                            {showFullSummary ? (
                                                <Typography color="primary" variant="subtitle2" sx={{ mr: 1 }}>
                                                    Hide Details
                                                </Typography>
                                            ) : (
                                                <Typography color="primary" variant="subtitle2" sx={{ mr: 1 }}>
                                                    Show Details
                                                </Typography>
                                            )}
                                            {showFullSummary ? <ExpandMoreIcon sx={{ transform: 'rotate(180deg)' }} /> : <ExpandMoreIcon />}
                                        </Button>
                                    </Box>
                                    <Collapse in={showFullSummary}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                mb: { xs: 1, md: 2 },
                                                backgroundColor: { xs: 'white', md: 'unset' },
                                                borderRadius: { xs: 1, md: 0 },
                                                px: { xs: 1, md: 0 },
                                                pb: { xs: 1, md: 0 }
                                            }}
                                        >
                                            <img src={WalletIcon} alt={'Icon'} />
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="h5">Budget per day</Typography>
                                                <Typography>
                                                    {campaignRequest?.budget && campaignRequest?.numberOfDays
                                                        ? (campaignRequest.budget / campaignRequest.numberOfDays).toLocaleString(
                                                              undefined,
                                                              {
                                                                  maximumFractionDigits: 0
                                                              }
                                                          )
                                                        : '--'}{' '}
                                                    EGP / day
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {!isMobile && <Divider />}

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                mb: { xs: 1, md: 2 },
                                                backgroundColor: { xs: 'white', md: 'unset' },
                                                borderRadius: { xs: 1, md: 0 },
                                                px: { xs: 1, md: 0 },
                                                pb: { xs: 1, md: 0 }
                                            }}
                                        >
                                            <img src={CalenderIcon} alt={'Icon'} />
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="h5">Campaign Time</Typography>
                                                <Typography>
                                                    {campaignRequest?.startDate && campaignRequest?.endDate ? (
                                                        <>
                                                            From{' '}
                                                            <strong>{format(new Date(campaignRequest.startDate), 'dd/MM/yyyy')}</strong> to{' '}
                                                            <strong>{format(new Date(campaignRequest.endDate), 'dd/MM/yyyy')}</strong>
                                                            <strong> ({campaignRequest?.numberOfDays} days)</strong>
                                                        </>
                                                    ) : (
                                                        '--'
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {!isMobile && <Divider />}

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                mb: { xs: 1, md: 2 },
                                                backgroundColor: { xs: 'white', md: 'unset' },
                                                borderRadius: { xs: 1, md: 0 },
                                                px: { xs: 1, md: 0 },
                                                pb: { xs: 1, md: 0 }
                                            }}
                                        >
                                            <img src={LocationIcon} alt={'Icon'} />
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="h5">Locations</Typography>
                                                {campaignRequest?.locations && campaignRequest.locations.length > 0
                                                    ? campaignRequest?.locations?.map((location: ILocation) => (
                                                          <Typography>{location?.name}</Typography>
                                                      ))
                                                    : '--'}
                                            </Box>
                                        </Box>

                                        {!isMobile && <Divider />}

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                mb: { xs: 1, md: 2 },
                                                backgroundColor: { xs: 'white', md: 'unset' },
                                                borderRadius: { xs: 1, md: 0 },
                                                px: { xs: 1, md: 0 },
                                                pb: { xs: 1, md: 0 }
                                            }}
                                        >
                                            <img src={LeadsIcon} alt={'Icon'} />
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="h5">Expected leads</Typography>
                                                <Typography>{`${expectedLeads.min} - ${expectedLeads.max}`} / day</Typography>
                                            </Box>
                                        </Box>

                                        {!isMobile && <Divider />}

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                mb: { xs: 1, md: 2 },
                                                backgroundColor: { xs: 'white', md: 'unset' },
                                                borderRadius: { xs: 1, md: 0 },
                                                px: { xs: 1, md: 0 },
                                                pb: { xs: 1, md: 0 }
                                            }}
                                        >
                                            <img src={WalletIcon} alt={'Icon'} />
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="h5">Cost Per Lead</Typography>
                                                <Typography>
                                                    {campaignRequest?.locations?.length && calculateAverageCost() !== null
                                                        ? calculateAverageCost()?.toFixed(1)
                                                        : '--'}{' '}
                                                    EGP
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: { xs: 1, md: 0 },
                                                px: { xs: 3, md: 0 },
                                                py: { xs: 1, md: 0 }
                                            }}
                                        >
                                            <Typography variant="caption">
                                                ** The values shown here are just <b>estimates based</b> on current data. Actual results,
                                                including the final lead count, budget usage, or campaign performance, may vary and do not
                                                necessarily match the estimates exactly.
                                            </Typography>
                                        </Box>
                                    </Collapse>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {!isMobile && <Divider sx={{ marginY: 2 }} />}
        </Grid>
    );
};

export default CreateCampaignSummary;
