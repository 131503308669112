import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';

import SuccessIcon from '../../assets/images/icons/success.svg';

const SubmissionView: React.FC = () => {
    return (
        <Grid item xs={12}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                        <img src={SuccessIcon} alt={'Icon'} />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Typography variant="h3" sx={{ color: '#079455' }}>
                        Campaign in review
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 4 }}>
                        You will be notified once it is running
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#015C9A', width: '164px', heigh: '40px', borderRadius: '8px' }}
                        href="/campaign-requests"
                    >
                        View all requests
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SubmissionView;
