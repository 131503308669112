import { Chip } from '@mui/material';
import { Box } from '@mui/system';
import { ICustomCellProps } from 'components/common/SearchableTable/interfaces';
import facebookIcon from 'assets/images/icons/facebookIcon.svg';
import instagramIcon from 'assets/images/icons/instagramIcon.svg';
import googleIcon from 'assets/images/icons/googleIcon.svg';
import tiktokIcon from 'assets/images/icons/tiktokIcon.svg';
import snapchatIcon from 'assets/images/icons/snapchatIcon.svg';

const iconMap = {
    facebook: facebookIcon,
    instagram: instagramIcon,
    tikTok: tiktokIcon,
    snapchat: snapchatIcon,
    google: googleIcon
};

const PlatformNameCell: React.FC<ICustomCellProps> = ({ data: name }) => {
    const icon = iconMap[name.toLowerCase() as keyof typeof iconMap];
    return (
        <Box>
            <Chip label={name} size="small" icon={<img src={icon} alt={name} />} sx={{ backgroundColor: '#F7F7F7' }} />
        </Box>
    );
};

export default PlatformNameCell;
