// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CompoundsIcon } from 'assets/images/sidebar-icons/compoundsIcon';

import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const compoundsMenuItem: NavItemType = {
    id: 'compounds',
    title: <FormattedMessage id="compounds" />,
    type: 'item',
    url: '/compounds',
    icon: CompoundsIcon,
    external: false,
    target: false
};

export default compoundsMenuItem;
