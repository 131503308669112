import { FilterTypeEnum, ITableFilter } from 'components/common/SearchableTable/interfaces';

const partnersFilters: ITableFilter[] = [
    { filterType: FilterTypeEnum.TEXT, filterName: 'name', title: 'Search by name', placeholder: 'Search' },
    {
        filterType: FilterTypeEnum.SELECT_ONE,
        filterName: 'hasCRM',
        title: 'Has CRM',
        optionsKey: 'hasCRM',
        placeholder: 'With or Without CRM'
    }
    // { filterType: FilterTypeEnum.DATE, filterName: 'dateRange', title: 'Date' }
    // { filterType: 'checkbox', filterName: 'hasCRM', title: '' }
];

export default partnersFilters;
