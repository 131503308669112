import { createStaticRanges } from 'react-date-range';
import { startOfDay, endOfDay } from 'date-fns';
import dayjs from 'dayjs';

const currentQuarter = Math.floor(dayjs().month() / 3);

export const defaultStaticRanges = createStaticRanges([
    {
        label: 'Last Week',
        range: () => ({
            startDate: startOfDay(dayjs().subtract(1, 'week').startOf('week').toDate()),
            endDate: endOfDay(dayjs().subtract(1, 'week').endOf('week').toDate())
        })
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: startOfDay(dayjs().subtract(1, 'month').startOf('month').toDate()),
            endDate: endOfDay(dayjs().subtract(1, 'month').endOf('month').toDate())
        })
    },
    {
        label: 'Last Quarter',
        range: () => ({
            startDate:
                currentQuarter === 0
                    ? startOfDay(dayjs().subtract(1, 'year').month(9).startOf('month').toDate())
                    : endOfDay(
                          dayjs()
                              .month((currentQuarter - 1) * 3)
                              .startOf('month')
                              .toDate()
                      ),
            endDate:
                currentQuarter === 0
                    ? startOfDay(dayjs().subtract(1, 'year').month(11).endOf('month').toDate())
                    : endOfDay(
                          dayjs()
                              .month((currentQuarter - 1) * 3 + 2)
                              .endOf('month')
                              .toDate()
                      )
        })
    }
]);
