import { CMS_API, formattedQuery } from 'store/apis';
import { QueryPagesInterface } from './interfaces';

const PAGES_APIS = {
    query: (query: QueryPagesInterface) => {
        const formatQuery: string[] = [CMS_API, '/v1/pages', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default PAGES_APIS;
