import { FilterTypeEnum, ITableFilter } from 'components/common/SearchableTable/interfaces';

const campaignRequestsFilters: ITableFilter[] = [
    // {
    //     filterType: 'list',
    //     filterName: 'assignedAgents',
    //     optionsKey: 'users'
    // },
    // { filterType: 'text', filterName: 'unitCode' },
    { filterType: FilterTypeEnum.TEXT, filterName: 'campaignName', title: 'Search by campaign name' },
    { filterType: FilterTypeEnum.LIST, filterName: 'organizationIds', optionsKey: 'partners', title: 'Partner' },
    {
        filterType: FilterTypeEnum.LIST,
        filterName: 'locationIds',
        title: 'Location',
        paginated: true,
        url: '/v1/locations/search',
        initialSearchQuery: {
            pageSize: 50,
            page: 1,
            name: '',
            addLocationTypeToName: true
        }
    },
    { filterType: FilterTypeEnum.DATE, filterName: 'dateRange', title: 'Date' }
    // { filterType: 'text', filterName: 'ownerNumber' },
    // { filterType: 'list', filterName: 'compounds', optionsKey: 'compounds' },
    // { filterType: 'list', filterName: 'unitStatuses', optionsKey: 'unitStatuses' },
    // { filterType: 'text', filterName: 'numberOfBedrooms' },
    // { filterType: 'text', filterName: 'numberOfBathrooms' }
];

export default campaignRequestsFilters;
